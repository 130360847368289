import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const OPTIONS = [
  { key: 2, text: 'Orden: secuencial', value: 'sequence' },
  { key: 1, text: 'Orden: aleatorio', value: 'random' },
]

const QuestionsOrderDropdown = ({
  upward = true,
  value = '',
  className = '',
  placeholder = '',
  onChange = (_, { value }) => {
    console.log(_, value)
  },
}) => {
  return (
    <Dropdown
      upward={upward}
      value={value}
      placeholder={placeholder}
      selection={true}
      className={className}
      search
      options={OPTIONS}
      onChange={onChange}
    />
  )
}

export default QuestionsOrderDropdown
