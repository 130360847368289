import axios from 'axios'
const string_parameterize = function (str1) {
  return str1
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-')
}
export default async function uploadFile(
  file,
  path,
  bucket = process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME,
  isThumbnail = false
) {
  return axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/shared/upload-url`, {
      file_name: string_parameterize(file.name),
      file_type: file.type,
      bucket_name: bucket,
      file_path: string_parameterize(path),
      is_thumbnail: isThumbnail,
    })
    .then(async (resp) => {
      const options = {
        headers: {
          'Content-Type': file.type,
        },
      }
      try {
        await axios.put(resp.data.url, file, options)
        return {
          file_type: file.type,
          file_url: resp.data.url.split('?')[0],
          file_name: string_parameterize(file.name),
          is_thumbnail: file.is_thumbnail,
        }
      } catch (error) {
        console.log(error)
        return { file_url: null }
      }
    })
}
