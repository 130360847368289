const ONE_SECOND_MS = 1000
const ONE_MINUTE_MS = ONE_SECOND_MS * 60
const ONE_HOUR_MS = ONE_MINUTE_MS * 60
const ONE_DAY_MS = ONE_HOUR_MS * 24
const getNumOrZero = (num) => Math.floor(Math.max(0, num))

export default function timeInterval(elapsed) {
  const wholeDaysValue = getNumOrZero(elapsed / ONE_DAY_MS)
  const wholeDaysInMs = wholeDaysValue * ONE_DAY_MS
  const wholeHoursValue = getNumOrZero((elapsed - wholeDaysInMs) / ONE_HOUR_MS)
  const wholeHoursInMs = wholeHoursValue * ONE_HOUR_MS
  const wholeMinutesValue = getNumOrZero(
    (elapsed - wholeHoursInMs - wholeDaysInMs) / ONE_MINUTE_MS
  )
  const wholeMinutesInMs = wholeMinutesValue * ONE_MINUTE_MS
  const wholeSecondsValue = getNumOrZero(
    (elapsed - wholeMinutesInMs - wholeHoursInMs - wholeDaysInMs) /
      ONE_SECOND_MS
  )
  const wholeSecondsInMs = wholeSecondsValue * ONE_SECOND_MS
  const millisecsValue =
    elapsed -
    wholeMinutesInMs -
    wholeSecondsInMs -
    wholeHoursInMs -
    wholeDaysInMs
  const minutesRotation = wholeMinutesValue * 6
  const secondsRotation = wholeSecondsValue * 6
  const millisecsRotation = millisecsValue * 0.36
  let elapsedFormatted =
    `${wholeMinutesValue.toString()}:` +
    `${wholeSecondsValue.toString().padStart(2, '0')}.` +
    `${millisecsValue.toString().padStart(3, '0')}`

  if (wholeHoursValue !== 0 || wholeDaysValue !== 0) {
    elapsedFormatted =
      `${
        wholeDaysValue !== 0
          ? wholeHoursValue.toString().padStart(2, '0')
          : wholeHoursValue.toString()
      }:` + elapsedFormatted
  }

  if (wholeDaysValue !== 0) {
    elapsedFormatted = `${wholeDaysValue.toString()}:` + elapsedFormatted
  }

  return {
    minutesRotation,
    secondsRotation,
    millisecsRotation,
    elapsedFormatted,
  }
}

export function timeIntervalAsF1(elapsed) {
  const wholeDaysValue = getNumOrZero(elapsed / ONE_DAY_MS)
  const wholeDaysInMs = wholeDaysValue * ONE_DAY_MS
  const wholeHoursValue = getNumOrZero((elapsed - wholeDaysInMs) / ONE_HOUR_MS)
  const wholeHoursInMs = wholeHoursValue * ONE_HOUR_MS
  const wholeMinutesValue = getNumOrZero(
    (elapsed - wholeHoursInMs - wholeDaysInMs) / ONE_MINUTE_MS
  )
  const wholeMinutesInMs = wholeMinutesValue * ONE_MINUTE_MS
  const wholeSecondsValue = getNumOrZero(
    (elapsed - wholeMinutesInMs - wholeHoursInMs - wholeDaysInMs) /
      ONE_SECOND_MS
  )
  const wholeSecondsInMs = wholeSecondsValue * ONE_SECOND_MS
  const millisecsValue =
    elapsed -
    wholeMinutesInMs -
    wholeSecondsInMs -
    wholeHoursInMs -
    wholeDaysInMs
  let elapsedFormatted = `${millisecsValue.toString().padStart(3, '0')}`

  if (wholeSecondsValue > 0) {
    elapsedFormatted =
      `${wholeSecondsValue.toString().padStart(2, '0')}.` + elapsedFormatted
  }

  if (wholeMinutesValue > 0) {
    elapsedFormatted = `${wholeMinutesValue.toString()}:` + elapsedFormatted
  }

  if (wholeHoursValue !== 0 || wholeDaysValue !== 0) {
    elapsedFormatted =
      `${
        wholeDaysValue !== 0
          ? wholeHoursValue.toString().padStart(2, '0')
          : wholeHoursValue.toString()
      }:` + elapsedFormatted
  }

  if (wholeDaysValue !== 0) {
    elapsedFormatted = `${wholeDaysValue.toString()}:` + elapsedFormatted
  }

  return `+${elapsedFormatted}`
}
