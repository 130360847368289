import ContentLoader from 'react-content-loader'
export default function Loader(props) {
  return (
    <ContentLoader
      speed={1}
      width={props.width}
      height={props.height}
      viewBox={`0 0 350 ${props.height}`}
      backgroundColor="#f5f5f5"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx="0"
        ry="0"
        width={props.width}
        height={props.height}
      />
    </ContentLoader>
  )
}
