import { useEffect, useState } from 'react'
import { useCodeRunnerStore } from '../hooks/useCodeRunnerStore'

const CLASSNAMES = {
  ERROR: 'text-red-600 animate-pulse',
  NORMAL: 'text-white',
}

const ConsoleError = () => {
  const { consoleResult } = useCodeRunnerStore()
  const [className, setClassName] = useState(CLASSNAMES.ERROR)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setClassName(CLASSNAMES.NORMAL)
    }, 4000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [consoleResult.type, CLASSNAMES])

  return (
    <p
      className={`${className} font-medium text-lg whitespace-pre-wrap transition-all ease-out duration-200`}
    >
      {consoleResult.content}
    </p>
  )
}

export default ConsoleError
