import React from 'react'
import MonacoEditor from '@monaco-editor/react'

const Editor = ({
  language = 'python',
  height = '100%',
  onChange = () => {},
  value = '',
  readOnly = false,
}) => {
  return (
    <MonacoEditor
      language={language}
      height={height}
      value={value}
      width={'100%'}
      onChange={onChange}
      className="pt-7"
      keepCurrentModel={true}
      onMount={(_, monaco) => {
        monaco.editor.setTheme('vs-dark')
      }}
      options={{
        fontFamily: 'system-ui',
        fontWeight: '400',
        fontSize: 17,
        cursorStyle: 'line',
        formatOnPaste: true,
        formatOnType: true,
        wordWrap: true,
        autoClosingBrackets: true,
        autoClosingQuotes: true,
        cursorBlinking: true,
        bracketPairColorization: true,
        theme: 'vs-dark',
        minimap: false,
        readOnly: readOnly,
      }}
    />
  )
}

export default Editor
