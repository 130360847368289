import React, { useState, useRef } from 'react'
import { Image, Loader, Button, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { selectSelectedFile, setSelectedFile } from '../../../../store/uiSlice'
import { Document, Page, pdfjs } from 'react-pdf'
import getFileUrl from '../../../../utils/getFileUrl'
import ReactPlayer from 'react-player'
import './FilePreview.css'
import { useTheme } from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function FilePreview({ backText = 'Ver respuesta' }) {
  const [numPages, setNumPages] = useState(null)
  const [fileUrl, setFileUrl] = React.useState(undefined)
  let dispatch = useDispatch()
  const file = useSelector(selectSelectedFile)
  const ref = useRef(null)
  const [didLoadImage, setDidLoadImage] = useState(false)
  const theme = useTheme()
  let content = <></>

  React.useEffect(() => {
    async function getImageUrl() {
      if (fileUrl === undefined && file !== null) {
        if (file.is_valid_url) {
          setFileUrl(file.file_url)
        } else {
          const url = await getFileUrl(
            file.file_url,
            process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
          )
          setFileUrl(url)
        }
      }
    }
    getImageUrl()
  })

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  if (file !== null) {
    if (fileUrl === undefined) {
      content = <Loader inverted />
    } else if (file.file_type.includes('pdf')) {
      content = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Document
            loading={'Cargando PDF...'}
            style={{ width: '100%' }}
            onLoadSuccess={onDocumentLoadSuccess}
            file={{ url: fileUrl }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                className="pdf-document-page"
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      )
    } else if (file.file_type.includes('image')) {
      content = (
        <div>
          {didLoadImage ? '' : <Loader active />}
          <Image
            style={{ objectFit: 'contain' }}
            centered
            src={fileUrl}
            size="massive"
            onLoad={() => setDidLoadImage(true)}
          />
        </div>
      )
    } else if (file.file_type.includes('video')) {
      content = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactPlayer url={fileUrl} controls={true} />
        </div>
      )
    }
  }

  return (
    <div size="large" open={file !== null} ref={ref}>
      {file !== null && (
        <Button
          style={{ marginBottom: '1rem' }}
          onClick={() => dispatch(setSelectedFile(null))}
          size="tiny"
          basic
          inverted={theme.isDark}
        >
          <Icon name="chevron left" />
          {backText}
        </Button>
      )}
      {content}
    </div>
  )
}
