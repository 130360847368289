import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon, Input, Image, Label } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  setReady,
  setErrorMessage,
  setAssigment,
  selectAssigment,
} from 'app/store/assigmentCreationSlice'
import LineTo from 'react-lineto'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  gap: 1rem;
`

const Column = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #f4f4f4;
  border-radius: 0.25rem;
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ItemType = {
  CONCEPT: 'concept',
}

const ConceptDraggable = ({ item, handleDeleteItem, connectConcepts }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, drag] = useDrag(() => ({
    type: ItemType.CONCEPT,
    item: { item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        connectConcepts(item, dropResult)
      }
    },
  }))

  return (
    <Item
      ref={drag}
      draggable
      className={item.id}
      style={{ zIndex: 1, cursor: 'move' }}
    >
      <ItemContent>
        {item.image ? (
          <Image rounded src={URL.createObjectURL(item.image)} size="small" />
        ) : (
          <span>{item.name}</span>
        )}
        <div>
          <Button
            size="mini"
            icon
            onClick={() => handleDeleteItem(item.id, 'left')}
          >
            <Icon name="trash" />
          </Button>
        </div>
      </ItemContent>
    </Item>
  )
}

const ConceptZone = ({
  item,
  concepts,
  accept,
  handleDeleteItem,
  handleDeleteLinkedConcepts,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop(() => ({
    accept,
    drop: () => ({ item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <Item ref={drop} className={item.id} style={{ zIndex: 1 }}>
      <ItemContent>
        {item.image ? (
          <Image rounded src={URL.createObjectURL(item.image)} size="small" />
        ) : (
          <span>{item.name}</span>
        )}
        <div>
          <Button
            size="mini"
            icon
            onClick={() => handleDeleteItem(item.id, 'right')}
          >
            <Icon name="trash" />
          </Button>
        </div>
      </ItemContent>
      <div style={{ width: '100%' }}>
        {item.labels &&
          item.labels.map((label) => {
            const foundConcept = concepts.find((c) => c.id === label)

            if (foundConcept) {
              return (
                <Label key={label}>
                  {foundConcept.name}
                  <Icon
                    name="delete"
                    onClick={() =>
                      handleDeleteLinkedConcepts(foundConcept.id, item)
                    }
                  />
                </Label>
              )
            }

            return null
          })}
      </div>
    </Item>
  )
}

export const DragAndDropConfig = () => {
  const dispatch = useDispatch()
  const assignment = useSelector(selectAssigment)
  const [leftSideConcepts, setLeftSideConcepts] = useState([
    // { id: 1, name: "Left Concept 1" },
    // { id: 2, name: "Left Concept 2" },
  ])
  const [rightSideConcepts, setRightSideConcepts] = useState([
    // { id: 3, name: "Right Concept 1" },
    // { id: 4, name: "Right Concept 2" },
  ])
  const [connections, setConnections] = useState([])
  const [leftSideConcept, setLeftSideConcept] = useState({
    name: '',
    image: null,
  })
  const [rightSideConcept, setRightSideConcept] = useState({
    name: '',
    image: null,
  })

  const rightSideConceptsRef = useRef()
  rightSideConceptsRef.current = rightSideConcepts

  const handleDeleteItem = (id, side) => {
    if (side === 'left') {
      // Remove all right side links
      rightSideConceptsRef.current.map((concept) => {
        if (concept.labels.includes(id)) {
          handleDeleteLinkedConcepts(id, concept)
        }
        return true
      })

      setLeftSideConcepts(leftSideConcepts.filter((item) => item.id !== id))
      setConnections(connections.filter((conn) => conn.id !== id))
    } else {
      setRightSideConcepts(rightSideConcepts.filter((item) => item.id !== id))
      setConnections(connections.filter((conn) => conn.id !== id))
    }
  }

  const handleAddLeftSideConcept = () => {
    // Add concept
    const concept = {
      id: uuidv4(),
    }

    if (leftSideConcept.image) {
      concept.image = leftSideConcept.image
    } else if (leftSideConcept.name) {
      concept.name = leftSideConcept.name
    }

    if (!Object.hasOwn(concept, 'name') && !Object.hasOwn(concept, 'image')) {
      return
    }

    let leftSideConceptsCopy = [...leftSideConcepts]
    leftSideConceptsCopy.push(concept)

    setLeftSideConcepts(leftSideConceptsCopy)
    handleDeleteEditorItem('left')
  }

  const handleAddRightSideConcept = (propsConcept) => {
    // Add concept
    const concept = {
      id: uuidv4(),
      labels: [],
    }

    const calculatedConcept = propsConcept ? propsConcept : rightSideConcept

    if (calculatedConcept.image) {
      concept.image = calculatedConcept.image
    } else if (calculatedConcept.name) {
      concept.name = calculatedConcept.name
    }

    if (!Object.hasOwn(concept, 'name') && !Object.hasOwn(concept, 'image')) {
      return
    }

    let rightSideConceptsCopy = [...rightSideConcepts]
    rightSideConceptsCopy.push(concept)

    setRightSideConcepts(rightSideConceptsCopy)
    handleDeleteEditorItem('right')
  }

  const handleInput = (side, value) => {
    if (side === 'left') {
      setLeftSideConcept({
        ...leftSideConcept,
        name: value,
      })
    } else if (side === 'right') {
      setRightSideConcept({
        ...rightSideConcept,
        name: value,
      })
    }
  }

  const handleFileUpload = (event, side) => {
    const file = event.target.files[0]

    if (side === 'left') {
      setLeftSideConcept({
        ...leftSideConcept,
        image: file,
      })
    } else if (side === 'right') {
      handleAddRightSideConcept({
        ...rightSideConcept,
        image: file,
      })
    }
  }

  const handleDeleteEditorItem = (side) => {
    const { name, image } = { name: '', image: null }
    side === 'left'
      ? setLeftSideConcept({ name, image })
      : setRightSideConcept({ name, image })
  }

  const handleConnectConcepts = (conceptA, conceptB) => {
    // Get current concept b index
    const previousConceptBIndex = rightSideConceptsRef.current.findIndex(
      (c) => c.id === conceptB.item.id
    )
    const previousConceptBLabels =
      rightSideConceptsRef.current[previousConceptBIndex].labels

    // Update concept b object
    const updatedConceptB = {
      ...rightSideConceptsRef.current[previousConceptBIndex],
      labels: [...previousConceptBLabels],
    }

    // Check if item not in labels
    if (!updatedConceptB.labels.includes(conceptA.item.id)) {
      updatedConceptB.labels = [...updatedConceptB.labels, conceptA.item.id]
    }

    // Update state
    const updatedRightSideConcepts = [...rightSideConceptsRef.current]
    updatedRightSideConcepts.splice(previousConceptBIndex, 1, updatedConceptB)
    setRightSideConcepts(updatedRightSideConcepts)
  }

  const handleDeleteLinkedConcepts = (labelId, concept) => {
    // Delete label
    const labels = [...concept.labels]
    const targetLabelIndex = labels.findIndex((l) => l === labelId)
    labels.splice(targetLabelIndex, 1)

    // Create new concept object
    const conceptCopy = {
      ...concept,
      labels,
    }

    // Find current concept
    const rightHandSideConcepts = [...rightSideConceptsRef.current]
    const tragetConceptIndex = rightHandSideConcepts.findIndex(
      (c) => c.id === concept.id
    )
    rightHandSideConcepts.splice(tragetConceptIndex, 1, conceptCopy)
    setRightSideConcepts(rightHandSideConcepts)
  }

  // Handle concepts changes
  useEffect(() => {
    // Check if assignment can be created
    if (leftSideConcepts.length === 0 || rightSideConcepts.length === 0) {
      dispatch(setReady(false))
      dispatch(setErrorMessage('Agrega al menos un concepto en cada columna'))
    } else {
      dispatch(setReady(true))
      dispatch(setErrorMessage(null))
    }

    // Update assignment data
    dispatch(
      setAssigment({
        ...assignment,
        configuration: {
          ...(assignment.configuration || {}),
          leftSideConcepts,
          rightSideConcepts,
        },
      })
    )
  }, [leftSideConcepts, rightSideConcepts])

  return (
    <Wrapper>
      <DndProvider backend={HTML5Backend}>
        <Column>
          {/* New concept input */}
          <div>
            {leftSideConcept.image !== null ? (
              <Image rounded src={URL.createObjectURL(leftSideConcept.image)} />
            ) : (
              <Input
                className="h-21 w-full"
                placeholder="Etiqueta o concepto"
                value={leftSideConcept.name}
                //listen for enter key and call handleAddLeftSideConcept function
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddLeftSideConcept()
                  }
                }}
                onChange={(input) => handleInput('left', input.target.value)}
                label={
                  <Button icon onClick={handleAddLeftSideConcept} size="tiny">
                    <Icon name="plus" /> Agregar
                  </Button>
                }
                labelPosition="right"
              ></Input>
            )}
          </div>
          {/* Delete button (for images) */}
          {leftSideConcept.image !== null && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '1rem',
              }}
            >
              <Button
                icon
                size="tiny"
                onClick={() => handleDeleteEditorItem('left')}
              >
                <Icon name="trash alternate outline" />
              </Button>
            </div>
          )}
          {/* Other actions */}
          <input
            id="upload-image-left"
            type="file"
            accept="image/*"
            onChange={(file) => handleFileUpload(file, 'left')}
            style={{ display: 'none' }}
          />
          {/* List of leftSideConcepts */}
          {leftSideConcepts.map((item) => (
            <ConceptDraggable
              handleDeleteItem={handleDeleteItem}
              key={item.id}
              item={item}
              connectConcepts={handleConnectConcepts}
            />
          ))}
        </Column>
        <Column>
          {/* New concept input */}
          <div>
            {rightSideConcept.image !== null ? (
              <Image
                rounded
                src={URL.createObjectURL(rightSideConcept.image)}
              />
            ) : (
              <Input
                type="text"
                placeholder="Etiqueta o concepto"
                value={rightSideConcept.name}
                onChange={(input) => handleInput('right', input.target.value)}
                action={
                  <Button
                    icon
                    onClick={() =>
                      document.querySelector('#upload-image-right').click()
                    }
                  >
                    <Icon name="image outline" />
                  </Button>
                }
                label={
                  <Button
                    icon
                    onClick={() => handleAddRightSideConcept()}
                    size="tiny"
                    style={{
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  >
                    <Icon name="plus" /> Agregar
                  </Button>
                }
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddRightSideConcept()
                  }
                }}
                actionPosition="left"
                labelPosition="right"
              />
            )}
          </div>
          {/* Delete button (for images) */}
          {rightSideConcept.image !== null && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '1rem',
              }}
            >
              <Button
                icon
                size="tiny"
                onClick={() => handleDeleteEditorItem('right')}
              >
                <Icon name="trash alternate outline" />
              </Button>
            </div>
          )}
          {/* Add concept */}
          <input
            id="upload-image-right"
            type="file"
            accept="image/*"
            onChange={(file) => handleFileUpload(file, 'right')}
            style={{ display: 'none' }}
          />
          {/* List of rightSideConcepts */}
          {rightSideConcepts.map((item) => (
            <ConceptZone
              handleDeleteItem={handleDeleteItem}
              key={item.id}
              item={item}
              accept={ItemType.CONCEPT}
              concepts={leftSideConcepts}
              handleDeleteLinkedConcepts={handleDeleteLinkedConcepts}
            />
          ))}
        </Column>
      </DndProvider>
      <div style={{ position: 'absolute', width: '10%' }}>
        {connections.map((conn, index) => (
          <LineTo
            key={index}
            from={conn.conceptA}
            to={conn.conceptB}
            borderColor={'black'}
            fromAnchor={'right center'}
            toAnchor={'left left'}
            zIndex={0}
          />
        ))}
      </div>
    </Wrapper>
  )
}
