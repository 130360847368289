import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import getFileUrl from '../../../utils/getFileUrl'
import { stringToColor } from '../../../utils/color'

const downloadFile = async (file_url) => {
  const url = await getFileUrl(
    file_url,
    process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
  )
  window.open(url, '_blank')
}

const FileExtension = ({ name, file_type }) => {
  const parts = name.split('.')
  const extension =
    parts.length > 1
      ? parts.pop()
      : file_type
      ? file_type.split('/').length > 1
        ? file_type.split('/')[1]
        : ''
      : ''

  return (
    <ExtensionIcon style={{ background: stringToColor(extension) }}>
      <span style={{ color: 'white' }}>{extension.slice(0, 4)}</span>
    </ExtensionIcon>
  )
}

const getFileWeight = (size) => {
  let weight
  let extension

  if (size >= 1000000) {
    weight = Math.round((size / 1000000) * 100) / 100
    extension = 'Megabytes'
  } else if (size >= 1024) {
    weight = Math.round((size / 1024) * 100) / 100
    extension = 'Kilobytes'
  } else {
    weight = size
    extension = 'Bytes'
  }

  return `${weight} ${extension}`
}

export default function File({ url, file_name, size, file_type }) {
  let content

  if (size) {
    content = (
      <FileContainer key={url}>
        <FileExtension name={file_name} file_type={file_type} />
        <FleName>{file_name}</FleName> -{' '}
        <FileWeight>{getFileWeight(size)}</FileWeight>
      </FileContainer>
    )
  } else {
    content = (
      <FileContainer key={url}>
        <FileExtension name={file_name} file_type={file_type} />
        <FleName>{file_name}</FleName>
        <DownloadIcon
          name="arrow alternate circle down outline"
          onClick={() => downloadFile(url)}
        />
      </FileContainer>
    )
  }

  return content
}

const FileContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 6px;
  box-shadow: ${(props) =>
    props.theme.isDark ? props.theme.shadow : 'rgb(218 225 233)'};
  background: ${(props) =>
    props.theme.isDark ? props.theme.sidebarBackground : 'rgb(255, 255, 255)'};
  white-space: nowrap;
  border-radius: 8px;
  padding: 12px 16px;
  display: inline-block !important;
  cursor: default;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.text};
`
const FleName = styled.strong`
  font-size: 14px;
  color: ${(props) => props.theme.text};
  margin: 0 1rem;
`
const FileWeight = styled.span`
  font-size: 13px;
  line-height: 0;
  color: ${(props) => props.theme.textTertiary};
`
const DownloadIcon = styled(Icon)`
  cursor: pointer;
`
const ExtensionIcon = styled.span`
  font-family: ${(props) => props.theme.fontFamilyMono};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
  color: white !important;
  text-align: center;
  border-radius: 4px;

  min-width: 28px;
  height: 28px;
`
