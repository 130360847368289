import React from 'react'
import { useCodeRunnerStore } from '../hooks/useCodeRunnerStore'

const Instructions = () => {
  const { assignment } = useCodeRunnerStore()
  return (
    assignment &&
    assignment.description && (
      <div className="bg-gray-800 ease-out max-h-[190px] overflow-y-auto  rounded-t-[8px] w-full h-fit">
        <div className="py-1 px-4 mt-1 text-xl font-mono ">
          <h1 className="mb-2">
            <span className="text-white font-mono text-lg font-semibold">
              {assignment.title}
            </span>
          </h1>

          <p className=" flex flex-col w-full text-base tracking-wide bg-transparent border-none  font-mono text-green-400">
            <span className="text-base text-green-400 tracking-wide bg-transparent ">
              {'/*'}
            </span>
            {/* Split description in order to add new line */}
            {assignment.description.split('\n').map((line, index) => (
              <span
                key={index}
                className="text-base text-green-400 tracking-wide bg-transparent"
              >
                {line}
              </span>
            ))}
            <span className="text-base text-green-400 tracking-wide bg-transparent ">
              {'*/'}
            </span>
          </p>
        </div>
      </div>
    )
  )
}

export default Instructions
