import styled, { useTheme } from 'styled-components'
import Flex from './containers/Flex'
import timeAgo from '../utils/timeAgo'
import { Table, Label, Icon } from 'semantic-ui-react'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { BsTextLeft, BsQuestionDiamondFill } from 'react-icons/bs'
import { GiChoice } from 'react-icons/gi'
import { FaEnvelope } from 'react-icons/fa'
import ExamDetail from './assigments/Exam/ExamDetail'
import 'react-leaf-polls-copy/dist/index.css'
import { selectCurrentUser } from '../store/authSlice'
import { useSelector } from 'react-redux'
import calculateExamGrade from '../utils/calculateExamGrade'
import { DragAndDrop } from './assigments/DragAndDrop'
import SubmissionDetails from './SubmissionDetails'
import SubmissionFeedback from 'app/elements/SubmissionFeedback'
import CompletedTypeformAssignment from './CompletedAssignments/CompletedTypeformAssignment'

export default function CompletedAssigment({ assigment, submission }) {
  const type = assigment.assignmentType?.slug || 'typeform'
  const currentUser = useSelector(selectCurrentUser)
  const theme = useTheme()

  const renderResponses = () => {
    let fieldNames = {}
    //check if is string, then parse to json
    const details =
      typeof submission.details === 'string'
        ? JSON.parse(submission.details.replaceAll('=>', ':'))
        : submission.details

    if (!details.fields || !details.answers) {
      return <span />
    }
    details?.fields.map((f) => {
      fieldNames[f.id] = f.title
    })

    return (
      submission && (
        <Flex
          align="flex-start"
          justify="flex-start"
          paddingLeft={0}
          paddingRight={0}
          full
        >
          <Table inverted={theme.isDark} fixed style={{ width: '100%' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Pregunta</Table.HeaderCell>
                <Table.HeaderCell>Respuesta</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {details?.answers.map((answer, index) => {
                let content = JSON.stringify(answer)
                let icon = <GiChoice size={20} />
                switch (answer.type) {
                  case 'choice': {
                    icon = (
                      <span>
                        <GiChoice size={20} /> Opción múltiple
                      </span>
                    )
                    const choices = details.fields.find(
                      (f) => f.id === answer.field.id
                    ).choices
                      ? details.fields.find((f) => f.id === answer.field.id)
                          .choices
                      : details.fields.find((f) => f.id === answer.field.id)
                          .properties.choices
                    content = (
                      <ul>
                        {choices.map((c, index) => (
                          <li key={index}>
                            {c.label === answer.choice.label ? (
                              <mark
                                style={{
                                  fontSize: '1.2rem',
                                }}
                              >
                                <strong>{c.label}</strong>
                              </mark>
                            ) : (
                              c.label
                            )}
                          </li>
                        ))}
                      </ul>
                    )
                    break
                  }
                  case 'choices':
                    content = answer.choices.labels.join(', ')
                    icon = <RiCheckboxMultipleFill size={20} />
                    break
                  case 'text':
                    content = answer.text
                    icon = (
                      <>
                        <span>
                          <BsTextLeft size={20} />
                          Abierta
                        </span>
                      </>
                    )
                    break
                  case 'boolean':
                    content = answer.boolean ? 'Sí' : 'No'
                    icon = (
                      <span>
                        <BsQuestionDiamondFill size={20} /> Sí/No
                      </span>
                    )
                    break
                  case 'email':
                    content = answer.email
                    icon = (
                      <span>
                        <FaEnvelope size={20} /> Email
                      </span>
                    )
                    break
                  case 'number':
                    content = answer.number
                    icon = (
                      <span>
                        <BsQuestionDiamondFill size={20} /> Numérica
                      </span>
                    )
                    break
                  case 'date':
                    content = answer.date
                    icon = (
                      <>
                        <span>
                          <BsQuestionDiamondFill size={20} /> Fecha
                        </span>
                      </>
                    )
                    break
                  case 'phone_number':
                    content = answer.phone_number
                    icon = (
                      <span>
                        <BsQuestionDiamondFill size={20} /> Teléfono
                      </span>
                    )
                    break
                  case 'short_text':
                    content = answer.text
                    icon = (
                      <span>
                        <BsTextLeft size={20} /> Abierta
                      </span>
                    )
                    break
                  default:
                    content = JSON.stringify(answer)
                    icon = (
                      <span>
                        <BsQuestionDiamondFill size={20} /> Otro
                      </span>
                    )
                    break
                }

                return (
                  <Table.Row key={index}>
                    <Table.Cell>{icon}</Table.Cell>
                    <Table.Cell>{fieldNames[answer.field.id]}</Table.Cell>
                    <Table.Cell>{content}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Flex>
      )
    )
  }

  const renderExamFeedback = ({ grading }) => {
    return (
      <Table inverted={theme.isDark} fixed style={{ width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Pregunta</Table.HeaderCell>
            <Table.HeaderCell>Tu respuesta</Table.HeaderCell>
            <Table.HeaderCell>Retroalimentación</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {grading.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.question,
                    }}
                  />
                </Table.Cell>
                <Table.Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    color={item.isCorrect ? 'green' : 'red'}
                    name={
                      item.isCorrect
                        ? 'check circle outline'
                        : 'times circle outline'
                    }
                    size="large"
                  />{' '}
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  }
                </Table.Cell>
                <Table.Cell>
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.feedback
                          ? item.feedback
                          : 'No hay retroalimentación',
                      }}
                    />
                  }
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  const renderExamInstantFeedback = ({ grading }) => {
    return (
      <Table inverted={theme.isDark} fixed style={{ width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Pregunta</Table.HeaderCell>
            <Table.HeaderCell>Tu respuesta</Table.HeaderCell>
            <Table.HeaderCell>Respuesta correcta</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {grading.map((item, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.question,
                    }}
                  />
                </Table.Cell>
                <Table.Cell
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    color={item.isCorrect ? 'green' : 'red'}
                    name={
                      item.isCorrect
                        ? 'check circle outline'
                        : 'times circle outline'
                    }
                    size="large"
                  />{' '}
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  }
                </Table.Cell>
                <Table.Cell>
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.feedback,
                      }}
                    />
                  }
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  const renderExamResponses = () => {
    let content

    // check if it has instant feedback
    if (!assigment.configuration.instantFeedback) {
      // check if exam has feedback
      if (
        Object.hasOwn(submission.details, 'feedback') ||
        Object.hasOwn(submission.feedback || {}, 'instructor_feedback')
      ) {
        const { rightAnswersCount, questionsCount, grade, grading } =
          calculateExamGrade({ submission, assigment })

        // Mostrar feedback
        content = (
          <>
            <Heading>{assigment.title}</Heading>
            <Meta>
              Calificación:
              <Label
                circular
                style={{ marginLeft: '5px' }}
                color={grade > 0.5 ? 'green' : 'red'}
                size="large"
              >
                ({rightAnswersCount}/{questionsCount}){' '}
                {Math.round(grade * 10000) / 100}%
              </Label>
            </Meta>
            <Flex
              align="flex-start"
              justify="flex-start"
              paddingLeft={0}
              paddingRight={0}
            >
              {renderExamFeedback({ grade, grading })}
            </Flex>
          </>
        )
      } else if (!currentUser.isStudent) {
        content = <ExamDetail assigment={assigment} submission={submission} />
      } else {
        content = (
          <Flex column>
            <Heading>{assigment.title}</Heading>
            <Meta>
              Actividad completada{' '}
              {timeAgo.format(
                Date.parse(
                  submission.createdAt
                    ? submission.createdAt
                    : submission.created_at
                )
              )}
            </Meta>
          </Flex>
        )
      }
    } else {
      const { rightAnswersCount, questionsCount, grade, grading } =
        calculateExamGrade({ submission, assigment })
      content = (
        <>
          <Heading>{assigment.title}</Heading>
          <Meta>
            Resultados:
            <Label
              circular
              style={{ marginLeft: '5px' }}
              color={grade > 0.5 ? 'green' : 'red'}
              size="large"
            >
              ({rightAnswersCount}/{questionsCount}){' '}
              {Math.round(grade * 10000) / 100}%
            </Label>
          </Meta>
          <Flex
            align="flex-start"
            justify="flex-start"
            paddingLeft={0}
            paddingRight={0}
          >
            {renderExamInstantFeedback({ grading })}
          </Flex>
        </>
      )
    }

    return content
  }

  let content
  if (currentUser.isStudent) {
    let assignmentPreview

    switch (type) {
      case 'exam':
        assignmentPreview = <></>
        break
      case 'typeform':
        assignmentPreview = (
          <>
            <br />
            <CompletedTypeformAssignment submission={submission} />
          </>
        )
        break
      case 'drag_n_drop':
        assignmentPreview = (
          <DragAndDrop
            assignment={assigment}
            submission={submission}
            hideTitle
            showResults
          />
        )
        break
      default:
        break
    }

    content = (
      <Flex full column>
        <Heading>{assigment.title}</Heading>
        {/* Details */}
        <SubmissionDetails submission={submission} />
        {/* Preview */}
        {assignmentPreview}
        {/* Feedback */}
        {assigment.configuration?.requiresFeedback && submission && (
          <SubmissionFeedback />
        )}
      </Flex>
    )
    // switch (type) {
    //   case 'exam':
    //     content = renderExamResponses()
    //     break
    //   case 'Poll':
    //   case 'poll':
    //     content = renderPollResults()
    //     break
    //   case 'drag_n_drop':
    //     content = (
    //       <DragAndDrop
    //         assignment={assigment}
    //         submission={submission}
    //         showResults={true}
    //         showScore={true}
    //       />
    //     )
    //     break
    //   default:
    //     content = (
    //       <Flex full column>
    //         <Heading>{assigment.title}</Heading>
    //         <SubmissionDetails submission={submission} />
    //         {assigment.configuration?.requiresFeedback && submission && (
    //           <SubmissionFeedback />
    //         )}
    //       </Flex>
    //     )
    //     break
    // }
  } else {
    switch (type) {
      case 'typeform':
        content = renderResponses()
        break
      case 'typeform_quiz':
        content = renderResponses()
        break
      case 'exam':
        content = renderExamResponses()
        break
      default:
        content = (
          <Flex column>
            <Heading>{assigment.title}</Heading>
            <Meta>
              Actividad completada{' '}
              {timeAgo.format(
                Date.parse(
                  submission.createdAt
                    ? submission.createdAt
                    : submission.created_at
                )
              )}
            </Meta>
          </Flex>
        )
        break
    }
  }
  return content
}
const Heading = styled.p`
  font-weight: bold;
  font-size: 2em;
`
const Meta = styled.div`
  font-size: 1.3em;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.textTertiary};
`
