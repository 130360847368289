import { useMemo } from 'react'

const useAssignmentAdapter = ({ assignment }) => {
  const adaptedAssignment = useMemo(() => {
    return {
      ...assignment,
    }
  }, [assignment])

  return adaptedAssignment
}

export default useAssignmentAdapter
