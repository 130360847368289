import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/authSlice'
import {
  useCreateElementMutation,
  useAddRaceSubmissionMutation
} from 'app/store/apiHubSlice'
import LoaderContainer from '../components/containers/LoaderContainer'
import { Loader, Input, Button, Icon, Label, Table } from 'semantic-ui-react'
import Flex from '../components/containers/Flex'
import Stopwatch from './Stopwatch/Stopwatch'
import styled, { useTheme } from 'styled-components'
import dayjs from '../utils/dayjs'
import Avatar from '../components/shared/Avatar'
import timeInterval, { timeIntervalAsF1 } from '../utils/timeInterval'
import { SocketContext } from 'app/context/socket'

function PodiumBadge(index) {
  switch (index) {
    case 0:
      return (
        <Label circular color={'yellow'} size={'large'}>
          {index + 1}
        </Label>
      )
    case 1:
      return (
        <Label circular color={'grey'} size={'medium'}>
          {index + 1}
        </Label>
      )
    case 2:
      return (
        <Label circular color={'brown'} size={'small'}>
          {index + 1}
        </Label>
      )
    default:
      return (
        <Label circular size={'mini'}>
          {index + 1}
        </Label>
      )
  }
}

function Podium({ element }) {
  const createdAtDate = dayjs(element.createdAt).local()
  const sortedSubmissions = [...(element?.data?.submissions || [])].sort((a, b) =>
    dayjs(a.submittedAt).isAfter(dayjs(b.submittedAt)) ? 1 : -1
  )
  const theme = useTheme()
  let dates = []
  let intervals = []
  let times = []
  let firstDate

  sortedSubmissions.map((sub, index) => {
    const dayjsLocal = dayjs(sub.submittedAt).local()
    const timeElapsed = dayjsLocal.diff(createdAtDate, 'millisecond')

    if (index != 0) {
      intervals.push(
        timeIntervalAsF1(dayjsLocal.diff(firstDate, 'millisecond'))
      )
    } else {
      firstDate = dayjsLocal
      intervals.push('')
    }

    dates.push(dayjsLocal.format('h:mm:s A'))
    times.push(timeInterval(timeElapsed).elapsedFormatted)
  })

  return (
    <div style={{ padding: '1rem' }}>
      <StyledH1 style={{ textAlign: 'center' }}>
        {element.config.title}
      </StyledH1>
      <Table
        style={{ width: 'auto', margin: 'auto', border: 'none' }}
        unstackable
        inverted={theme.isDark}
      >
        <Table.Header>
          <Table.Row style={{ border: 'none' }}>
            <NoBorderHeaderCell></NoBorderHeaderCell>
            <NoBorderHeaderCell></NoBorderHeaderCell>
            <NoBorderHeaderCell>Hora</NoBorderHeaderCell>
            <NoBorderHeaderCell>Tiempo</NoBorderHeaderCell>
            <NoBorderHeaderCell>Intervalo</NoBorderHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedSubmissions.map((submission, index) => (
            <Table.Row style={{ border: 'none' }} key={submission.userUuid}>
              <NoBorderCell>{PodiumBadge(index)}</NoBorderCell>
              <NoBorderCell
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AvatarWrapper>
                  <Avatar
                    src={submission.avatarUrl}
                    size={35}
                    showBorder={false}
                  />
                </AvatarWrapper>
                <span style={{ paddingLeft: '0.7rem' }}>
                  {submission.givenName}
                </span>
              </NoBorderCell>
              <NoBorderCell>
                <span>{dates[index]}</span>
              </NoBorderCell>
              <NoBorderCell>
                <span>{times[index]}</span>
              </NoBorderCell>
              <NoBorderCell textAlign="right">
                <span
                  style={{
                    color: index === 0 ? '#499F68' : '#D33E43',
                  }}
                >
                  {intervals[index]}
                </span>
              </NoBorderCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default function Race({
  raceId,
  element,
  elementNotFound,
  enrollmentGroupQuery,
  elementConfig = {},
}) {
  const socket = useContext(SocketContext)
  const currentUser = useSelector(selectCurrentUser)
  const [title, setTitle] = useState('')
  const [createElement, { isLoading: isCreatingElement }] =
    useCreateElementMutation()
  const [addRaceSubmission, { isLoading: isAddingSubmission }] = useAddRaceSubmissionMutation()

  // Creates a race element
  const handleCreateRace = async () => {
    try {
      await createElement({
        id: raceId,
        type: 'race',
        config: {
          ...elementConfig,
          title,
        },
        data: {
          submissions: [],
        },
      });
    } catch (err) {
      console.log(err)
    }
  }

  // Completes race
  const onConfirmButtonClicked = async () => {
    try {
      await addRaceSubmission({
        elementId: raceId
      }).unwrap();
    } catch (err) {
      console.log(err)
    }
  }

  if (enrollmentGroupQuery.isFetching) {
    return (
      <LoaderContainer>
        <Loader active />
      </LoaderContainer>
    )
  } else if (element !== null) {
    const enrollments = enrollmentGroupQuery?.data?.enrollments || []
    const isEnrolled = enrollments.findIndex(
      (e) => e.userId === currentUser.id
    ) !== -1
    const didUserSubmit = element?.data?.submissions.findIndex(
      (e) => e.userUuid === currentUser.id
    ) !== -1

    // Calculate time elapsed
    const createdAtDate = dayjs(element.createdAt).local()
    const currentDate = dayjs().local()
    const timeElapsed = currentDate.diff(createdAtDate, 'millisecond')

    if (!currentUser.isStudent || didUserSubmit || !isEnrolled) {
      return <Podium element={element} />
    } else if (currentUser.isStudent) {
      return (
        <Flex align="center" justify="center" column full>
          <div style={{ textAlign: 'center' }}>
            <StyledH1>{element.config.title}</StyledH1>
            <Stopwatch DEFAULT_ELAPSED={timeElapsed} />
            <Button
              primary
              size="massive"
              loading={isAddingSubmission}
              disabled={isAddingSubmission}
              onClick={onConfirmButtonClicked}
              style={{ color: 'white' }}
            >
              ¡Terminé!{' '}
              <Icon
                style={{ marginLeft: '0.5rem', marginRight: '0' }}
                name="hand paper outline"
              />
            </Button>
          </div>
        </Flex>
      )
    }
  } else if (elementNotFound && !currentUser.isStudent) {
    return (
      <InputContainer>
        <StyledH1>Título o pregunta</StyledH1>
        <StyledInput
          placeholder="Título"
          style={{ width: '100%', marginTop: '1rem' }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <CreateButtonContainer>
          <Button
            primary
            disabled={isCreatingElement}
            loading={isCreatingElement}
            onClick={handleCreateRace}
          >
            Crear
          </Button>
        </CreateButtonContainer>
      </InputContainer>
    )
  } else {
    return <h1>Aún no terminan de crear esta actividad</h1>
  }
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`
const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
`
const NoBorderCell = styled(Table.Cell)`
  border: none !important;
`
const NoBorderHeaderCell = styled(Table.HeaderCell)`
  border: none !important;
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const StyledInput = styled(Input)`
  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
const StyledH1 = styled.h1`
  color: ${(props) => props.theme.text};
`
