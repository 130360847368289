/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { Button } from 'semantic-ui-react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import getFileUrl from 'app/utils/getFileUrl'
import { useCreateSubmissionMutation } from 'app/store/apiHubSlice'
import { selectCurrentUser } from 'app/store/authSlice'
import ParentInformer from 'app/utils/ParentInformer'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { Label as SemanticUILabel } from 'semantic-ui-react'
import { selectEnrollmentId, selectViewOnly } from 'app/store/uiSlice'
import { useNavigate } from 'react-router-dom'

const ItemType = {
  LABEL: 'label',
}

const Label = ({
  id,
  name,
  moveLabel,
  preventDrag,
  isCorrect,
  showResults,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.LABEL,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !preventDrag,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        moveLabel(item.id, dropResult.id)
      }
    },
  }))

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className={`
        cursor-move
        text-center
        text-sm
        tracking-wider
        font-semibold
        p-4
        shadow
        drop-shadow-sm
        ${showResults ? (isCorrect ? 'bg-green-100' : 'bg-red-100') : 'bg-white'
        }
        rounded-full
        transform 
        transition duration-500 hover:scale-105
      `}
    >
      {name}
    </div>
  )
}

const ImageDropZone = ({ id, imageUrl, name, accept, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept,
    drop: () => ({ id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={drop}
      style={{
        backgroundColor: canDrop ? '#e6ffe6' : isOver ? '#ffe6e6' : 'white',
      }}
      className={`flex flex-col items-center ${name ? 'justify-center' : ''
        } w-full rounded-md border-solid border-2 border-slate-200 p-3 overflow-clip`}
    >
      {imageUrl ? (
        <img
          className="w-full h-24 object-contain"
          src={imageUrl}
          alt="target"
        />
      ) : (
        <p className="text-sm font-light">{name}</p>
      )}
      {children}
    </div>
  )
}

const Assignment = ({
  assignment,
  documentUuid,
  labels,
  imageTargets,
  initialSelectedLabels = [],
  hideSubmitButton = false,
  preventDrag = false,
  showResults = false,
  showScore = false,
  hideTitle = false,
}) => {
  const [createSubmission, { isLoading }] = useCreateSubmissionMutation()
  const currentUser = useSelector(selectCurrentUser)
  const theme = useTheme()
  const navigate = useNavigate()
  const enrollmentId = useSelector(selectEnrollmentId)
  const [selectedLabels, setSelectedLabels] = useState(initialSelectedLabels)
  const [score, setScore] = useState(null)

  const moveLabel = (fromLabelId, toDropZoneId) => {
    setSelectedLabels((selectedLabels) => {
      const labelExists = selectedLabels.some(
        (label) => label.id === fromLabelId
      )

      if (!labelExists) {
        return [
          ...selectedLabels,
          { id: fromLabelId, dropZoneId: toDropZoneId },
        ]
      }

      return selectedLabels.map((label) => {
        if (label.id === fromLabelId) {
          return { ...label, dropZoneId: toDropZoneId }
        }
        return label
      })
    })
  }

  useEffect(() => {
    if (selectedLabels.length === labels.length) {
      const correctAnswers = selectedLabels.filter((selectedLabel) => {
        const imageTarget = imageTargets.find((imageTarget) =>
          imageTarget.labels.includes(selectedLabel.id)
        )
        const imageTargetIndex = imageTargets.findIndex(
          (i) => i === imageTarget
        )
        return imageTarget && selectedLabel.dropZoneId === imageTargetIndex
      }).length

      setScore(Math.round((correctAnswers / labels.length) * 10))
    }
  }, [selectedLabels, labels.length, imageTargets])

  // Handle submit
  const handleSubmit = async () => {
    const payload = {
      assignmentId: assignment.id,
      userId: currentUser.id,
      documentId: documentUuid,
      details: {
        selectedLabels,
      },
      grade: parseFloat(score),
      enrollmentId,
    }

    try {
      // Save submission
      await createSubmission(payload).unwrap()

      // Inform parent
      ParentInformer.sendSubmission(assignment)
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }

  // Checks if a label is correct
  const isLabelCorrect = ({ label }) => {
    const imageTarget = imageTargets.find((imageTarget) =>
      imageTarget.labels.includes(label.id)
    )
    const imageTargetIndex = imageTargets.findIndex((i) => i === imageTarget)
    return imageTarget && label.dropZoneId === imageTargetIndex
  }

  const editAssignment = () => {
    navigate(`/drag-n-drop/${assignment.id}/edit?user_id=${currentUser.id}`, {
      state: { prev: location.search },
    })
  }

  return (
    <div className="p-3">
      {/* Title */}
      {!hideTitle && (
        <div className="flex justify-center w-full mb-4">
          <div>
            <Title>{assignment.title}</Title>
          </div>
        </div>
      )}
      {/* Grade */}
      {showScore && score !== null && (
        <Meta>
          Calificación:{' '}
          <SemanticUILabel
            circular
            style={{ marginLeft: '5px' }}
            color={score > 5 ? 'green' : 'red'}
            size="large"
          >
            {score}
          </SemanticUILabel>
        </Meta>
      )}
      {/* Drag and drop */}
      <DndProvider backend={HTML5Backend}>
        <div className="grid justify-around grid-cols-4 p-1 gap-4">
          {imageTargets.map((imageTarget, index) => (
            <ImageDropZone
              key={index}
              id={index}
              name={imageTarget.name}
              imageUrl={imageTarget.imageUrl}
              accept={ItemType.LABEL}
              onDrop={moveLabel}
            >
              <div className="flex flex-wrap gap-2 justify-center">
                {selectedLabels
                  .filter((selectedLabel) => selectedLabel.dropZoneId === index)
                  .map((selectedLabel) => {
                    const labelData = labels.find(
                      (label) => label.id === selectedLabel.id
                    )
                    return (
                      labelData && (
                        <Label
                          key={labelData.id}
                          id={labelData.id}
                          name={labelData.name}
                          moveLabel={moveLabel}
                          preventDrag={preventDrag}
                          isCorrect={isLabelCorrect({
                            label: {
                              ...labelData,
                              dropZoneId: selectedLabel.dropZoneId,
                            },
                          })}
                          showResults={showResults}
                        />
                      )
                    )
                  })}
              </div>
            </ImageDropZone>
          ))}
        </div>
        <div className="flex flex-wrap mt-4 gap-4">
          {labels
            .filter(
              (label) =>
                !selectedLabels.some(
                  (selectedLabel) => selectedLabel.id === label.id
                )
            )
            .map((label) => (
              <Label
                key={label.id}
                id={label.id}
                name={label.name}
                moveLabel={moveLabel}
                preventDrag={preventDrag}
                isCorrect={isLabelCorrect({ label })}
                showResults={showResults}
              />
            ))}
        </div>
      </DndProvider>
      {currentUser.isStudent && !hideSubmitButton && (
        <div className="w-full flex items-center justify-center mt-4">
          <Button
            primary
            size="large"
            disabled={score === null}
            onClick={handleSubmit}
            loading={isLoading}
          >
            ¡Terminé!
          </Button>
        </div>
      )}
      {hideSubmitButton &&
        (currentUser.isSchoolAdmin || currentUser.isAdmin || currentUser.isInstructor) && (
          <div className="mt-5">
            <Button
              inverted={theme.isDark}
              disabled={
                (assignment?.configuration?.isSynchronous &&
                  assignment?.configuration?.isOpen) ??
                false
              }
              onClick={editAssignment}
            >
              Editar actividad
            </Button>
          </div>
        )}
    </div>
  )
}

export const DragAndDrop = ({
  assignment,
  submission,
  documentUuid,
  hideSubmitButton = false,
  showResults = false,
  showScore = false,
  hideTitle = false,
}) => {
  const labels = assignment.configuration.leftSideConcepts
  const viewOnly = useSelector(selectViewOnly)
  // Get image targets
  const [imageTargets, setImageTargets] = useState(
    assignment.configuration.rightSideConcepts
  )

  // Handle images loading
  const [isLoadingImages, setIsLoadingImages] = useState(true)

  // Get a valid imageUrl for all image targets
  useEffect(() => {
    // Get valid image urls
    async function getImagesUrls() {
      let updatedImageTargets = [...imageTargets]

      for (let i = 0; i < updatedImageTargets.length; i++) {
        let element = updatedImageTargets[i]

        if (element.imageUrl) {
          const url = await getFileUrl(
            element.imageUrl,
            process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
          )
          element = {
            ...element,
            imageUrl: url,
          }
          updatedImageTargets.splice(i, 1, element)
        }
      }

      // Update state
      setImageTargets(updatedImageTargets)
      setIsLoadingImages(false)
    }

    getImagesUrls()
  }, [])

  if (isLoadingImages) {
    return <></>
  }

  return (
    <>
      {/* Assignment */}
      <Assignment
        assignment={assignment}
        documentUuid={documentUuid}
        labels={labels}
        imageTargets={imageTargets}
        initialSelectedLabels={
          submission && submission.details
            ? submission.details.selectedLabels
            : []
        }
        hideSubmitButton={submission || viewOnly ? true : hideSubmitButton}
        preventDrag={!!submission}
        showResults={showResults}
        showScore={showScore}
        hideTitle={hideTitle}
      />
    </>
  )
}
const Title = styled.p`
  font-size: 2em;
  margin: 0;
  font-weight: 800;
  width: 100%;
  color: ${(props) => props.theme.text};
`
const Meta = styled.div`
  font-size: 1.3em;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.textTertiary};
`
