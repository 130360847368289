export const getContrastColorHex = (hexColor) => {
  const color = hexColor.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b
  return luminance > 186 ? '#333333' : '#FFFFFF'
}

export const getContrastColorRgb = (rgbcolor) => {
  const r = rgbcolor[0]
  const g = rgbcolor[1]
  const b = rgbcolor[2]
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#333' : '#fff'
}

export const getContrastColorRgba = (rgbacolor) => {
  const match = rgbacolor.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/)
  if (!match) return 'rgba(51, 51, 51, 1)'
  const r = parseInt(match[1], 10)
  const g = parseInt(match[2], 10)
  const b = parseInt(match[3], 10)

  const brightness = r * 0.299 + g * 0.587 + b * 0.114
  return brightness > 128 ? 'rgba(51, 51, 51, 1)' : 'rgba(255, 255, 255, 1)'
}
