import styled, { useTheme } from 'styled-components'
import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useCreateForumPostMutation } from 'app/store/apiHubSlice'
import FileUploader from './FileUploader'
import ParentInformer from '../../../../utils/ParentInformer'
import { selectCurrentUser } from '../../../../store/authSlice'
import { selectEnrollmentId } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import uploadMultipleFiles from '../../../../utils/uploadMultipleFiles'
import { getRandomColor } from 'app/utils/defaultColors'

export default function NewPost({ assignment, documentId, onCancel }) {
  const [createForumPost, { isLoading: isCreatingPost }] =
    useCreateForumPostMutation()
  const [creating, setCreating] = useState(false)
  const [newPost, setNewPost] = React.useState({ title: '', content: '' })
  const [myFiles, setMyFiles] = useState([])
  const currentUser = useSelector(selectCurrentUser)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const theme = useTheme()
  const [errorMessage, setErrorMessage] = useState(null)
  const enrollmentId = useSelector(selectEnrollmentId)

  const createNewPost = async (files) => {
    try {
      const forumPost = await createForumPost({
        userId: currentUser.id,
        assignmentId: assignment.id,
        documentId: documentId,
        content: newPost.content,
        files: files,
        title: assignment.title,
        details: {
          backgroundColor: getRandomColor(),
        },
        enrollmentId,
      })

      if (!forumPost.data) {
        setErrorMessage('Ocurrió un error al publicar tu entrada.')
        return
      }

      window.location.reload()
      //TODO: invalidae cache to refetch posts
      // dispatch(setStudentAnsweredForum(true));
      ParentInformer.sendSubmission(assignment)
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditorChanged = (state) => {
    setEditorState(state)
    setNewPost({
      ...newPost,
      content: draftToHtml(convertToRaw(state.getCurrentContent())),
    })
  }

  const handleCreatePost = async () => {
    if (newPost.content.length < 9) {
      return
    }

    setErrorMessage(null)
    setCreating(true)

    try {
      const files = await uploadMultipleFiles(
        myFiles,
        `assigments/${assignment.id}/${currentUser.id}`
      )
      await createNewPost(files)
    } catch (err) {
      setErrorMessage('Ocurrió un error al publicar tu entrada.')
    }
    setCreating(false)
  }

  return (
    <EditorContainer
      style={{
        overflow: 'auto',
        marginTop: '2rem',
        marginBottom: '4rem',
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChanged}
        toolbar={{
          options: ['inline', 'blockType', 'list'],
          blockType: {
            inDropdown: false,
          },
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
        placeholder="Escribe aquí..."
        editorStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
        toolbarStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
      />
      <FileUploader myFiles={myFiles} setMyFiles={setMyFiles} />
      <div
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
        }}
      >
        <Button onClick={() => onCancel()}>Cancelar</Button>
        <Popup
          content="¡Debes escribir algo en tu entrada!"
          on="hover"
          disabled={newPost.content.length >= 9}
          trigger={
            <Button
              disabled={isCreatingPost || creating}
              loading={isCreatingPost || creating}
              primary
              onClick={handleCreatePost}
            >
              Guardar
            </Button>
          }
        />
      </div>
      {errorMessage && (
        <div style={{ textAlign: 'right' }}>
          <small style={{ color: 'red' }}>{errorMessage}</small>
        </div>
      )}
    </EditorContainer>
  )
}

const EditorContainer = styled.div``
