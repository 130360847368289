import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/es-mx'
export default function DateTime({ utc = true, value, format = 'LLL a' }) {
  return (
    <Moment utc={utc} locale="es-mx" format={format}>
      {value}
    </Moment>
  )
}
