import React from 'react'
export default function Flex({
  children,
  column = false,
  align = 'center',
  justify = 'center',
  paddingLeft = 30,
  paddingRight = 30,
  full = false,
  margin = '',
  minHeight = '100%',
  gap = 0,
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        justifyContent: justify,
        alignItems: align,
        paddingLeft: full ? 0 : paddingLeft,
        paddingRight: full ? 0 : paddingRight,
        minHeight,
        minWidth: '100%',
        overflowY: full ? 'hidden' : 'auto',
        overflowX: full ? 'hidden' : 'auto',
        gap,
        margin: margin,
      }}
    >
      {children}
    </div>
  )
}
