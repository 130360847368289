import React, { useState } from 'react'
import { Widget } from '@typeform/embed-react'
import ParentInformer from '../../utils/ParentInformer'
import {
  useCreateSubmissionMutation,
  useGetTypeformInfoResponseMutation,
} from 'app/store/apiHubSlice'
import { selectCurrentUser } from '../../store/authSlice'
import { selectEnrollmentId, selectViewOnly } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'
import Flex from '../containers/Flex'
import styled from 'styled-components'
import { Loader } from 'semantic-ui-react'

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time * 1000))
}

const MAX_REQUEST_ATTEMPS = 5
export default function TypeForm({ assignment, documentId }) {
  const [finished, setFinished] = useState(false)
  const [isSavingTypeform, setIsSavingTypeform] = useState(false)
  const [onMaxAttemptsError, setOnMaxAttemptsError] = useState(false)

  const enrollmentId = useSelector(selectEnrollmentId)
  const currentUser = useSelector(selectCurrentUser)
  const viewOnly = useSelector(selectViewOnly)

  const [createSubmission, { isSuccess, reset }] = useCreateSubmissionMutation()
  const [typeformInfoResponse] = useGetTypeformInfoResponseMutation()

  const form_id =
    typeof assignment.configuration === 'string'
      ? JSON.parse(assignment.configuration.replaceAll('=>', ':')).form_id
      : assignment.configuration.form_id

  const handleSubmit = async (event) => {
    if (!currentUser.isStudent) {
      return
    }

    // Store saving attempts
    let didSaveSubmission = false
    let currentAttemps = 0

    // Reseting vars when invoking again the submit function
    if (currentAttemps === MAX_REQUEST_ATTEMPS) {
      currentAttemps = 0
      setOnMaxAttemptsError(false)
      setFinished(false)
      reset()
    }

    // Try to save typeform
    do {
      // Mark typeform as saving
      setIsSavingTypeform(true)

      try {
        // Get typeform response
        const typeformResponse = await typeformInfoResponse({
          formId: form_id.includes('#') ? form_id.split('#')[0] : form_id,
          responseId: event.responseId,
        })

        // Get typeform details
        const { fields, items } = typeformResponse.data

        // Form payload
        const payload = {
          assignmentId: assignment.id,
          userId: currentUser.id,
          documentId: documentId,
          details: {
            formId: form_id,
            responseId: event.responseId,
            fields,
            answers: items[0].answers,
          },
          enrollmentId,
        }

        // Create submission
        await createSubmission(payload).unwrap()

        // Handle success
        didSaveSubmission = true
        setIsSavingTypeform(false)
        setFinished(true)
      } catch (err) {
        await delay(3)
      } finally {
        currentAttemps += 1
        // handle maxAttempts retries, to conditional rendering ui
        setOnMaxAttemptsError(currentAttemps === MAX_REQUEST_ATTEMPS)
      }
    } while (!didSaveSubmission && currentAttemps < MAX_REQUEST_ATTEMPS)

    // if max attempts are reached, reset the saving form state
    if (currentAttemps === MAX_REQUEST_ATTEMPS) {
      setIsSavingTypeform(false)
    }
  }
  React.useEffect(() => {
    if (finished && isSuccess) {
      ParentInformer.sendSubmission(assignment)
    }
  }, [finished, isSuccess])

  return (
    <Flex minHeight="600px" column full>
      {isSuccess ? (
        <StyledH3>Actividad terminada</StyledH3>
      ) : (
        <>
          {isSavingTypeform && (
            <Flex>
              <Loader active />
              <p style={{ marginTop: '3rem' }}>Guardando respuestas</p>
            </Flex>
          )}
          {onMaxAttemptsError && !finished && !isSavingTypeform && (
            <StyledH3>
              No pudimos guardar la actividad, intentalo de nuevo
            </StyledH3>
          )}
          <Widget
            disableAutoFocus
            hidden={{
              user_id: currentUser.id,
              assignment_id: assignment.id,
            }}
            id={form_id}
            style={{
              width: '100%',
              height: '80vh',
              opacity: isSavingTypeform ? 0 : 1,
            }}
            onSubmit={!viewOnly ? handleSubmit : () => {}}
          />
        </>
      )}
    </Flex>
  )
}

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.text};
`
