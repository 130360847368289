import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import draftToHtml from 'draftjs-to-html'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTheme } from 'styled-components'

export default function QuestionInput({ value, onChange }) {
  const [editorState, setEditorState] = useState(() => {
    const opts = {
      styleToHTML: (style) => {
        if (style === 'SUPERSCRIPT') {
          return {
            start: '<sup>',
            end: '</sup>',
          }
        }
        if (style === 'SUBSCRIPT') {
          return {
            start: '<sub>',
            end: '</sub>',
          }
        }
      },
      htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'sup') {
          return currentStyle.add('SUPERSCRIPT')
        }
        if (nodeName === 'sub') {
          return currentStyle.add('SUBSCRIPT')
        }

        return currentStyle
      },
    }

    if (value) {
      return EditorState.createWithContent(convertFromHTML(opts)(value))
    } else {
      return EditorState.createEmpty()
    }
  })
  const theme = useTheme()

  const handleEditorChanged = (state) => {
    const content = draftToHtml(convertToRaw(state.getCurrentContent()))
    setEditorState(state)
    if (onChange) {
      onChange(content)
    }
  }

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorChanged}
      toolbar={{
        options: ['inline'],
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
        },
      }}
      wrapperStyle={{ flexGrow: '1' }}
      placeholder="Opción sin título"
      editorStyle={{
        backgroundColor: theme.sidebarBackground,
        color: theme.text,
        border: `1px solid ${theme.cardBorder}`,
        borderRadius: '4px',
        minHeight: '4rem',
        maxHeight: '4rem',
        overflow: 'hidden',
        width: '100%',
      }}
      toolbarStyle={{
        backgroundColor: theme.sidebarBackground,
        color: theme.text,
        border: `1px solid ${theme.cardBorder}`,
        borderRadius: '4px',
      }}
    />
  )
}
