import axios from 'axios'
export default async function getFileUrl(url, bucket) {
  const resp = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/v2/utils/s3-signed-url`,
    {
      fileUrl: url,
      bucketName:
        bucket || process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME,
    },
    {
      headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY },
    }
  )
  return resp.data.url
}
