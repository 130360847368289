// Components
import Instructions from './components/Instructions'
import Workspace from './components/Workspace'

// Constans
import { EDITOR_BG_COLOR } from './constants'

const Main = ({ submission }) => {
  return (
    <main
      className={`flex w-full flex-col rounded-[8px]  h-[700px] relative bg-[${EDITOR_BG_COLOR}] `}
    >
      <Instructions />

      <div className="flex-1 w-full min-w-0 overflow-hidden rounded-[8px]">
        <Workspace submission={submission} />
      </div>
    </main>
  )
}

export default Main
