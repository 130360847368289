import ParentInformer from 'app/utils/ParentInformer'
import { useCodeRunnerStore } from './useCodeRunnerStore'
import useCurrentUser from './useCurrentUser'
import { useCreateSubmissionMutation } from 'app/store/apiHubSlice'
import { selectEnrollmentId } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'

const useCodeSubmission = () => {
  const { assignment, documentId } = useCodeRunnerStore()
  const currentUser = useCurrentUser()
  const enrollmentId = useSelector(selectEnrollmentId)

  const [createSubmission, { isLoading: isSubmitting, isError, reset }] =
    useCreateSubmissionMutation()

  const runSubmitMutation = async (code = '') => {
    if (!currentUser?.isStudent || !code) {
      return
    }

    try {
      // Form payload
      const payload = {
        assignmentId: assignment.id,
        userId: currentUser.id,
        documentId: documentId,
        details: {
          code,
        },
        enrollmentId,
      }

      // Save submission
      await createSubmission(payload).unwrap()

      // Inform parent
      ParentInformer.sendSubmission(assignment)

      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }
  return { runSubmitMutation, isSubmitting, isError, reset }
}

export default useCodeSubmission
