// UI libraries
import { useTheme } from 'styled-components'
import { Button } from 'semantic-ui-react'

export function BasicButton({ children, ...rest }) {
  const theme = useTheme()

  return (
    <Button basic inverted={theme.isDark} {...rest}>
      {children}
    </Button>
  )
}
