import { useDispatch, useSelector } from 'react-redux'
import {
  setReady,
  selectAssigment,
  setAssigment,
} from 'app/store/assigmentCreationSlice'

export const CodingConfig = () => {
  let dispatch = useDispatch()
  const assignment = useSelector(selectAssigment)

  // Handle instructions change
  const onInstructionsChange = ({ target }) => {
    dispatch(
      setAssigment({
        ...assignment,
        description: target.value,
      })
    )

    dispatch(setReady(target.value.length > 3))
  }

  return (
    <div className="mb-6 w-full">
      <label
        htmlFor="message"
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        Instrucciones
      </label>
      <textarea
        id="message"
        rows="4"
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-[8px] border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        placeholder="Escribe las instrucciones del código aquí..."
        onChange={onInstructionsChange}
      />
    </div>
  )
}
