import styled from 'styled-components'

export const Spinner = ({ size }) => {
  return <SpinnerElement size={size} />
}

const SpinnerElement = styled.div`
  border: 3px solid rgba(95, 107, 124, 0.2);
  border-top: 3px solid rgba(95, 107, 124, 0.8);
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size + 'px' : '32px')};
  height: ${(props) => (props.size ? props.size + 'px' : '32px')};
  animation: spin 0.6s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
