import React from 'react'
import Flex from '../../../components/containers/Flex'
import { useSelector } from 'react-redux'
import 'react-leaf-polls-copy/dist/index.css'

import Coding from '../Coding/bootstrap'
import SubmissionFeedback from 'app/elements/SubmissionFeedback'
import {
  selectSelectedAssignment,
  selectSelectedSubmission,
} from '../../../store/uiSlice'
export default function SubmittedCodingAssignment() {
  const assignment = useSelector(selectSelectedAssignment)
  const submission = useSelector(selectSelectedSubmission)
  let content = <Coding assignment={assignment} submission={submission} />

  return (
    <Flex column justify={'flex-start'} align="flex-start" full>
      {content}
      <SubmissionFeedback />
    </Flex>
  )
}
