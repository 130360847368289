import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react'
import { Icon, Button, Loader } from 'semantic-ui-react'
import Webcam from 'react-webcam'
import styled from 'styled-components'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import timeInterval from '../../../../utils/timeInterval'
import ReactPlayer from 'react-player'
import { isFirefox } from 'react-device-detect'

const videoConstraints = {
  facingMode: 'user',
}
const DEFAULT_LAPS = []

export default function NewVideo({ onPost, onCancel }) {
  const [capturing, setCapturing] = useState(false)
  const [cameraPermissions, setCameraPermissions] = useState(null)
  const [micPermissions, setMicPermissions] = useState(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [mimeType, setMimeType] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [elapsed, setElapsed] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null)
  const [isTicking, setIsTicking] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [laps, setLaps] = useState(DEFAULT_LAPS)
  const [isLoadingWebcam, setIsLoadingWebcam] = useState(true)
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isFirefox) {
      setCameraPermissions('granted')
      setMicPermissions('granted')
    } else {
      navigator.permissions.query({ name: 'camera' }).then((res) => {
        if (res && res.state) {
          setCameraPermissions(res.state)
        } else {
          setCameraPermissions('unknown')
        }
      })

      navigator.permissions.query({ name: 'microphone' }).then((res) => {
        if (res && res.state) {
          setMicPermissions(res.state)
        } else {
          setMicPermissions('unknown')
        }
      })
    }

    if (MediaRecorder.isTypeSupported('video/mp4')) {
      setMimeType('video/mp4')
    } else {
      // Brave browser not supports video/mp4 so
      // check video/webm instead
      if (MediaRecorder.isTypeSupported('video/webm')) {
        setMimeType('video/webm')
      }
    }
  }, [])

  useEffect(() => {
    if (cameraPermissions === 'granted' && micPermissions === 'granted') {
      requestCameraAndMicPermissions()
    }
  }, [micPermissions, setMicPermissions])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (!capturing) {
      handleDownload()
    }
  }, [recordedChunks])

  useLayoutEffect(() => {
    if (!isTicking) return
    setIntervalId(setInterval(() => update(), 10))
  }, [isTicking])

  const requestCameraAndMicPermissions = () => {
    requestMediaPermissions()
      .then(() => {
        // can successfully access camera and microphone streams
        setShowWebcam(true)
      })
      .catch((err) => {
        const { type } = err
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          setCameraPermissions('system_denied')
          setMicPermissions('system_denied')
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          setCameraPermissions('denied')
          setMicPermissions('denied')
        } else if (
          type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          setCameraPermissions('already_used')
          setMicPermissions('already_used')
        } else {
          // not all error types are handled by this library
          setCameraPermissions('unknown')
          setMicPermissions('unknown')
        }
      })
  }

  const onCancelButtonClicked = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    start(elapsedFormatted)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType,
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing])

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: mimeType,
      })
      const url = URL.createObjectURL(blob)
      setVideoUrl(url)
    }
  }, [recordedChunks])

  const start = (elapsedFormatted) => {
    if (isTicking) {
      mark(elapsedFormatted)
      return
    }

    if (!startTime) setStartTime(Date.now())
    setIsTicking(true)
  }
  const update = () => {
    const now = Date.now()
    setElapsed(now - startTime + elapsed)
  }
  const mark = (elapsedFormatted) => {
    if (!isTicking) return
    setLaps([elapsedFormatted, ...laps])
  }

  const onConfirmButtonClicked = () => {
    const blob = new Blob(recordedChunks, { type: mimeType })
    const file = new File([blob], new Date().toISOString(), { type: mimeType })
    onPost(file, true)
  }

  const handleUserMedia = () =>
    setTimeout(() => setIsLoadingWebcam(false), 1_000)

  const { elapsedFormatted } = timeInterval(elapsed)
  let mainContent
  let actionsContent

  // Check if we have permissions
  if (cameraPermissions === 'prompt' || micPermissions === 'prompt') {
    mainContent = (
      <PermissionsContainer>
        <h3>
          Para grabar video, tu navegador deberá solicitar acceso a la cámara y
          micrófono.
        </h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="eye slash outline" size="big" />
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <span>La cámara y el micrófono no están activos</span>
        </PermissionsMessageContainer>
        <Button primary onClick={requestCameraAndMicPermissions}>
          Solicitar permisos
        </Button>
      </PermissionsContainer>
    )
  } else if (cameraPermissions === 'denied' || micPermissions === 'denid') {
    mainContent = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos de cámara y micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="eye slash outline" size="big" />
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>
            La cámara y el micrófono no están activos
          </h5>
          <br />
          <span>
            Haz clic en el ícono 🔒 en la barra de direcciones de su navegador,
            <br />
            configura los permisos correctos y luego actualiza esta página.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (
    cameraPermissions === 'system_denied' ||
    micPermissions === 'system_denied'
  ) {
    mainContent = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos de cámara y micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="eye slash outline" size="big" />
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>
            La cámara y el micrófono no están activos
          </h5>
          <br />
          <span>
            Ve a las preferencias del sistema y concede permisos a tu navegador.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (
    cameraPermissions === 'already_used' ||
    micPermissions === 'already_used'
  ) {
    mainContent = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos de cámara y micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="eye slash outline" size="big" />
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>
            La cámara y el micrófono no están activos
          </h5>
          <br />
          <span>
            Asegúrate de que no haya otras aplicaciones o páginas usando tu
            micrófono y tu cámara.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (cameraPermissions === 'unknown' || micPermissions === 'unknown') {
    mainContent = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos de cámara y micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="eye slash outline" size="big" />
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>
            La cámara y el micrófono no están activos
          </h5>
          <br />
          <span>
            Ocurrió un error al intentar acceder a la cámara y micrófono.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (!cameraPermissions || !micPermissions) {
    mainContent = <Loader active />
  }

  // Set camera actions
  if (capturing) {
    actionsContent = (
      <>
        {/* Timer */}
        <div style={{ marginBottom: '1rem', color: 'white' }}>
          <div className="stopwatch-time elapsed">{elapsedFormatted}</div>
        </div>
        {/* Stop button */}
        <StopButton
          style={{ display: 'flex' }}
          onClick={handleStopCaptureClick}
        >
          <div
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: 'rgba(227, 73, 28, 0.9)',
              borderRadius: '6px',
            }}
          />
        </StopButton>
      </>
    )
  } else if (!isLoadingWebcam) {
    actionsContent = (
      <ActionsContainer>
        <RecordButton onClick={handleStartCaptureClick} />
        <CancelButton onClick={onCancelButtonClicked}>
          <NoMarginIcon name="x" size="big" />
        </CancelButton>
      </ActionsContainer>
    )
  }

  // Set camera if we have permissions
  if (showWebcam && recordedChunks.length === 0) {
    mainContent = (
      <CameraContainer>
        {/* Loader */}
        {isLoadingWebcam && <Loader active />}
        {/* Camera */}
        <HybridgeCam
          ref={webcamRef}
          width={'100%'}
          audio
          mirrored
          muted
          videoConstraints={videoConstraints}
          onUserMedia={handleUserMedia}
        />
        {/* Actions */}
        <VideoActionsContainer>{actionsContent}</VideoActionsContainer>
      </CameraContainer>
    )
  } else if (recordedChunks.length > 0) {
    mainContent = (
      <PlayerContainer>
        <HybridgePlayer
          url={videoUrl}
          controls={false}
          playing
          loop
          width="100%"
          height="auto"
        ></HybridgePlayer>
        <VideoActionsContainer>
          {/* Text */}
          <p style={{ color: 'rgb(255, 255, 255)' }}>
            ¿Quieres usar este video?
          </p>
          {/* Options */}
          <div style={{ display: 'flex', gap: '10px' }}>
            <ConfirmButton onClick={onConfirmButtonClicked}>Sí</ConfirmButton>
            <DiscardButton onClick={onCancelButtonClicked}>No</DiscardButton>
          </div>
        </VideoActionsContainer>
      </PlayerContainer>
    )
  }

  return mainContent
}

const PermissionsContainer = styled.div`
  min-height: 300px;
`
const PermissionsMessageContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 10rem;
  border-radius: 16px;
  background: ${(props) =>
    props.theme.isDark ? 'black' : 'rgb(240, 240, 240)'};
  margin: 32px 0px;
  padding: 4rem;
`
const NoMarginIcon = styled(Icon)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`
const CameraContainer = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
`
const PlayerContainer = styled.div`
  position: relative;
  min-height: 500px;
`
const HybridgeCam = styled(Webcam)`
  border-radius: 15px;
  position: relative;
`
const HybridgePlayer = styled(ReactPlayer)`
  border-radius: 15px !important;
  overflow: hidden;
`
const ActionsContainer = styled.div`
  display: flex;
  widht: 140px;
  left: calc(50% - 50px);
  bottom: 25px;
  position: absolute;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
const RecordButton = styled(Button)`
  background-color: rgba(255, 60, 76, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
`
const StopButton = styled(Button)`
  background-color: rgba(204, 204, 204, 0.4) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
`
const CancelButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  cursor: pointer;
`
const VideoActionsContainer = styled.div`
  display: flex;
  width: 200px !important;
  left: calc(50% - 100px);
  bottom: 50px;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ConfirmButton = styled(Button)`
  background-color: rgba(54, 207, 113, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  color: white !important;
`
const DiscardButton = styled(Button)`
  background-color: rgba(149, 149, 149, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  color: white !important;
`
