import React from 'react'
import { FAILED_LOAD_SCRIPT_MESSAGE } from '../constants'

const LoadScriptFailed = () => {
  return (
    <div className="h-screen bg-slate-700 flex items-center justify-center">
      <h1 className="text-white text-2xl ">{FAILED_LOAD_SCRIPT_MESSAGE}</h1>
    </div>
  )
}

export default LoadScriptFailed
