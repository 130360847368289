import React from 'react'
import { useCodeRunnerStore } from '../hooks/useCodeRunnerStore'

const ConsoleResult = () => {
  const { consoleResult } = useCodeRunnerStore()

  return (
    <ul className="text-white text-xl font-medium p-0 mt-0">
      {consoleResult?.content?.map((item) => (
        <li key={item} className="list-none p-0 text-lg">
          {item}
        </li>
      ))}
    </ul>
  )
}

export default ConsoleResult
