import React, { useState, useEffect, useContext, useCallback } from 'react'
import types from '../components/assigments/types'
import { Button, Loader, Message } from 'semantic-ui-react'
import queryString from 'query-string'
import {
  useGetAssignmentByIdQuery,
  useGetSubmissionByAssignmentIdAndUserIdQuery,
  useToggleAssignmentOpenMutation,
} from '../store/apiHubSlice'
import {
  selectIsFullScreenActive,
  setSelectedSubmission,
  setSelectedAssignment,
  selectSelectedAssignment,
  selectSelectedSubmission,
} from '../store/uiSlice'
import { selectCurrentUser } from '../store/authSlice'
import CompletedAssigment from '../components/CompletedAssigment'
import AssigmentSummary from '../components/AssigmentSummary'
import AssignmentCreator from './AssignmentCreator/AssignmentCreator'
import ToggleViews from '../components/ToggleViews'
import ParentInformer from '../utils/ParentInformer'
import { useSelector, useDispatch } from 'react-redux'
import LoaderContainer from '../components/containers/LoaderContainer'
import FullScreen from '../components/FullScreen'
import styled from 'styled-components'
import LockedAssignment from 'app/components/LockedAssignment'
import SubmittedCodingAssignment from 'app/components/assigments/Coding/SubmittedCodingAssignment'
import Flex from 'app/components/containers/Flex'
import SubmissionDetails from 'app/components/SubmissionDetails'
import { SocketContext } from 'app/context/socket'

export function isActivityClosed({ configuration }) {
  if (!Object.hasOwn(configuration, 'isSynchronous')) {
    return false
  }

  return configuration.isSynchronous && !configuration.isOpen
}
export const dispatchCustomEvent = (eventName, eventDetail) => {
  const e = new CustomEvent(eventName, { detail: eventDetail })
  document.dispatchEvent(e)
}

export default function Assignment() {
  const dispatch = useDispatch()
  const {
    assignmentId,
    submission_id,
    submission_owner,
    type,
    enrollmentGroupId,
    enrollmentId,
  } = queryString.parse(window.location.search)
  let { documentId } = queryString.parse(window.location.search)
  const currentUser = useSelector(selectCurrentUser)
  const assigmentQuery = useGetAssignmentByIdQuery({
    id: assignmentId,
    activeOnly: true,
    showCode: !currentUser?.isStudent,
  })
  const isFullScreenActive = useSelector(selectIsFullScreenActive)
  const [toggleAssignmentOpen] = useToggleAssignmentOpenMutation()
  const [isUpdatingAssignment, setIsUpdatingAssignment] = useState(false)
  const selectedSubmission = useSelector(selectSelectedSubmission)
  const selectedAssignment = useSelector(selectSelectedAssignment)

  // Get submission
  const submissionQuery = useGetSubmissionByAssignmentIdAndUserIdQuery(
    {
      assignmentId: assignmentId,
      userId: submission_owner
        ? submission_owner
        : currentUser.isInstructor
        ? null
        : currentUser.id,
      enrollmentId,
    },
    { skip: !currentUser.isStudent }
  )
  const { socket } = useContext(SocketContext)

  const handleAssignmentUpdated = useCallback(
    (updatedAssignment) => {
      // If it's not the same as selected assignment, ignore
      if (updatedAssignment?.id !== selectedAssignment?.id) {
        return
      }

      // Update assignment
      dispatch(setSelectedAssignment(updatedAssignment))

      // Dispatch event
      dispatchCustomEvent(`assignment:${updatedAssignment.id}:sockets_update`, {
        updatedAssignment,
      })
    },
    [selectedAssignment?.id, selectedSubmission?.id]
  )

  const handleToggleAssignmentOpen = useCallback(
    (updatedAssignment) => {
      // If it's not the same as selected assignment, ignore
      if (updatedAssignment?.id !== selectedAssignment?.id) {
        return
      }

      // If the user already has a submission, ignore
      if (!!selectedSubmission) {
        return
      }

      // Update assignment
      dispatch(setSelectedAssignment(updatedAssignment))

      // Dispatch event
      dispatchCustomEvent(`assignment:${updatedAssignment.id}:sockets_update`, {
        updatedAssignment,
      })
    },
    [selectedAssignment?.id, selectedSubmission?.id]
  )

  // Join socket room
  useEffect(() => {
    // Listen to assignment events
    socket.on('assignments.update', handleAssignmentUpdated)
    socket.on('assignments.toggle_open', handleToggleAssignmentOpen)

    // Unbind all events
    return () => {
      socket.off('assignments.update')
      socket.off('assignments.toggle_open')
    }
  }, [handleAssignmentUpdated, handleToggleAssignmentOpen])

  useEffect(() => {
    if (
      !selectedSubmission &&
      submissionQuery.isSuccess &&
      submissionQuery?.data
    ) {
      dispatch(setSelectedSubmission(submissionQuery?.data?.data))
    }
    if (
      !selectedAssignment &&
      assigmentQuery.isSuccess &&
      assigmentQuery?.data
    ) {
      dispatch(setSelectedAssignment(assigmentQuery?.data))
    }
  }, [
    selectedSubmission,
    submissionQuery.isSuccess,
    submissionQuery?.data,
    selectedAssignment,
    assigmentQuery.isSuccess,
    assigmentQuery?.data,
  ])

  // Opens activity
  const toggleIsAssignmentOpen = async (assignment) => {
    setIsUpdatingAssignment(true)

    try {
      await toggleAssignmentOpen({
        id: assignment.id,
        isOpen: !assignment.configuration.isOpen,
      }).unwrap()
    } catch (err) {
      console.log(err)
    }

    setIsUpdatingAssignment(false)
  }

  let content

  if (
    assigmentQuery.isLoading ||
    submissionQuery.isLoading ||
    (assigmentQuery.isSuccess && !selectedAssignment)
  ) {
    return (
      <LoaderContainer>
        <Loader active />
      </LoaderContainer>
    )
  }

  if (assigmentQuery.isSuccess) {
    const { data: submission } = submissionQuery.isSuccess
      ? submissionQuery.data
      : { data: null }
    const isSynchronous = selectedAssignment?.configuration?.isSynchronous
    const isClosed = isActivityClosed({
      configuration: selectedAssignment?.configuration || {},
    })

    ParentInformer.sendAssigmentTitle(selectedAssignment)

    let AssigmentComponent =
      types[selectedAssignment?.assignmentType?.slug]?.Component

    if (!AssigmentComponent) {
      return <></>
    }

    if (currentUser.isStudent) {
      if (
        submission &&
        selectedAssignment?.assignmentType?.slug !== 'forum' &&
        selectedAssignment?.assignmentType?.slug !== 'q_a' &&
        selectedAssignment?.assignmentType?.slug !== 'file_upload' &&
        selectedAssignment?.assignmentType?.slug !== 'coding_assignment'
      ) {
        content = (
          <CompletedAssigment
            assigment={selectedAssignment}
            submission={submission}
          />
        )
      } else if (
        selectedSubmission &&
        selectedAssignment?.assignmentType?.slug === 'coding_assignment'
      ) {
        content = (
          <Flex full column gap={5} justify="flex-start" align="flex-start">
            <SubmissionDetails submission={selectedSubmission} />
            <SubmittedCodingAssignment />
          </Flex>
        )
      } else {
        // Check if activity is closed
        if (isClosed) {
          content = <LockedAssignment />
        } else {
          content = (
            <AssigmentComponent
              enrollmentGroupId={+enrollmentGroupId || undefined}
              assignment={selectedAssignment}
              assigment={selectedAssignment}
              submission={submission}
              documentUuid={documentId}
              documentId={documentId}
              refetchAssignment={assigmentQuery.refetch}
            />
          )
        }
      }
    } else {
      if (
        (selectedAssignment?.assignmentType?.slug === 'forum' ||
          selectedAssignment?.assignmentType?.slug === 'q_a') &&
        !submission_id
      ) {
        content = (
          <>
            <AssigmentComponent
              assignment={selectedAssignment}
              assigment={selectedAssignment}
              documentUuid={documentId}
              documentId={documentId}
              refetchAssignment={assigmentQuery.refetch}
              enrollmentGroupId={enrollmentGroupId || undefined}
            />
            {isSynchronous && (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <OpenActivityButton
                  color={isClosed ? 'green' : 'red'}
                  onClick={() => toggleIsAssignmentOpen(selectedAssignment)}
                  loading={isUpdatingAssignment}
                >
                  {isClosed ? 'Open assignment' : 'Close assignment'}
                </OpenActivityButton>
              </div>
            )}
          </>
        )
      } else if (
        (selectedAssignment?.assignmentType?.slug === 'forum' ||
          selectedAssignment?.assignmentType?.slug === 'q_a') &&
        submission_id
      ) {
        content = (
          <AssigmentComponent
            assignment={selectedAssignment}
            assigment={selectedAssignment}
            submission={submission}
            documentUuid={documentId}
            documentId={documentId}
            enrollmentGroupId={enrollmentGroupId || undefined}
            refetchAssignment={assigmentQuery.refetch}
          />
        )
      } else if (selectedAssignment?.assignmentType?.slug === 'poll') {
        content = (
          <AssigmentComponent
            assigment={selectedAssignment}
            submission={submission}
            documentUuid={documentId}
            documentId={documentId}
            enrollmentGroupId={enrollmentGroupId || undefined}
          />
        )
      } else if (selectedAssignment?.assignmentType?.slug === 'board') {
        content = (
          <AssigmentComponent
            enrollmentGroupId={+enrollmentGroupId || undefined}
            assignment={selectedAssignment}
            assigment={selectedAssignment}
            documentUuid={documentId}
            documentId={documentId}
          />
        )
      } else if (submission_id) {
        content = (
          <CompletedAssigment
            submission={submission}
            assigment={selectedAssignment}
          />
        )
      } else {
        content = (
          <>
            <ToggleViews
              views={[
                {
                  name: 'Assignment View',
                  view: (
                    <AssigmentComponent
                      assignment={selectedAssignment}
                      assigment={selectedAssignment}
                      documentUuid={documentId}
                      documentId={documentId}
                      enrollmentGroupId={+enrollmentGroupId || undefined}
                    />
                  ),
                },
                {
                  name: 'Submissions',
                  view: (
                    <AssigmentSummary
                      assignment={selectedAssignment}
                      documentId={documentId}
                    />
                  ),
                },
              ]}
              assignment={selectedAssignment}
            />
            {isSynchronous && (
              <div className="flex flex-col items-center justify-center w-full">
                <OpenActivityButton
                  color={isClosed ? 'green' : 'red'}
                  onClick={() => toggleIsAssignmentOpen(selectedAssignment)}
                  loading={isUpdatingAssignment}
                >
                  {isClosed ? 'Open assignment' : 'Close assignment'}
                </OpenActivityButton>
                {!isClosed &&
                  selectedAssignment?.assignmentType?.slug === 'exam' && (
                    <Message
                      size="tiny"
                      warning
                      className="w-11/12 text-center"
                    >
                      <Message.Header>
                        Advertencia Quizz síncrono
                      </Message.Header>
                      <p>
                        Al cerrar el Quizz, se enviarán todas las respuestas de
                        los estudiantes independientemente si terminaron o no.
                      </p>
                    </Message>
                  )}
              </div>
            )}
          </>
        )
      }
    }
  } else if (
    assigmentQuery.isError &&
    (assigmentQuery.error.status === 404 ||
      assigmentQuery.error.status === 400) &&
    !currentUser.isStudent
  ) {
    content = (
      <AssignmentCreator
        documentId={documentId}
        assignmentId={assignmentId}
        type={type}
      />
    )
  } else {
    content = (
      <h1 className="text-2xl font-bold text-center">
        Aún no terminan de crear esta actividad
      </h1>
    )
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }

  const isCreatingActivity =
    assigmentQuery.isError && assigmentQuery.error.status === 400
  const assignmentTypeSlug = isCreatingActivity
    ? undefined
    : selectedAssignment?.assignmentType?.slug ?? ''

  // Activities without title
  const assignmentTypesWithNoTitle = [
    'board',
    'q_a',
    'poll',
    'drag_n_drop',
    'coding_assignment',
    'quiz',
    'exam',
    'typeform',
  ]

  return (
    <>
      {/* Activity's name */}
      {!isFullScreenActive &&
        !assignmentTypesWithNoTitle.includes(assignmentTypeSlug) &&
        !isCreatingActivity && (
          <ActivityNameContainer assigmentType={assignmentTypeSlug}>
            <h4>{selectedAssignment?.title}</h4>
          </ActivityNameContainer>
        )}
      {/* Full screen */}
      <FullScreen assignmentType={assignmentTypeSlug}>{content}</FullScreen>
    </>
  )
}

const ActivityNameContainer = styled.div`
  background-color: rgb(4, 69, 175);
  h4 {
    color: white !important;
  }
  align-self: start;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;

  width: auto;
  z-index: 10;
`
const OpenActivityButton = styled(Button)`
  margin-top: 1rem !important;
  margin-bottom: 2rem;
`
