import React, { useState, useEffect, useContext, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  Button,
  Icon,
  Loader,
  Comment,
  Header,
  Form,
  Label,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSelectedPost,
  setSelectedFile,
  setReloadPosts,
  selectSelectedFile,
  setSelectedSubmission,
  selectViewOnly,
} from '../../../../store/uiSlice'
import {
  useGetForumPostCommentsQuery,
  useGetSubmissionByAssignmentIdAndUserIdQuery,
  useUpdateForumPostMutation,
} from 'app/store/apiHubSlice'
import ReactPlayer from 'react-player'
import ReactAudioPlayer from 'react-audio-player'
import timeAgo from '../../../../utils/timeAgo'
import getFileUrl from '../../../../utils/getFileUrl'
import Avatar from '../../../shared/Avatar'
import FilePreview from './FilePreview'
import placeholder from '../../../shared/placeholder.png'
import { selectCurrentUser } from '../../../../store/authSlice'
import PostDetailButtons from './PostDetailButtons'
import PostEditor from './PostEditor'
import moment from 'moment-timezone'
import { AiOutlineEdit } from 'react-icons/ai'
import { Tooltip } from 'react-tooltip'
import { SocketContext } from 'app/context/socket'
import { convertFiles } from 'app/utils/convertFiles'
import DeletedPost from './DeletedPost'
import Flex from 'app/components/containers/Flex'

function ImageThumbnail({ file }) {
  let dispatch = useDispatch()
  const [imageUrl, setImageUrl] = React.useState(undefined)
  React.useEffect(() => {
    async function getImageUrl() {
      if (imageUrl === undefined) {
        const url = await getFileUrl(
          file.file_url,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
        )
        setImageUrl(url)
      }
    }
    getImageUrl()
  })
  return (
    <div key={file.file_name}>
      {imageUrl === undefined ? (
        <Loader inverted />
      ) : (
        <Thumb
          onClick={() => dispatch(setSelectedFile(file))}
          src={imageUrl}
          alt={file.file_name}
          height="100"
        />
      )}
    </div>
  )
}
function HybridgeVideoPlayer({ file, didLoadVideo }) {
  const [videoUrl, setVideoUrl] = React.useState(undefined)

  React.useEffect(() => {
    async function getVideoUrl() {
      if (videoUrl === undefined) {
        const url = await getFileUrl(
          file.file_url,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
        )
        setVideoUrl(url)
      }
    }
    getVideoUrl()
  })
  return (
    <div
      key={file.file_name}
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {videoUrl === undefined ? (
        <Loader inverted />
      ) : (
        <ReactPlayer
          url={videoUrl}
          width={'auto'}
          controls
          onReady={() => didLoadVideo()}
        />
      )}
    </div>
  )
}
function HybridgeAudioPlayer({ file }) {
  const [audioUrl, setAudioUrl] = React.useState(undefined)
  React.useEffect(() => {
    async function getAudioUrl() {
      if (audioUrl === undefined) {
        const url = await getFileUrl(
          file.file_url,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
        )
        setAudioUrl(url)
      }
    }
    getAudioUrl()
  })
  return (
    <div key={file.file_name}>
      {audioUrl === undefined ? (
        <Loader inverted />
      ) : (
        <ReactAudioPlayer
          style={{
            maxWidth: '100%',
          }}
          src={audioUrl}
          controls
        />
      )}
    </div>
  )
}
function FileThumb({ file }) {
  let dispatch = useDispatch()

  const handleFilePreview = () => {
    dispatch(setSelectedFile(file))
  }

  const handleFileDownload = async () => {
    const url = await getFileUrl(
      file.file_url,
      process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
    )
    window.open(url, '_blank')
  }

  const onFileClick = async () => {
    if (file.file_type.includes('pdf') || file.file_type.includes('video')) {
      handleFilePreview()
    } else {
      await handleFileDownload()
    }
  }

  return (
    <FileLink key={file.file_name} onClick={onFileClick}>
      <Icon
        name={
          file.file_type.includes('image')
            ? 'picture'
            : file.file_type.includes('video')
            ? 'film'
            : file.file_type.includes('pdf')
            ? 'file pdf'
            : 'file text'
        }
      />
      {file.file_name}
    </FileLink>
  )
}

function FileThumbnails({ files, answerTypes = [], format, didLoadVideo }) {
  files = convertFiles(files)
  let images = files
    .filter(
      (f) =>
        f.file_type &&
        f.file_type.includes('image') &&
        !answerTypes.includes('video')
    )
    .map((file, index) => {
      return <ImageThumbnail key={index} file={file} />
    })
  let videos = files
    .filter(
      (f) => f.file_type && f.file_type.includes('video') && format === 'small'
    )
    .map((file, index) => {
      return (
        <HybridgeVideoPlayer
          key={index}
          file={file}
          didLoadVideo={didLoadVideo}
        />
      )
    })
  let audios = files
    .filter((f) => f.file_type && f.file_type.includes('audio'))
    .map((file, index) => {
      return <HybridgeAudioPlayer key={index} file={file} />
    })
  let others = files
    .filter(
      (f) =>
        f.file_type &&
        !f.file_type.includes('image') &&
        (!f.file_type.includes('video') || format !== 'small') &&
        !f.file_type.includes('audio')
    )
    .map((file, index) => {
      return <FileThumb key={index} file={file} />
    })
  let no_file_url = files
    .filter((f) => f.file_url == null)
    .map((_, index) => {
      return (
        <Label key={index} style={{ marginBottom: '0.8rem' }}>
          <Icon name="warning sign" /> Sin archivo multimedia
        </Label>
      )
    })
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
          overflow: 'auto',
        }}
      >
        {images}
        {videos}
        {audios}
      </div>

      {others}
      {no_file_url}
    </>
  )
}

export default function PostDetail({ selectedPost, user, assignment }) {
  const [comment, setComment] = useState('')
  let dispatch = useDispatch()
  // const selectedPost = useSelector(selectSelectedPost)
  const commentsQuery = useGetForumPostCommentsQuery({
    postId: selectedPost ? selectedPost.id : '',
  })
  const [updatePost] = useUpdateForumPostMutation()

  const [likingComment, setLikingComment] = useState(null)
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [comments, setComments] = useState([])
  const [commentAdded, setCommentAdded] = useState(false)
  const selectedFile = useSelector(selectSelectedFile)
  // eslint-disable-next-line no-unused-vars
  const [didLoadVideo, setDidLoadVideo] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const theme = useTheme()
  let content = <></>

  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState('')
  const [joinedSocket, setJoinedSocket] = useState(false)
  const socket = useContext(SocketContext)
  const viewOnly = useSelector(selectViewOnly) && currentUser.isStudent

  const isSameUser = currentUser.id === selectedPost?.user?.id

  const {
    data: submission,
    isError,
    isFetching,
  } = useGetSubmissionByAssignmentIdAndUserIdQuery(
    {
      userId: selectedPost.userId,
      assignmentId: selectedPost.assignmentId,
    },
    {
      skip: currentUser.isStudent,
    }
  )

  const [open, setOpen] = useState(false)
  const handleOpenModal = () => {
    dispatch(
      setSelectedSubmission({
        ...submission.data,
        user: { ...selectedPost?.user },
      })
    )
    setOpen(true)
  }
  const handleCloseModal = () => {
    dispatch(setSelectedSubmission(null))
    setOpen(false)
  }

  // Initialize poll's answers list;
  useEffect(() => {
    if (commentsQuery.data === undefined) return
    const { data: tempComments } = commentsQuery.data
    setComments(tempComments)
  }, [commentsQuery.data, selectedPost])

  useEffect(() => {
    async function refetchComments() {
      if (!commentsQuery.data) return
      const commentsQueryRefetch = await commentsQuery.refetch()
      setComments(commentsQueryRefetch?.data?.data || [])
    }
    refetchComments()
  }, [selectedPost, commentAdded])

  const onPostComment = async (e) => {
    const commentText = e.target[0].value

    if (commentText.length > 0) {
      try {
        setIsLoadingComments(true)

        await updatePost({
          postId: selectedPost.id,
          userId: user.id,
          comment: commentText,
        }).unwrap()

        setComment('')
        setCommentAdded(true)
        dispatch(setReloadPosts(true))

        setIsLoadingComments(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const toggleLike = async (comment) => {
    setLikingComment(comment.id)
    try {
      await updatePost({
        postId: selectedPost.id,
        userId: user.id,
        commentId: comment.id ? comment.id : '',
      }).unwrap()
    } catch (err) {
      console.log(err)
      await commentsQuery.refetch()
    }
    setLikingComment(null)
  }

  const didLikeComment = (comment, user) => {
    const engagement = comment.engagement ? comment.engagement : {}
    const likes = engagement.likes ? engagement.likes : []
    return likes.findIndex((like) => like.userId === user.id) !== -1
  }

  const onGoBack = () => {
    dispatch(setSelectedPost(null))
  }

  const onEditComment = () => {
    updatePost({
      postId: selectedPost.id,
      engagement: {
        ...(selectedPost.engagement || {}),
        comments: comments.map((c) => {
          if (c.id === editedComment.id) {
            return {
              id: editedComment.id,
              userId: editedComment.userId,
              comment: editedComment.comment,
              commentedAt: editedComment.commentedAt,
              editedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            }
          }
          return c
        }),
      },
    })

    setEditedComment('')
  }

  if (selectedFile !== null && selectedPost !== null) {
    return <FilePreview />
  } else if (selectedPost !== null) {
    const editHistory = selectedPost.editHistory
    content = (
      <ContentContainer>
        {/* Back button */}
        <Button
          style={{ marginBottom: '1rem' }}
          onClick={onGoBack}
          size="tiny"
          basic
          inverted={theme.isDark}
        >
          <Icon name="chevron left" />
          Ver todos
        </Button>
        {/* Avatar */}
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <AvatarWrapper style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={selectedPost.user.avatarUrl}
                size={30}
                showBorder={false}
              />{' '}
              <CustomHeader as="h3" style={{ marginTop: 0 }}>
                {`${selectedPost.user.givenName} ${selectedPost.user.familyName1}`}
              </CustomHeader>
            </AvatarWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <TimeMeta>
                {timeAgo.format(Date.parse(selectedPost.createdAt))}
              </TimeMeta>
              {editHistory && editHistory.length > 1 && (
                <>
                  <AiOutlineEdit
                    data-tooltip-id="ai-outline-edit-tooltip"
                    data-tooltip-content={moment(
                      editHistory[editHistory.length - 1].editedAt
                    ).format('DD/MM/YYYY HH:mm')}
                  />
                  <Tooltip id="ai-outline-edit-tooltip" />
                </>
              )}
            </div>
          </div>
        </div>
        {/* Files */}
        <div
          style={{
            paddingTop: '14px',
            paddingBottom: '14px',
          }}
        >
          {selectedPost.files && selectedPost.files.length > 0 && (
            <FileThumbnails
              files={selectedPost.files}
              answerTypes={assignment.configuration.answerTypes || []}
              format={assignment.configuration.format}
              didLoadVideo={() => setDidLoadVideo(true)}
            />
          )}
          {isEditing && isSameUser && !viewOnly ? (
            <PostEditor
              key={selectedPost.id}
              post={selectedPost}
              setIsEditing={setIsEditing}
              uploadUrl={`assignments/${assignment.id}/${currentUser.id}`}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: selectedPost.content,
              }}
            />
          )}
        </div>
        <PostDetailButtons
          key={selectedPost.id}
          post={selectedPost}
          setIsEditing={setIsEditing}
        />

        {/* Comments */}
        <div>
          <Comments>
            <CustomHeader as="h3" dividing>
              Comentarios
            </CustomHeader>
            {editedComment ? (
              <>
                <h3>Editar Comentario</h3>
                <Form reply onSubmit={onEditComment}>
                  <Form.TextArea
                    style={{
                      maxHeight: 70,
                      resize: 'none',
                      backgroundColor: theme.sidebarBackground,
                      color: theme.text,
                    }}
                    onChange={(e) =>
                      setEditedComment({
                        ...editedComment,
                        comment: e.target.value,
                      })
                    }
                    value={editedComment.comment}
                    placeholder={'Escribe un comentario'}
                  />
                  <Button
                    primary
                    loading={isLoadingComments}
                    floated="right"
                    size="tiny"
                  >
                    Guardar
                  </Button>
                </Form>
              </>
            ) : (
              <>
                {commentsQuery.isSuccess &&
                  comments.map((comment) => (
                    <Comment key={comment.id}>
                      <Comment.Avatar src={comment.avatarUrl || placeholder} />
                      {comment.editedAt && (
                        <div
                          style={{
                            float: 'right',
                          }}
                        >
                          <AiOutlineEdit
                            data-tooltip-id="ai-outline-comments-tooltip"
                            data-tooltip-content={moment(
                              Date.parse(comment.editedAt)
                            ).format('DD/MM/YYYY HH:mm')}
                          />
                          <Tooltip id="ai-outline-comments-tooltip" />
                        </div>
                      )}
                      <Comment.Content>
                        <Comment.Author
                          style={{
                            color: theme.textSecondary,
                          }}
                        >
                          {comment.name}
                        </Comment.Author>

                        <Comment.Text
                          style={{
                            color: theme.text,
                          }}
                        >
                          {comment.comment}
                        </Comment.Text>

                        {/* <Comment.Actions>
                          <Comment.Action
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Button
                              as="div"
                              labelPosition="right"
                              onClick={() => {
                                setEditedComment(comment)
                              }}
                              style={{
                                display:
                                  comment.userId === user.id ? 'block' : 'none',
                              }}
                            >
                              <p>Editar</p>
                            </Button>
                          </Comment.Action>
                          <Comment.Action
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <Button
                              disabled={
                                comment.userId === user.id ||
                                comment.id === likingComment
                              }
                              as="div"
                              labelPosition="right"
                              onClick={() => toggleLike(comment)}
                              style={{
                                color:
                                  comment.userId === user.id ||
                                  comment.id === likingComment
                                    ? theme.textTertiary
                                    : theme.text,
                              }}
                            >
                              <Icon
                                name={
                                  didLikeComment(comment, user)
                                    ? 'heart'
                                    : 'heart outline'
                                }
                                color={
                                  didLikeComment(comment, user) ? 'red' : null
                                }
                              />
                              <Counter>
                                {comment.engagement && comment.engagement.likes
                                  ? comment.engagement.likes.length
                                  : '0'}
                              </Counter>
                            </Button>
                          </Comment.Action>
                        </Comment.Actions> */}
                      </Comment.Content>
                    </Comment>
                  ))}
                {commentsQuery.isLoading && (
                  <div>
                    <Loader active inline="centered" />
                  </div>
                )}
                <Form reply onSubmit={onPostComment}>
                  <Form.TextArea
                    style={{
                      maxHeight: 70,
                      resize: 'none',
                      backgroundColor: theme.sidebarBackground,
                      color: theme.text,
                    }}
                    onChange={(e) => {
                      if (commentAdded) setCommentAdded(false)
                      setComment(e.target.value)
                    }}
                    value={comment}
                    placeholder={'Escribe un comentario'}
                    disabled={viewOnly}
                  />
                  {!currentUser.isStudent && (
                    <Button
                      color="red"
                      inverted
                      onClick={handleOpenModal}
                      size="tiny"
                      floated="left"
                      type="button"
                      disabled={isFetching || isError}
                      loading={isFetching}
                    >
                      Eliminar
                    </Button>
                  )}
                  <Button
                    primary
                    loading={isLoadingComments}
                    floated="right"
                    size="tiny"
                    type="submit"
                    disabled={viewOnly}
                  >
                    <Icon name="comment outline"></Icon>
                    Comentar
                  </Button>
                </Form>
              </>
            )}
          </Comments>
        </div>
        <DeletedPost open={open} handleClose={handleCloseModal} />
      </ContentContainer>
    )
  } else {
    content = <div></div>
  }

  return content
}
const TimeMeta = styled.span`
  float: right;
  font-size: 12px;
  font-weight: normal;
  color: ${(props) => props.theme.textSecondary};
`
const Thumb = styled.img`
  border-radius: 5px;
  border: 1px solid transparent;
  :hover {
    cursor: pointer;
    border: 1px solid #ccc;
  }
`
export const FileLink = styled.div`
  :hover {
    cursor: pointer;
    color: #00b5ad;
    text-decoration: underline;

    ${({ disabled }) =>
      disabled &&
      `
    cursor: default;
    text-decoration: none;
  `}
  }
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const Comments = styled(Comment.Group)`
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
`
const Counter = styled.span`
  font-size: 0.8rem;
`
const ContentContainer = styled.div`
  padding: 3rem;
`
const CustomHeader = styled(Header)`
  color: ${(props) => props.theme.text};
`
