import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/authSlice'
import { Input, Button } from 'semantic-ui-react'
import styled from 'styled-components'
import dayjs from '../utils/dayjs'
import { useCreateElementMutation } from '../store/apiHubSlice'
import ParentInformer from '../utils/ParentInformer'

function CountdownBuilder({ elementId }) {
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  const [createElement, { isLoading: isCreatingElement }] =
    useCreateElementMutation()

  // Handle time update
  const onTimeUpdated = (unit, value) => {
    let substringValue = value
    if (/[^\d]/.test(value)) {
      return
    }

    // Limit value
    if (value.length > 2) {
      substringValue = value.substring(0, 2)
    }

    if (unit === 'hours') {
      setHours(substringValue)
    } else if (unit === 'minutes') {
      setMinutes(substringValue)
    } else if (unit === 'seconds') {
      setSeconds(substringValue)
    }
  }

  // Handle create timer
  const createCountdown = async () => {
    // Get hours
    const hoursAsInt = parseInt(hours.length > 0 ? hours : 0)
    // Get minutes
    const minutesAsInt = parseInt(minutes.length > 0 ? minutes : 0)
    // Get seconds
    const secondsAsInt = parseInt(seconds.length > 0 ? seconds : 0)

    // Calculate deadline
    const deadline = dayjs()
      .add(hoursAsInt, 'hour')
      .add(minutesAsInt, 'minute')
      .add(secondsAsInt, 'second')

    try {
      await createElement({
        id: elementId,
        type: 'countdown',
        config: {
          deadline,
        },
      }).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <CountdownCreatorContainer>
      {/* Hours */}
      <CountdownInputContainer>
        <span style={{ marginBottom: '0.5rem' }}>Horas</span>
        <CountdownInput
          placeholder="00"
          value={hours}
          onChange={({ target }) => onTimeUpdated('hours', target.value)}
        />
      </CountdownInputContainer>
      {/* Minutes */}
      <CountdownInputContainer>
        <span style={{ marginBottom: '0.5rem' }}>Minutos</span>
        <CountdownInput
          placeholder="00"
          value={minutes}
          onChange={({ target }) => onTimeUpdated('minutes', target.value)}
        />
      </CountdownInputContainer>
      {/* Seconds */}
      <CountdownInputContainer>
        <span style={{ marginBottom: '0.5rem' }}>Segundos</span>
        <CountdownInput
          placeholder="00"
          value={seconds}
          onChange={({ target }) => onTimeUpdated('seconds', target.value)}
        />
      </CountdownInputContainer>
      {/* Create button */}
      <CountdownInputContainer>
        <Button
          primary
          onClick={createCountdown}
          disabled={
            hours.length === 0 && minutes.length === 0 && seconds.length === 0
          }
          loading={isCreatingElement}
        >
          Crear
        </Button>
      </CountdownInputContainer>
    </CountdownCreatorContainer>
  )
}

export default function Countdown({ elementId, element, elementNotFound }) {
  const currentUser = useSelector(selectCurrentUser)
  const [timer, setTimer] = useState(null)

  // Get time between deadline and now
  const getTime = (deadline) => {
    const time = Date.parse(deadline) - Date.now()

    // Update timer
    setTimer({
      hours: Math.floor((time / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((time / 1000 / 60) % 60),
      seconds: Math.floor((time / 1000) % 60),
    })
  }

  // Get time until deadline every second
  useEffect(() => {
    if (element) {
      const interval = setInterval(() => getTime(element.config.deadline), 1000)
      return () => clearInterval(interval)
    }
  }, [element])

  // Handle timer updates
  useEffect(() => {
    if (!timer) {
      return
    }

    if (timer.hours === 0 && timer.minutes === 0 && timer.seconds === 0) {
      ParentInformer.sendBlinkInstruction(elementId)
    }
  }, [timer])

  // Check if timer has been created
  if (elementNotFound && !currentUser.isStudent) {
    return <CountdownBuilder elementId={elementId} />
  } else if (elementNotFound) {
    return <h1>Aún no terminan de crear esta actividad</h1>
  }

  return timer ? (
    <Timer>
      {/* Hours */}
      <Box>
        <TimerNumber id="day">
          {timer.hours < 1 ? '0' : timer.hours}
        </TimerNumber>
        h
      </Box>
      {/* Minutes */}
      <Box>
        <TimerNumber id="minutes">
          {timer.minutes < 1 ? '0' : timer.minutes}
        </TimerNumber>
        m
      </Box>
      {/* Seconds */}
      <Box hideBorder>
        <TimerNumber id="seconds">
          {timer.seconds < 1 ? '0' : timer.seconds}
        </TimerNumber>
        s
      </Box>
    </Timer>
  ) : (
    <></>
  )
}

const CountdownCreatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`

const CountdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const CountdownInput = styled(Input)`
  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    border: none !important;
    color: ${(props) =>
      props.theme.isDark ? props.theme.textTertiary : '#8d92ab'} !important;
  }
  margin-right: 1rem;
`

const Timer = styled.div`
  display: inline-block;
  padding: 10px;
  text-align: center;
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`

const Box = styled.div`
  font-size: 1.5rem;
  padding: 10px;
  flex: 1;
`

const TimerNumber = styled.p`
  font-weight: 500;
  font-size: 2.5rem;
  display: inline;
`
