import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, Label } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectSelectedSubmission,
  setSelectedSubmission,
} from '../store/uiSlice'
import Flex from './containers/Flex'
export default function ToggleViews({ views, assignment, defaultView = 0 }) {
  const [currentIndex, setCurrentIndex] = React.useState(defaultView)
  const theme = useTheme()
  const dispatch = useDispatch()
  const submission = useSelector(selectSelectedSubmission)
  return (
    <>
      <Floated
        align="center"
        justify={submission ? 'space-between' : 'flex-end'}
        full
      >
        {submission ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              icon="home"
              size="tiny"
              onClick={() => dispatch(setSelectedSubmission(null))}
            ></Button>
            <Label>{submission.user?.name}</Label>
          </div>
        ) : (
          <></>
        )}
        <Button.Group>
          {views.map((view, index) => {
            return (
              <Button
                positive={views[currentIndex].name === view.name}
                key={view.name}
                onClick={() => {
                  setCurrentIndex(index)
                  dispatch(setSelectedSubmission(null))
                }}
                inverted={theme.isDark}
              >
                {view.name}
              </Button>
            )
          })}
        </Button.Group>
      </Floated>
      <ContentContainer
        isTypeform={assignment.assignmentType.slug === 'typeform'}
      >
        {views[currentIndex].view}
      </ContentContainer>
    </>
  )
}

const Floated = styled(Flex)`
  top: 10px;
  right: 10px;
`

const ContentContainer = styled.div`
  padding-top: ${(props) => (props.isTypeform ? '0px' : '0.2rem')};
  width: 100%;
`
