import React, { useState } from 'react'
import styled from 'styled-components'
import {
  setAssigment,
  selectAssigment,
  setReady,
  setErrorMessage,
} from '../../../../store/assigmentCreationSlice'
import { useGenerateAIQuizMutation } from 'app/store/apiHubSlice'
import { Button, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import Question from './Question'
import { Input } from 'semantic-ui-react'
import Flex from 'app/components/containers/Flex'
import { SiDependabot } from 'react-icons/si'
import { selectCurrentDocument } from 'app/store/uiSlice'

export default function ExamConfig({ exam }) {
  let dispatch = useDispatch()
  const assigment = useSelector(selectAssigment)
  const [questions, setQuestions] = useState(exam ? exam : [])
  const [error] = useState(null)
  const currentDocument = useSelector(selectCurrentDocument)
  const [generateAIQuiz, { isLoading: isGeneratingAIQuiz }] = useGenerateAIQuizMutation()
  const [questionCount, setQuestionCount] = useState(5)

  const handleQuizChange = (quizData) => {
    let config = { ...assigment.configuration }
    config.quiz = quizData

    // Check if questions have a right answer selected
    let allRightChoicesSelected = true

    for (let i = 0; i < config.quiz.length; i++) {
      const question = config.quiz[i]

      if (Object.hasOwn(question, 'options') && question.options.length > 0) {
        const correctAnswerIndex = question.options.findIndex(
          (op) => op.isCorrect
        )
        if (correctAnswerIndex === -1) {
          allRightChoicesSelected = false
          break
        }
      }
    }

    if (!allRightChoicesSelected) {
      dispatch(setReady(false))
      dispatch(
        setErrorMessage(
          'Selecciona una opción correcta para todas las preguntas'
        )
      )
    } else if (config.quiz.length === 0) {
      dispatch(setReady(false))
      dispatch(setErrorMessage('Agrega al menos una pregunta'))
    } else {
      dispatch(setReady(true))
      dispatch(setErrorMessage(null))
    }

    dispatch(
      setAssigment({
        ...assigment,
        description: assigment.description
          ? assigment.description
          : assigment.title,
        configuration: config,
      })
    )
  }

  const onAddQuestion = () => {
    const questionsCopy = [...questions]
    questionsCopy.push({
      id: uuidv4(),
      element: 'RadioButtons',
      required: true,
      questions: {
        es: '',
      },
      options: [{ value: uuidv4(), text: { es: '' } }],
    })
    setQuestions(questionsCopy)
  }

  const onDuplicateQuestion = (id) => {
    let questionsCopy = [...questions]

    // Find question
    const targetIndex = questionsCopy.findIndex((q) => q.id === id)
    // Duplicate question at index
    questionsCopy.splice(targetIndex, 0, {
      ...questionsCopy[targetIndex],
      id: uuidv4(),
    })

    // Duplicate options
    if (Object.hasOwn(questionsCopy[targetIndex], 'options')) {
      questionsCopy[targetIndex + 1] = {
        ...questionsCopy[targetIndex + 1],
        options: questionsCopy[targetIndex].options.map((op) => ({
          value: uuidv4(),
          ...op,
        })),
      }
    }

    setQuestions(questionsCopy)
  }

  const onDeleteQuestion = (id) => {
    const questionsCopy = [...questions]
    const targetIndex = questionsCopy.findIndex((q) => q.id === id)
    questionsCopy.splice(targetIndex, 1)
    setQuestions(questionsCopy)
    handleQuizChange(questionsCopy)
  }

  const onUpdateQuestion = (updatedQuestion) => {
    const questionsCopy = [...questions]
    const targetIndex = questionsCopy.findIndex(
      (q) => q.id === updatedQuestion.id
    )
    questionsCopy[targetIndex] = updatedQuestion
    setQuestions(questionsCopy)
    handleQuizChange(questionsCopy)
  }

  const addQuizQuestions = (quiz) => {
    const questionsCopy = [...questions]
    quiz.forEach((q) => {
      questionsCopy.push({
        id: uuidv4(),
        element: 'RadioButtons',
        required: true,
        questions: {
          es: `<p>${q.question}</p>`,
        },
        options: q.options.map((op) => ({
          value: uuidv4(),
          text: { es: `<p>${op.value}</p>` },
          isCorrect: op.isCorrect ? true : false,
        })),
      })
    })
    setQuestions(questionsCopy)
    handleQuizChange(questionsCopy)
  }

  const createQuestionsByAI = async () => {
    try {
      const response = await generateAIQuiz({
        documentId: currentDocument,
        questionCount,
      }).unwrap()
      const { data: quiz } = response
      addQuizQuestions(quiz)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <AiAssistantContainer>
        <Flex full column align="flex-start" justify="flex-start" gap={5}>
          <Flex full align="center" justify="flex-start" gap={5}>
            <Header>AI Assitant</Header>
            <SiDependabot size="23" />
          </Flex>
          <Flex full column align="flex-start">
            <span>Questions (max 5)</span>
            <Input
              value={questionCount}
              onChange={(e) =>
                +e.target.value >= 5
                  ? setQuestionCount(5)
                  : setQuestionCount(+e.target.value)
              }
            />
          </Flex>
          <Flex full justify="flex-start" align="center">
            <Button
              primary
              icon
              loading={isGeneratingAIQuiz}
              disabled={isGeneratingAIQuiz || !questionCount}
              onClick={() => createQuestionsByAI()}
            >
              <Icon name="bolt" />
              Generate
            </Button>
            {isGeneratingAIQuiz && (
              <>
                <SiDependabot size="23" />
                <Loading>Working my magic...</Loading>
              </>
            )}
          </Flex>
        </Flex>
      </AiAssistantContainer>
      <FormContainer>
        {/* Preguntas */}
        {questions.map((question) => (
          <Question
            key={question.id}
            id={question.id}
            question={question}
            onDelete={onDeleteQuestion}
            onUpdate={onUpdateQuestion}
            onDuplicate={onDuplicateQuestion}
          />
        ))}

        {/* Botón para agregar pregunta */}
        <Button style={{ width: '100%' }} secondary onClick={onAddQuestion}>
          Agregar pregunta
          <Icon style={{ marginLeft: '0.5rem' }} name="add" />
        </Button>
        <div>{error && <small style={{ color: 'red' }}>{error}</small>}</div>
      </FormContainer>
    </>
  )
}
const Header = styled.p`
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  font-weight: 600;
`
const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`
const AiAssistantContainer = styled.div`
  padding: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px dashed ${(props) => props.theme.text};
`
const Loading = styled.div`
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
`
