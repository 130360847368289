import { createContext, useCallback, useMemo, useState } from 'react'

import getErrorMessage from '../helpers/getErrorMessage'
import { CONSOLE_RESULTS_TYPES } from '../constants'
import { getWidth } from '../helpers/CrossBrowserWindowMeasurements'

export const CodeRunnerStore = createContext({
  consoleResult: {
    type: CONSOLE_RESULTS_TYPES.STALE,
    content: null,
  },
  handleResultChange: ({ error, executionResult }) => {
    return { error, executionResult }
  },
  resetResult: () => {},
  instructionsOpen: true,
  toggleInstructions: () => {},
  assignment: {
    id: '',
    title: '',
    description: '',
    configuration: {
      isChallenge: false,
      image: '',
      requiresFeedback: false,
      defaultOpen: false,
      autoFeedback: false,
      isSynchronous: false,
      isOpen: false,
      quiz: [],
    },
    collectionId: '',
    active: false,
    createdAt: '',
    updatedAt: '',
    assignmentTypeId: '',
    assignmentType: {
      id: '',
      name: '',
      slug: '',
      displayText: '',
      icon: '',
      color: '',
      createdAt: '',
      updatedAt: '',
    },
    collection: [],
  },
  documentId: '',
})

const CodeRunnerProvider = ({ children, assignment, documentId }) => {
  const [result, setResult] = useState({
    type: CONSOLE_RESULTS_TYPES.STALE,
    content: null,
  })

  const [instructionsOpen, setInstructionsOpen] = useState(() => {
    const windowWidth = getWidth()
    return windowWidth >= 768
  })

  const toggleInstructions = useCallback(() => {
    setInstructionsOpen((prev) => !prev)
  }, [])

  const resetResult = useCallback(() => {
    setResult({
      type: CONSOLE_RESULTS_TYPES.STALE,
      content: null,
    })
  }, [])

  const handleResultChange = useCallback(
    ({ error, executionResult }) => {
      if (error && !executionResult) {
        return setResult({
          type: CONSOLE_RESULTS_TYPES.ERROR,
          content: error?.message ? getErrorMessage(error?.message) : null,
        })
      }

      if (!error && executionResult) {
        setResult({
          type: CONSOLE_RESULTS_TYPES.RESULT,
          content: executionResult?.length > 0 ? executionResult : null,
        })
      }

      return result
    },
    [CONSOLE_RESULTS_TYPES]
  )

  const value = useMemo(() => {
    return {
      consoleResult: result,
      handleResultChange,
      toggleInstructions,
      instructionsOpen,
      resetResult,
      assignment,
      documentId,
    }
  }, [
    handleResultChange,
    result.content,
    result.type,
    resetResult,
    instructionsOpen,
    toggleInstructions,
    assignment,
    documentId,
  ])

  return (
    <CodeRunnerStore.Provider value={value}>
      {children}
    </CodeRunnerStore.Provider>
  )
}

export default CodeRunnerProvider
