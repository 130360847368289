import React from 'react'
import {
  setAssigment,
  selectAssigment,
  setReady,
} from '../../../store/assigmentCreationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useState } from 'react'
import Flex from '../../../components/containers/Flex'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import randomImage from '../../../utils/randomImage'
import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'
import { convertFromHTML } from 'draft-convert'
import editorHtmlOpts from 'app/utils/editorHtmlOpts'

export default function ForumConfig({ format }) {
  let dispatch = useDispatch()
  const assigment = useSelector(selectAssigment)

  const [editorState, setEditorState] = useState(() => {
    if (assigment && assigment.description !== '') {
      return EditorState.createWithContent(
        convertFromHTML(editorHtmlOpts)(assigment.description || '')
      )
    }

    return EditorState.createEmpty()
  })

  React.useEffect(() => {
    if (!Object.keys(assigment.configuration).includes('min')) {
      dispatch(
        setAssigment({
          ...assigment,
          configuration: {
            min: 0,
            image: randomImage(),
            format,
            isChallenge: assigment.configuration.isChallenge,
            isSynchronous: assigment.configuration.isSynchronous,
          },
        })
      )
      dispatch(setReady(true))
    }

    if (
      assigment.configuration.format === 'small' &&
      !Object.keys(assigment.configuration).includes('answerTypes')
    ) {
      dispatch(
        setAssigment({
          ...assigment,
          configuration: {
            ...assigment.configuration,
            answerTypes: ['text'],
          },
        })
      )
    }
  }, [assigment.configuration])

  const handleEditorChanged = (state) => {
    setEditorState(state)
    dispatch(
      setAssigment({
        ...assigment,
        description: draftToHtml(convertToRaw(state.getCurrentContent())),
      })
    )
  }

  const handleCheckboxChange = (e, data) => {
    const sopportedAnswersTypes = assigment.configuration.answerTypes
      ? [...assigment.configuration.answerTypes]
      : []
    const typeIndex = sopportedAnswersTypes.findIndex(
      (type) => type === data.name
    )

    // Add or remove type
    if (typeIndex === -1) {
      sopportedAnswersTypes.push(data.name)
    } else {
      sopportedAnswersTypes.splice(typeIndex, 1)
    }

    let config = {
      ...assigment.configuration,
      answerTypes: sopportedAnswersTypes,
    }

    dispatch(setAssigment({ ...assigment, configuration: config }))
  }

  let content

  if (format === 'small') {
    content = (
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Flex full align="flex-start" column justify="flex-start">
          <span style={{ marginBottom: '1rem' }}>Tipo(s) de respuesta</span>
          <div>
            <CustomCheckbox
              label="Texto"
              name="text"
              checked={
                assigment.configuration.answerTypes &&
                assigment.configuration.answerTypes.includes('text')
              }
              style={{ marginRight: '1rem', color: 'red' }}
              onClick={handleCheckboxChange}
            />
            <CustomCheckbox
              label="Video"
              name="video"
              checked={
                assigment.configuration.answerTypes &&
                assigment.configuration.answerTypes.includes('video')
              }
              style={{ marginRight: '1rem' }}
              onClick={handleCheckboxChange}
            />
            <CustomCheckbox
              label="Audio"
              name="audio"
              checked={
                assigment.configuration.answerTypes &&
                assigment.configuration.answerTypes.includes('audio')
              }
              style={{ marginRight: '1rem' }}
              onClick={handleCheckboxChange}
            />
          </div>
        </Flex>
      </div>
    )
  } else {
    content = (
      <div
        style={{ width: '100%', overflow: 'auto', backgroundColor: 'white' }}
      >
        <Flex full align="flex-start" column justify="flex-start">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChanged}
            toolbar={{
              options: ['inline', 'blockType', 'list'],
              blockType: {
                inDropdown: false,
              },
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
            }}
            placeholder="Escribe aquí..."
            wrapperStyle={{
              width: '100%',
            }}
          />
        </Flex>
      </div>
    )
  }

  return content
}

const CustomCheckbox = styled(Checkbox)`
  label {
    color: ${(props) => props.theme.textSecondary} !important;
  }
`
