import { useState } from 'react'
import Editor from './Editor'
import { Spinner } from '../../../LoadingSpinner'
import { initialCode } from '../constants'
import useRunCode from '../hooks/useRunCode'
import { useCodeRunnerStore } from '../hooks/useCodeRunnerStore'
import Terminal from './Terminal'
import useCodeSubmission from '../hooks/useCodeSubmission'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import useCurrentUser from '../hooks/useCurrentUser'
import { selectViewOnly } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'

const Workspace = ({ submission }) => {
  const currentUser = useCurrentUser()
  const [sendModalOpen, setSendModalOpen] = useState(false)
  const viewOnly = useSelector(selectViewOnly)

  const toggleSendModalOpen = () => setSendModalOpen(!sendModalOpen)

  const { handleResultChange, resetResult } = useCodeRunnerStore()
  const { runSubmitMutation, isSubmitting, isError, reset } =
    useCodeSubmission()

  const [code, setCode] = useState(
    submission ? submission.details.code : initialCode
  )

  const handleEditorChange = (value) => setCode(value)

  const onFinish = ({ error, executionResult }) => {
    handleResultChange({ error, executionResult })
  }

  const { isLoading, execute } = useRunCode(onFinish)

  const onSendActivity = () => {
    runSubmitMutation(code)
  }

  const handleRunCode = async () => {
    resetResult()
    await execute(code)
  }

  const onAcceptErrorMessage = () => {
    toggleSendModalOpen()
    reset()
  }

  return (
    <>
      <Modal
        basic
        onClose={toggleSendModalOpen}
        onOpen={toggleSendModalOpen}
        open={sendModalOpen}
        size="small"
      >
        <Header icon>
          <Icon name="warning sign" color="yellow" />
          Enviar actividad
        </Header>
        <Modal.Content>
          <p className="text-center text-lg text-white">
            Estas a punto de enviar la actividad, ¿deseas continuar?
          </p>
        </Modal.Content>
        <Modal.Actions className="flex justify-center">
          <Button basic color="red" inverted onClick={toggleSendModalOpen}>
            <Icon name="remove" /> No
          </Button>
          <Button
            loading={isSubmitting}
            color="green"
            inverted
            onClick={onSendActivity}
          >
            <Icon name="checkmark" /> Si
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal basic open={isError} size="small">
        <Header icon>
          <Icon name="warning" color="red" />
          Hubo un error
        </Header>
        <Modal.Content>
          <p className="text-center text-lg text-white">
            No pudimos entregar la actividad, intentalo de nuevo mas tarde.
          </p>
        </Modal.Content>
        <Modal.Actions className="flex justify-center">
          <Button basic inverted onClick={onAcceptErrorMessage}>
            Aceptar
          </Button>
        </Modal.Actions>
      </Modal>

      <form className="w-full h-full relative">
        {isLoading && (
          <div className="absolute flex items-center justify-center bg-slate-600 h-full w-full z-30 animate-pulse bg-opacity-40">
            <Spinner />
          </div>
        )}
        <div className="h-full flex flex-col relative justify-center">
          <div
            style={{
              borderBottom: '2px solid rgb(44 45 60)',
              borderTop: '1px solid  rgb(44 45 60)',
            }}
            className={`flex  justify-end items-center pr-4 gap-x-2 bg-[#1e1e1e] py-3   `}
          >
            <Button
              icon
              rounded
              color="green"
              size="tiny"
              loading={isLoading}
              onClick={handleRunCode}
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
              type="button"
            >
              <Icon name="play" />
            </Button>
            {currentUser?.isStudent && !submission && (
              <Button
                primary
                size="tiny"
                disabled={!code || viewOnly}
                onClick={toggleSendModalOpen}
                type="button"
              >
                <Icon name="paper plane" />
                Entregar
              </Button>
            )}
          </div>

          <Editor
            readOnly={submission !== null}
            value={code}
            onChange={handleEditorChange}
          />
          <Terminal />
        </div>
      </form>
    </>
  )
}

export default Workspace
