import TypeForm from './TypeForm'
import FileUpload from './FileUpload'
import Forum from './Forum/Forum'
import Exam from './Exam'
import { DragAndDrop } from './DragAndDrop'
import Coding from './Coding/bootstrap'

const types = {
  typeform: {
    slug: 'typeform',
    Component: TypeForm,
    displayText: 'Lección interactiva',
  },
  file_upload: {
    slug: 'file_upload',
    Component: FileUpload,
    displayText: 'Envío de documentos',
  },
  forum: {
    slug: 'forum',
    Component: Forum,
    displayText: 'Foro',
  },
  q_a: {
    slug: 'q_a',
    Component: Forum,
    displayText: 'Q/A',
  },
  exam: {
    slug: 'exam',
    Component: Exam,
    displayText: 'Examen',
  },
  drag_n_drop: {
    slug: 'drag_n_drop',
    Component: DragAndDrop,
    displayText: 'Drag n Drop',
  },
  coding_assignment: {
    slug: 'coding_assignment',
    Component: Coding,
    displayText: 'Coding Assignment',
  },
}
export default types
