import styled from 'styled-components'

export default function LockedAssignment() {
  return (
    <LockContainer>
      <LockerIcon>🔒</LockerIcon>
      <Description>Espera a que abran esta actividad</Description>
    </LockContainer>
  )
}

const LockContainer = styled.div`
  background-color: #252525;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  border-radius: 10px;
`

const LockerIcon = styled.h1`
  font-size: 3rem;
  margin: 0;
`

const Description = styled.p`
  color: #e9e9e9 !important;
`
