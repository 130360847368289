import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import ParentInformer from '../utils/ParentInformer'
export default function Embed() {
  const [searchParams] = useSearchParams()
  const url = searchParams.get('url')
  const height = searchParams.get('height')

  if (height && height.includes('px')) {
    ParentInformer.sendElementHeight('embed', height)
  }

  return (
    <Flex>
      <iframe
        allowFullScreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        src={url}
        frameBorder="0"
        style={{ overflow: 'hidden', height: '100%', width: '100%' }}
        height="100%"
        width="100%"
      ></iframe>
    </Flex>
  )
}

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
