import React from 'react'
import { Table } from 'semantic-ui-react'
import { RiCheckboxMultipleFill } from 'react-icons/ri'
import { BsTextLeft, BsQuestionDiamondFill } from 'react-icons/bs'
import { GiChoice } from 'react-icons/gi'
import { FaEnvelope } from 'react-icons/fa'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { selectSelectedSubmission } from 'app/store/uiSlice'
import { selectCurrentUser } from 'app/store/authSlice'

export default function CompletedTypeformAssignment() {
  const submission = useSelector(selectSelectedSubmission)
  const currentUser = useSelector(selectCurrentUser)
  const theme = useTheme()

  if (!submission?.details?.formId && !submission?.details?.form_id) return <></>

  let fieldNames = {}
  const details =
    typeof submission.details === 'string'
      ? JSON.parse(submission.details.replaceAll('=>', ':'))
      : submission.details

  if (!details.fields || !details.answers) {
    return <span />
  }

  details?.fields.map((f) => {
    fieldNames[f.id] = f.title

    if (
      Object.hasOwn(f, 'properties') &&
      Object.hasOwn(f.properties, 'fields')
    ) {
      f.properties.fields.map((subField) => {
        fieldNames[subField.id] = subField.title
      })
    }
  })

  if (!submission) return <></>

  return (
    <Flex
      align="flex-start"
      justify="flex-start"
      paddingLeft={0}
      paddingRight={0}
      paddingTop={18}
    >
      <Table unstackable inverted={theme.isDark} style={{ width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Pregunta</Table.HeaderCell>
            <Table.HeaderCell>Respuesta</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {details &&
            details.answers &&
            details.answers.length > 0 &&
            details.answers.map((answer, index) => {
              let content = JSON.stringify(answer)
              let icon = <GiChoice size={20} />
              switch (answer.type) {
                case 'choice': {
                  icon = (
                    <span>
                      <GiChoice size={20} /> Opción múltiple
                    </span>
                  )

                  // Find field
                  let field = details.fields.find(
                    (f) => f.id === answer.field.id
                  )

                  // Find nested fields
                  if (!field) {
                    for (let i = 0; i < details.fields.length; i++) {
                      const f = details.fields[i]
                      const subfields = f.properties.fields

                      field = subfields.find((subField) => {
                        return subField.id === answer.field.id
                      })

                      if (field) {
                        break
                      }
                    }
                  }

                  let choices = []
                  if (field) {
                    choices = field.choices
                      ? field.choices
                      : field.properties
                      ? field.properties.choices
                      : []
                  }

                  content = (
                    <ul>
                      {choices.map((c, index) => (
                        <Chip
                          key={index}
                          selected={c.label === answer.choice.label}
                        >
                          {c.label}
                        </Chip>
                      ))}
                    </ul>
                  )
                  break
                }
                case 'choices':
                  content = answer.choices.labels.join(', ')
                  icon = <RiCheckboxMultipleFill size={20} />
                  break
                case 'text':
                  content = answer.text
                  icon = (
                    <>
                      <span>
                        <BsTextLeft size={20} />
                        Abierta
                      </span>
                    </>
                  )
                  break
                case 'boolean':
                  content = answer.boolean ? 'Sí' : 'No'
                  icon = (
                    <span>
                      <BsQuestionDiamondFill size={20} /> Sí/No
                    </span>
                  )
                  break
                case 'email':
                  content = answer.email
                  icon = (
                    <span>
                      <FaEnvelope size={20} /> Email
                    </span>
                  )
                  break
                case 'number':
                  content = answer.number
                  icon = (
                    <span>
                      <BsQuestionDiamondFill size={20} /> Numérica
                    </span>
                  )
                  break
                case 'date':
                  content = answer.date
                  icon = (
                    <>
                      <span>
                        <BsQuestionDiamondFill size={20} /> Fecha
                      </span>
                    </>
                  )
                  break
                case 'phone_number':
                  content = answer.phone_number
                  icon = (
                    <span>
                      <BsQuestionDiamondFill size={20} /> Teléfono
                    </span>
                  )
                  break
                case 'short_text':
                  content = answer.text
                  icon = (
                    <span>
                      <BsTextLeft size={20} /> Abierta
                    </span>
                  )
                  break
                default:
                  content = JSON.stringify(answer)
                  icon = (
                    <span>
                      <BsQuestionDiamondFill size={20} /> Otro
                    </span>
                  )
                  break
              }

              return (
                <Table.Row key={index}>
                  <Table.Cell>{icon}</Table.Cell>
                  <Table.Cell>{fieldNames[answer.field.id]}</Table.Cell>
                  <Table.Cell>{content}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </Flex>
  )
}
const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: ${(props) => (props.align ? props.align : 'flex-start')};
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  width: 100%;
  height: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};
  flex: ${(props) => (props.flex ? props.flex : '1 1 0')};
  padding-top: ${(props) =>
    props.paddingTop ? `${props.paddingTop}px` : '0px'};
`

const Chip = styled.div`
  width: fit-content;
  padding: 0.3rem 0.8rem;
  border-radius: 2rem;
  color: ${(props) => (props.selected ? 'white' : '#434343')};
  background-color: ${(props) => (props.selected ? '#21BA45' : '#F0EFF0')};
  font-weight: bold;
  font-size: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.4rem;
`
