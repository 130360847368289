import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setIsFullScreenActive,
  selectIsFullScreenActive,
} from '../store/uiSlice'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
//import FABContainer from '../components/containers/FABContainer'
import fscreen from 'fscreen'
import Flex from './containers/Flex'

export default function FullScreen({ children, className }) {
  const isFullScreenActive = useSelector(selectIsFullScreenActive)
  const node = useRef(document.body)
  const dispatch = useDispatch()
  //const fabLeft = assignmentType === 'typeform' || assignmentType === 'board'

  useEffect(() => {
    const handleChange = () => {
      dispatch(setIsFullScreenActive(!!fscreen.fullscreenElement))
    }
    fscreen.addEventListener('fullscreenchange', handleChange)
    return () => fscreen.removeEventListener('fullscreenchange', handleChange)
  }, [])

  const toggleFullScreen = () => {
    if (fscreen.fullscreenElement || isFullScreenActive) {
      return fscreen.requestFullscreen(node.current).then(() => {
        return fscreen.exitFullscreen()
      })
    } else if (node.current) {
      return fscreen.requestFullscreen(node.current)
    }
  }

  const classNames = []
  if (className) {
    classNames.push(className)
  }

  return (
    <div
      className={classNames.join(' ')}
      style={
        isFullScreenActive
          ? {
              height: '100%',
              width: '100%',
            }
          : {
              borderRadius: '8px',
            }
      }
    >
      {children}
      {
        <Flex full justify="flex-end" align="flex-end">
          <IconButtonContainer icon onClick={toggleFullScreen}>
            <Icon name={isFullScreenActive ? 'compress' : 'expand'} />
          </IconButtonContainer>
        </Flex>
      }
    </div>
  )
}

const IconButtonContainer = styled(Button)`
  margin-top: 12px !important;
  padding: 8px !important;
`
