const defaultColors = [
  { r: 164, g: 249, b: 200, hex: '#A4F9C8' },
  { r: 169, g: 248, b: 251, hex: '#A9F8FB' },
  { r: 182, g: 220, b: 254, hex: '#B6DCFE' },
  { r: 48, g: 77, b: 109, hex: '#304D6D' },
  { r: 167, g: 255, b: 246, hex: '#A7FFF6' },
  { r: 255, g: 177, b: 210, hex: '#FFB1D2' },
  { r: 188, g: 255, b: 219, hex: '#BCFFDB' },
  { r: 222, g: 255, b: 242, hex: '#DEFFF2' },
  { r: 226, g: 248, b: 151, hex: '#E2F897' },
]

export const getRandomColor = () => {
  const color = defaultColors[Math.floor(Math.random() * defaultColors.length)]
  return color.hex
}

export default defaultColors
