import React, { useState } from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../store/authSlice'
import {
  getContrastColorHex,
  getContrastColorRgba,
} from '../../utils/getContrastColor'
import { defaultColors } from 'app/utils'

export default function GamePoll({
  question,
  results,
  votes,
  colors,
  subheading,
  onVote,
  isVoted,
  isLoading,
  showVotes = false,
}) {
  const totalVotes = results.reduce(
    (previous, current) => (previous || 0) + (current.votes || 0),
    0
  )
  const [selectionId, setSelection] = useState(-1)
  const currentUser = useSelector(selectCurrentUser)

  const onSelectOption = (selection) => {
    if (!isVoted) {
      setSelection(selection.id)
      onVote(selection.id)
    }
  }

  const getColor = (option, index) => {
    let color = null
    if (colors === undefined || colors === null) {
      color = { r: 170, g: 170, b: 170 }
    }
    if (Object.hasOwn(colors, option.id)) {
      const value = colors[option.id]
      const hexValue = value.replace('#', '')
      const r = parseInt(hexValue.substring(0, 2), 16)
      const g = parseInt(hexValue.substring(2, 4), 16)
      const b = parseInt(hexValue.substring(4, 6), 16)
      color = { r, g, b }
    } else {
      color = defaultColors[index % defaultColors.length]
    }

    let alpha = 1

    if (isVoted) {
      alpha =
        option.id === selectionId || option.id === votes[currentUser.id]
          ? 1
          : 0.5
    }

    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`
  }

  const calculatePercentage = (option) => {
    const percentage = (option.votes * 100) / (totalVotes || 1)
    return `${Math.round(percentage * 100) / 100}%`
  }

  return (
    <PollContainer>
      <Header>{question}</Header>
      {!!subheading && <Subheader>{subheading}</Subheader>}
      <Grid style={{ width: '100%', marginTop: '1rem' }} columns="two">
        <Grid.Row stretched>
          {results.map((res, index) => (
            <Grid.Column
              style={{ padding: '1px' }}
              textAlign="center"
              key={res.id}
            >
              <Segment
                inverted
                onClick={() => onSelectOption(res)}
                style={{
                  cursor: !isVoted && 'pointer',
                  backgroundColor: getColor(res, index),
                  padding: '3rem',
                  width: '100%',
                  maxHeight: '100%',
                }}
                loading={isLoading && res.id === selectionId}
              >
                <OptionText
                  style={{
                    color: getContrastColorRgba(getColor(res, index)),
                  }}
                >
                  {res.text}
                </OptionText>
                {(isVoted || showVotes) && (
                  <Percentage
                    style={{
                      color: getContrastColorRgba(getColor(res, index)),
                    }}
                  >
                    {calculatePercentage(res)} ({res.votes})
                  </Percentage>
                )}
              </Segment>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </PollContainer>
  )
}

const PollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OptionText = styled.h3`
  font-weight: bold;
`

const Percentage = styled.h4``
const Header = styled.h1`
  color: ${(props) => props.theme.text};
`
const Subheader = styled.p`
  color: ${(props) => props.theme.textSecondary};
`
