import React, { useState, createRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { Dropdown, Segment, Button, Icon } from 'semantic-ui-react'
import { FaTrash } from 'react-icons/fa'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import { convertFromHTML } from 'draft-convert'
import draftToHtml from 'draftjs-to-html'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import QuestionInput from './QuestionInput'
import editorHtmlOpts from 'app/utils/editorHtmlOpts'

const optionsTypes = [
  { key: 'radio', text: 'Opción múltiple', value: 'RadioButtons' },
  { key: 'open', text: 'Pregunta abierta', value: 'MultiLineInput' },
]

export default function Question({
  id,
  onDelete,
  question,
  onUpdate,
  onDuplicate,
}) {
  const fileInputRef = createRef()
  const [optionType, setOptionType] = useState(
    question.element ? question.element : 'RadioButtons'
  )
  const [options, setOptions] = useState(
    question.options
      ? question.options
      : [{ value: uuidv4(), text: { es: '' } }]
  )
  const [myFiles, setMyFiles] = useState([])
  const [editorState, setEditorState] = useState(() => {
    if (question.questions) {
      return EditorState.createWithContent(
        convertFromHTML(editorHtmlOpts)(question.questions.es)
      )
    }

    return EditorState.createEmpty()
  })
  const theme = useTheme()

  const onOptionTypeChange = (_, { value }) => {
    setOptionType(value)

    if (value === 'RadioButtons') {
      onUpdate({
        ...question,
        element: value,
        options,
      })
    } else {
      const questionCopy = { ...question, element: value }
      delete questionCopy.options
      onUpdate(questionCopy)
    }
  }

  const onOptionTextChange = (newText, index) => {
    const optionsCopy = [...options]

    optionsCopy[index] = {
      ...optionsCopy[index],
      text: {
        es: newText,
      },
    }

    setOptions(optionsCopy)
    onUpdate({
      ...question,
      options: optionsCopy,
    })
  }

  const addNewOption = () => {
    const optionsCopy = [...options]
    optionsCopy.push({ value: uuidv4(), text: { es: '' } })
    setOptions(optionsCopy)
    onUpdate({
      ...question,
      options: optionsCopy,
    })
  }

  const onSetCorrectOption = (index) => {
    const optionsCopy = [...options]
    const optionCopy = { ...options[index] }

    for (let i = 0; i < optionsCopy.length; i++) {
      if (i !== index) {
        const element = { ...optionsCopy[i] }
        if (element.isCorrect) {
          delete element.isCorrect
        }
        optionsCopy[i] = element
      }
    }

    if (optionCopy.isCorrect) {
      delete optionCopy.isCorrect
    } else {
      optionCopy.isCorrect = true
    }

    optionsCopy[index] = optionCopy
    setOptions(optionsCopy)
    onUpdate({
      ...question,
      options: optionsCopy,
    })
  }

  const onDeleteOption = (index) => {
    const optionsCopy = [...options]
    optionsCopy.splice(index, 1)
    setOptions(optionsCopy)
    onUpdate({
      ...question,
      options: optionsCopy,
    })
  }

  const handleFiles = (e) => {
    setMyFiles(Array.prototype.slice.call(e.target.files))
    const questionCopy = { ...question }
    questionCopy.imageFile = e.target.files[0]
    delete questionCopy.imageUrl
    onUpdate(questionCopy)
  }

  const removeFile = (file) => {
    setMyFiles(myFiles.filter((f) => f !== file))
    const questionCopy = {
      ...question,
    }
    delete questionCopy.imageFile
    onUpdate(questionCopy)
  }

  const handleEditorChanged = (state) => {
    const content = draftToHtml(convertToRaw(state.getCurrentContent()))
    setEditorState(state)
    onUpdate({
      ...question,
      questions: {
        es: content,
      },
    })
  }

  let content

  if (optionType === 'RadioButtons') {
    content = (
      <Segment.Group>
        {options.map((option, index) => (
          <OptionSegment key={option.value}>
            <CorrectOptionButton
              color={option.isCorrect ? 'green' : 'grey'}
              circular
              icon="check"
              onClick={() => onSetCorrectOption(index)}
            />
            {/* <Input style={{ flexGrow: 1 }} placeholder='Opción sin título' value={option.text['es']} onChange={(_, {value}) => onOptionTextChange(value, index)}/> */}
            <QuestionInput
              value={option.text['es']}
              onChange={(value) => onOptionTextChange(value, index)}
            />
            {options.length > 1 && (
              <InvisibleButton onClick={() => onDeleteOption(index)}>
                <Icon name="trash" color="red" />
              </InvisibleButton>
            )}
          </OptionSegment>
        ))}
        <OptionSegment>
          <Button inverted={theme.isDark} onClick={addNewOption}>
            Agregar opción
            <Icon style={{ marginLeft: '0.5rem' }} name="add" />
          </Button>
        </OptionSegment>
      </Segment.Group>
    )
  } else {
    content = <></>
  }

  return (
    <QuestionSegment padded>
      {/* Título y tipo de pregunta */}
      <QuestionTitle>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChanged}
          toolbar={{
            options: ['inline'],
            inline: {
              inDropdown: false,
              options: [
                'bold',
                'italic',
                'underline',
                'superscript',
                'subscript',
              ],
            },
          }}
          wrapperStyle={{ flexGrow: '1' }}
          placeholder="Pregunta sin título"
          editorStyle={{
            backgroundColor: theme.sidebarBackground,
            color: theme.text,
            border: `1px solid ${theme.cardBorder}`,
            borderRadius: '4px',
            minHeight: '4rem',
            maxHeight: '4rem',
            overflow: 'hidden',
            width: '100%',
          }}
          toolbarStyle={{
            backgroundColor: theme.sidebarBackground,
            color: theme.text,
            border: `1px solid ${theme.cardBorder}`,
            borderRadius: '4px',
          }}
        />
        <Dropdown
          style={{ color: 'red' }}
          button
          basic
          floating
          options={optionsTypes}
          value={optionType}
          onChange={onOptionTypeChange}
        />
      </QuestionTitle>
      {/* Imagen */}
      <Flex
        direction="column"
        align="flex-start"
        justify="flex-start"
        style={{ marginTop: '1rem' }}
      >
        {!!question.imageUrl && <Thumb src={question.imageUrl} height="50" />}
        <AddImageButton
          content="Sube fotos, videos y archivos"
          icon="picture"
          onClick={() => fileInputRef.current.click()}
          primary
        />
        <input ref={fileInputRef} type="file" hidden onChange={handleFiles} />
      </Flex>
      <Flex gap={15} align="flex-start" justify="flex-start" wrap="wrap">
        {myFiles.map((x) => (
          <File key={x.name} onClick={() => removeFile(x)}>
            <FaTrash size={14} /> {x.name}
          </File>
        ))}
      </Flex>
      {/* Answer question */}
      {content}
      {/* Actions */}
      <ActionsContainer>
        {/* Duplicate */}
        <TrashButtonContainer>
          <InvisibleButton onClick={() => onDuplicate(id)}>
            <Icon name="clone outline" color="black" />
            Duplicar pregunta
          </InvisibleButton>
        </TrashButtonContainer>
        {/* Delete */}
        <TrashButtonContainer>
          <InvisibleButton onClick={() => onDelete(id)}>
            <Icon name="trash" color="red" />
            Eliminar pregunta
          </InvisibleButton>
        </TrashButtonContainer>
      </ActionsContainer>
    </QuestionSegment>
  )
}

const QuestionSegment = styled(Segment)`
  background-color: ${(props) => props.theme.background} !important;
`
const QuestionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`
const CorrectOptionButton = styled(Button)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem !important;
  margin: 0 !important;
  margin-right: 1rem !important;
`
const OptionSegment = styled(Segment)`
  display: flex !important;
  align-items: center !important;
  background-color: ${(props) => props.theme.background} !important;
`
const InvisibleButton = styled(Button)`
  background-color: ${(props) => props.theme.background} !important;
  color: ${(props) => props.theme.text} !important;
  margin: 0 !important;
`
const TrashButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`
const AddImageButton = styled(Button)`
  margin-top: 1rem !important;
`
const File = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  svg {
    padding-right: 3px;
  }
  &:hover {
    cursor: pointer;
    color: red;
    svg {
      fill: red;
    }
  }
`
const Flex = styled.div`
  display: flex;
  width: 100%;
  gap: ${(props) => props.gap || 0}px;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  margin: ${(props) => props.margin || 0}px;
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
`
const Thumb = styled.img`
  border-radius: 5px;
  border: 1px solid transparent;
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
