import { Label, Icon } from 'semantic-ui-react'
import DateTime from 'app/components/shared/DateTime'
import moment from 'moment'
import { gradeColors } from 'app/components/shared/GradeColors'
import { useTheme } from 'styled-components'

export default function SubmissionDetails({ submission }) {
  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Label color="green">Actividad completada</Label>
        <Label color={theme.isDark ? 'black' : null}>
          <Icon name="calendar" />
          <DateTime
            utc={false}
            value={moment(submission.createdAt)}
            format="LL"
          />
        </Label>
        <Label color={theme.isDark ? 'black' : null}>
          <Icon name="clock" />
          <DateTime
            utc={false}
            value={moment(submission.createdAt)}
            format="h:mm a"
          />
        </Label>
      </div>
      {submission?.grade || submission?.grade === 0 ? (
        <Label
          size="big"
          circular
          color={
            gradeColors.find(
              (gc) => +gc.value === Math.round(Number(submission.grade))
            )?.color
          }
        >
          {submission?.grade}
        </Label>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Label color={theme.isDark ? 'black' : null}>
            Calificación pendiente
          </Label>
        </div>
      )}
    </div>
  )
}
