export async function RunPythonCode(code = '') {
  let prints = []
  const pyodide = await window.loadPyodide({
    stdout: (out) => prints.push(out),
  })

  if (code.includes('input(')) {
    await pyodide.runPythonAsync(`
    from js import input_fixed
    input = input_fixed
    __builtins__.input = input_fixed
    `)
  }

  pyodide.runPython(code)

  return prints
}
