import { useEffect, useLayoutEffect, useState } from 'react'
import timeInterval from '../../utils/timeInterval'
import { useTheme } from 'styled-components'
import './stopwatch.css'

const DARK_MODE_CLASSNAME = 'dark-mode'
const LIGHT_MODE_CLASSNAME = 'light-mode'
const DEFAULT_LAPS = []

export default function Stopwatch({ DEFAULT_ELAPSED = 0 }) {
  const theme = useTheme()
  const [mode] = useState(
    theme.isDark ? DARK_MODE_CLASSNAME : LIGHT_MODE_CLASSNAME
  )
  const [elapsed, setElapsed] = useState(DEFAULT_ELAPSED)
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null)
  const [isTicking, setIsTicking] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [laps, setLaps] = useState(DEFAULT_LAPS)

  const start = (elapsedFormatted) => {
    if (isTicking) {
      mark(elapsedFormatted)
      return
    }

    if (!startTime) setStartTime(Date.now())
    setIsTicking(true)
  }

  const update = () => {
    const now = Date.now()
    setElapsed(now - startTime + elapsed)
  }

  const mark = (elapsedFormatted) => {
    if (!isTicking) return
    setLaps([elapsedFormatted, ...laps])
  }

  useLayoutEffect(() => {
    if (!isTicking) return
    setIntervalId(setInterval(() => update(), 10))
  }, [isTicking])

  useEffect(() => {
    if (!isTicking) {
      start(elapsedFormatted)
    }
  })

  const {
    minutesRotation,
    secondsRotation,
    millisecsRotation,
    elapsedFormatted,
  } = timeInterval(elapsed)
  const className = isTicking ? 'is-active' : null

  return (
    <main className={`stopwatch-main ${className} ${mode}`}>
      <div className="stopwatch-clock">
        <Hand className="minute" value={minutesRotation} />
        <Hand className="second" value={secondsRotation} />
        <Hand className="millisec" value={millisecsRotation} />

        <span className="second-labels fifteen">15</span>
        <span className="second-labels thirty">30</span>
        <span className="second-labels fortyfive">45</span>
        <span className="second-labels sixty">60</span>

        <span className="millisec-labels twofifty">250</span>
        <span className="millisec-labels fivehundred">500</span>
        <span className="millisec-labels sevenfifty">750</span>
        <span className="millisec-labels onethousand">1000</span>
      </div>
      <div>
        <div className="stopwatch-time elapsed">{elapsedFormatted}</div>
      </div>
    </main>
  )
}

const Hand = ({ className, value }) => (
  <div
    className={`hand ${className}`}
    style={{ transform: `translateX(-50%) rotate(${value}deg)` }}
  />
)
