export const gradeColors = [
  { value: 0, color: 'red' },
  { value: 1, color: 'red' },
  { value: 2, color: 'red' },
  { value: 3, color: 'red' },
  { value: 4, color: 'red' },
  { value: 5, color: 'red' },
  { value: 6, color: 'yellow' },
  { value: 7, color: 'yellow' },
  { value: 8, color: 'green' },
  { value: 9, color: 'green' },
  { value: 10, color: 'green' },
]
