import { createClient } from '@liveblocks/client'

import { createRoomContext } from '@liveblocks/react'

import { store } from '../../store/store'

const client = createClient({
  authEndpoint: async (room) => {
    const headers = {
      'Content-Type': 'application/json',
    }

    const { auth } = store.getState()

    const body = JSON.stringify({
      room,
      userId: auth.currentUser.id,
    })

    const response = await fetch(
      `${process.env.REACT_APP_HUB_API}/api/utils.liveblocksAuth`,
      {
        method: 'POST',
        headers,
        body,
      }
    )

    const { data } = await response.json()

    return JSON.parse(data)
  },
})

const {
  RoomProvider,
  useHistory,
  useRedo,
  useUndo,
  useRoom,
  useUpdateMyPresence,
} = createRoomContext(client)

export {
  RoomProvider,
  useHistory,
  useRedo,
  useUndo,
  useRoom,
  useUpdateMyPresence,
}
