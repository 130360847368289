import React, { useState, useEffect } from 'react'
import {
  useAddPollVoteMutation,
  useCreateElementMutation,
} from '../store/apiHubSlice'
import { Form, Input, Button, List, Icon } from 'semantic-ui-react'
import GamePoll from './Poll/Game'
import styled, { useTheme } from 'styled-components'
import 'react-leaf-polls-copy/dist/index.css'
import { selectCurrentUser } from '../store/authSlice'
import { useSelector } from 'react-redux'
import { defaultColors } from '../utils'
import { v4 as uuidv4 } from 'uuid'
import { PopoverPicker } from '../components/PopoverPicker'
import { ParentInformer } from '../utils'

// Element for creating a poll
function PollConfig({ pollId, elementConfig }) {
  const [isReadyToCreate, setIsReadyToCreate] = useState(false)
  const [options, setOptions] = useState([])
  const [newOption, setNewOption] = useState('')
  const [newElement, setNewElement] = useState({
    config: {
      layout: 'game',
      title: '',
      subheading: '',
      public: false,
      options: {},
      colors: {},
      anonymousResponse: true,
    },
    data: {
      results: {},
      votes: {},
    },
  })
  const [createElement, { isLoading: isCreatingElement }] =
    useCreateElementMutation()
  const theme = useTheme()

  // Handle adding an option to the poll
  const handleAddOption = () => {
    if (!newOption.trim()) return

    const optionsCopy = [...options]
    optionsCopy.push({ id: uuidv4(), value: newOption })
    setOptions(optionsCopy)
    setNewOption('')
    setIsReadyToCreate(true)

    setNewElement({
      ...newElement,
      config: {
        ...newElement.config,
        options: optionsCopy.map((option) => ({
          id: option.id,
          value: option.value,
        })),
      },
    })
  }

  // Handle removing an option from the poll
  const handleRemoveOption = (i) => {
    const optionsCopy = [...options]
    optionsCopy.splice(i, 1)
    setOptions(optionsCopy)

    if (optionsCopy.length === 0) {
      setIsReadyToCreate(false)
    }

    setNewElement({
      ...newElement,
      config: {
        ...newElement.config,
        options: optionsCopy.map((option) => ({
          id: option.id,
          value: option,
        })),
      },
    })
  }

  // Handle title change
  const handleTitleChange = ({ target }) => {
    setNewElement({
      ...newElement,
      config: {
        ...newElement.config,
        title: target.value,
      },
    })
  }

  // Handle subheading change
  const handleSubheadingChange = ({ target }) => {
    setNewElement({
      ...newElement,
      config: {
        ...newElement.config,
        subheading: target.value,
      },
    })
  }

  // Handle option color change
  const handleColorChange = (option, newColor) => {
    let previousColors = { ...newElement.config.colors }
    previousColors[option.id] = newColor.hex
    setNewElement({
      ...newElement,
      config: {
        ...newElement.config,
        colors: previousColors,
      },
    })
  }

  // Gets a color for an option
  const getOptionColor = (option, index) => {
    if (Object.hasOwn(newElement.config.colors, option.id)) {
      return newElement.config.colors[option.id]
    }

    const color = defaultColors[index % defaultColors.length]
    return color.hex
  }

  const handleInputChange = (e) => {
    setNewOption(e.target.value)
  }

  // Creates a new element
  const handleCreateElement = async () => {
    try {
      const formattedResults = {}

      newElement.config.options.forEach((option) => {
        formattedResults[option.id] = 0
      })

      await createElement({
        id: pollId,
        type: 'poll',
        config: {
          ...elementConfig,
          ...newElement.config,
        },
        data: {
          ...newElement.data,
          results: formattedResults,
        },
      })
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <FormRow>
      {/* Title */}
      <Form style={{ marginBottom: '1.5rem' }}>
        Título o pregunta
        <Input
          value={newElement.config.title}
          onChange={handleTitleChange}
          style={{ width: '100%' }}
          focus
          placeholder="Título"
        />
      </Form>
      {/* Subheading */}
      <Form style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}>
        Subtítulo
        <Input
          value={newElement.config.subheading}
          onChange={handleSubheadingChange}
          style={{ width: '100%' }}
          focus
          placeholder="Subtítulo"
        />
      </Form>
      {/* Options */}
      <Form style={{ width: '100%', display: 'flex' }}>
        <Input
          style={{ flex: 'auto' }}
          value={newOption}
          placeholder="Agrega una opción"
          onInput={handleInputChange}
        />
        <Button
          inverted={theme.isDark}
          style={{ margin: '0', marginLeft: '0.8rem' }}
          onClick={handleAddOption}
        >
          Agregar
        </Button>
      </Form>
      <List style={{ marginBottom: '2rem' }} divided verticalAlign="middle">
        {options.map((option, index) => (
          <List.Item
            style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            key={option.id}
          >
            <List.Content floated="right">
              <Icon
                color="red"
                onClick={() => handleRemoveOption(option.id)}
                style={{ margin: '0', cursor: 'pointer' }}
                name="trash alternate"
              />
            </List.Content>
            <List.Content style={{ display: 'flex', alignItems: 'center' }}>
              {newElement.config.layout === 'game' && (
                <PopoverPicker
                  color={getOptionColor(option, index)}
                  onChange={(color) => handleColorChange(option, color)}
                />
              )}
              <span style={{ marginLeft: '0.5rem' }}>{option.value}</span>
            </List.Content>
          </List.Item>
        ))}
      </List>
      <div>
        <Button
          loading={isCreatingElement}
          disabled={!isReadyToCreate || newElement.config.title.length < 3}
          primary
          floated="right"
          onClick={handleCreateElement}
        >
          Crear
        </Button>
        <small style={{ color: 'red' }}>{}</small>
      </div>
    </FormRow>
  )
}

// Element for rendering a poll
export default function Poll({
  id,
  element,
  elementNotFound,
  elementConfig = {},
}) {
  const [addPollVote, { isLoading: isLoadingAddVote }] =
    useAddPollVoteMutation()
  const currentUser = useSelector(selectCurrentUser)
  const [poll, setPoll] = useState(null)
  const [formatResults, setFormatResults] = useState(null)

  // Initialize poll's data
  useEffect(() => {
    if (!element) return

    // Get poll query data
    storePollData(element)
  }, [element])

  // Formats poll data and stores it
  function storePollData(poll) {
    const { title, layout, subheading, colors, options, anonymousResponse } =
      poll.config
    const { results, votes = {} } = poll.data
    // Format options
    const formattedResults = getFormatedPollOptions({ options, results })
    setFormatResults(formattedResults)
    // Set poll data
    setPoll({
      title,
      subheading,
      votes,
      results,
      layout,
      options,
      colors,
      anonymousResponse,
    })
  }

  // Formats options in a friendly way
  function getFormatedPollOptions({ options, results }) {
    return options.map((option) => ({
      id: option.id,
      text: option.value,
      votes: results[option.id] || 0,
    }))
  }

  // Handle submit vote
  const handleNewVote = async (selectionId) => {
    if (!Object.hasOwn(poll.votes, currentUser.id) && !isLoadingAddVote) {
      // Post vote
      await addPollVote({
        elementId: id,
        selectedOptionId: selectionId,
      }).unwrap()

      ParentInformer.sendEngagement({
        eventName: 'poll:vote',
        elementType: 'poll',
        element: {
          id: id,
          pollTitle: poll.title,
        },
      })
    }
  }

  let content
  // Check if activity hasn't been created
  if (elementNotFound && !currentUser.isStudent) {
    content = <PollConfig pollId={id} elementConfig={elementConfig} />
  } else if (elementNotFound) {
    content = <h1>Aún no terminan de crear esta actividad</h1>
  }

  // Show poll design
  if (!elementNotFound && poll) {
    // Set appropiate layout
    content = (
      <GamePoll
        question={poll.title}
        subheading={poll.subheading}
        results={formatResults}
        colors={poll.colors}
        votes={poll.votes}
        isLoading={isLoadingAddVote}
        onVote={handleNewVote}
        isVoted={Object.hasOwn(poll.votes, currentUser.id)}
        showVotes={!currentUser.isStudent}
      />
    )
  }

  return <PollContainer>{content}</PollContainer>
}

const PollContainer = styled.div`
  padding: 2rem;
`
const FormRow = styled.div`
  width: 100%;
  margin-bottom: 10px;

  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
