import React from 'react'
import styled from 'styled-components'
import { Input } from 'semantic-ui-react'
import {
  setAssigment,
  selectAssigment,
  setReady,
} from '../../../store/assigmentCreationSlice'
import { useDispatch, useSelector } from 'react-redux'
export default function TypeFormConfig() {
  let dispatch = useDispatch()
  const assignment = useSelector(selectAssigment)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (Object.keys(assignment.configuration).length === 0) {
      dispatch(
        setAssigment({
          ...assignment,
          configuration: {
            form_id: '',
            form_url: '',
            isSynchronous: assignment.configuration.isSynchronous,
          },
        })
      )
    }
  }, [assignment])

  const handleLinkChange = (e) => {
    const url = e.target.value
    const id = isValidUrl(url) ? url.split('/').pop() : ''
    let config = { ...assignment.configuration }
    config.form_url = url
    config.form_id = id
    dispatch(
      setAssigment({
        ...assignment,
        configuration: config,
      })
    )
    if (isValidUrl(url)) {
      dispatch(setReady(true))
      setError(null)
    } else {
      dispatch(setReady(false))
      setError('Introduce un link de Typeform válido')
    }
  }

  const isValidUrl = (url) => {
    const URL_REGEX = new RegExp(
      '^https://([A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?).typeform.com/to/([^#]*)$'
    )
    return URL_REGEX.test(url)
  }

  let content = (
    <FormRow>
      <span>Link del typeform</span>
      <Input
        placeholder="https://"
        style={{ width: '100%' }}
        value={assignment.configuration.form_url || ''}
        onChange={handleLinkChange}
      />
      <div>{error && <small style={{ color: 'red' }}>{error}</small>}</div>
    </FormRow>
  )

  return content
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 10px;

  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
