export const initialCode = `# escribe tu código aquí`

export const PYODIDE_SCRIPT_SRC =
  'https://cdn.jsdelivr.net/pyodide/v0.23.1/full/pyodide.js'

export const PYODIDE_SCRIPT_ID = 'pyodide'

export const FAILED_LOAD_SCRIPT_MESSAGE =
  'Python Compiler (Pyodide) Script failed to load, try again later'

export const EDITOR_BG_COLOR = '#1e1e1e'

export const CONSOLE_RESULTS_TYPES = Object.freeze({
  RESULT: 'result',
  ERROR: 'error',
  STALE: 'stale',
})

export const MIN_WIDTH_RESPONSIVE = 768

export const ASSIGMENT_HEADER_HEIGHT_PX = {
  STUDENT: 40,
  NOT_STUDENT: 146,
}

export const MAX_TERMINAL_HEIGHT_PX = 505
