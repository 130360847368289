import { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { Loader } from 'semantic-ui-react'
import PostsList from './components/PostsList'
import PostDetail from './components/PostDetail'
import {
  selectEnrollmentGroupId,
  selectSelectedPost,
} from '../../../store/uiSlice'
import { selectCurrentUser } from '../../../store/authSlice'
import { setStudentAnsweredForum } from 'app/store/uiSlice'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import getFileUrl from '../../../utils/getFileUrl'
import LoaderContainer from '../../containers/LoaderContainer'
import { useGetForumPostsByAssignmentIdQuery } from 'app/store/apiHubSlice'
import { SocketContext } from 'app/context/socket'

function ImageThumbnail({ file }) {
  const [imageUrl, setImageUrl] = useState(undefined)
  useEffect(() => {
    async function getImageUrl() {
      if (imageUrl === undefined) {
        const url = await getFileUrl(
          file.file_url,
          process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
        )
        setImageUrl(url)
      }
    }
    getImageUrl()
  })
  return (
    <div key={file.file_name}>
      {imageUrl === undefined ? (
        <LoaderContainer>
          <Loader inverted />
        </LoaderContainer>
      ) : (
        <Thumb
          onClick={() => window.open(imageUrl, '_blank')}
          src={imageUrl}
          alt={file.file_name}
          height="100"
        />
      )}
    </div>
  )
}
function FileThumb({ file }) {
  const handleFileDownload = async () => {
    const url = await getFileUrl(
      file.file_url,
      process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
    )
    window.open(url, '_blank')
  }

  return (
    <FileLink key={file.file_name} onClick={handleFileDownload}>
      <Icon
        name={
          file.file_type.includes('image')
            ? 'picture'
            : file.file_type.includes('video')
            ? 'film'
            : file.file_type.includes('pdf')
            ? 'file pdf'
            : 'file text'
        }
      />
      {file.file_name}
    </FileLink>
  )
}
function FileThumbnails({ files }) {
  let images = files
    .filter((f) => f.file_type.includes('image'))
    .map((file, index) => {
      return <ImageThumbnail key={index} file={file} />
    })
  let others = files
    .filter((f) => !f.file_type.includes('image'))
    .map((file, index) => {
      return <FileThumb key={index} file={file} />
    })
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
          overflow: 'auto',
        }}
      >
        {images}
      </div>

      {others}
    </>
  )
}

const LIMIT = 12
export default function Forum({ assignment, submission, documentUuid }) {
  const { socket } = useContext(SocketContext)
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const selectedPostId = useSelector(selectSelectedPost)
  const enrollmentGroupId = useSelector(selectEnrollmentGroupId)
  const [posts, setPosts] = useState([])
  const [cursor, setCursor] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [shouldFetch, setShouldFetch] = useState(true)

  const { data, isLoading, isSuccess, isFetching, error } =
    useGetForumPostsByAssignmentIdQuery(
      {
        param: {
          key: 'assignmentId',
          value: assignment.id,
        },
        enrollmentGroupId,
        query: searchQuery,
        cursor,
        limit: LIMIT,
      },
      { skip: !shouldFetch }
    )

  const handleReceiveSubmission = useCallback(
    (data) => {
      setPosts((prev) => {
        // Check if data in posts
        if (prev && prev.find((p) => p.id === data.id)) {
          return prev
        }

        const newData = [data, ...(prev ?? [])]
        return newData
      })
    },
    [posts]
  )

  const handlePostUpdate = useCallback(
    (data) => {
      // Update posts list
      setPosts((prev) => {
        if (!prev) return prev

        return prev.map((p) => {
          if (p.id === data.id) {
            return data
          }
          return p
        })
      })
    },
    [posts]
  )

  // Subscribe to socket events
  useEffect(() => {
    // Listen to assignment events
    socket.on('forum_posts.create', handleReceiveSubmission)
    socket.on('forum_posts.update', handlePostUpdate)
    socket.on('forum_posts.comment', handlePostUpdate)
    socket.on('forum_posts.toggle_like', handlePostUpdate)

    // Unbind all events
    return () => {
      socket.off('forum_posts.create')
      socket.off('forum_posts.update')
      socket.off('forum_posts.comment')
      socket.off('forum_posts.toggle_like')
    }
  }, [handleReceiveSubmission, handlePostUpdate])

  useEffect(() => {
    if (isSuccess && !isFetching && data.posts) {
      setPosts((prevPosts) => [...prevPosts, ...data.posts])
      setShouldFetch(false)
      setCursor(data.cursor)
      if (currentUser.isStudent) {
        dispatch(setStudentAnsweredForum(!!submission))
      }
    }
  }, [isFetching])

  // debounce search
  useEffect(() => {
    if (searchTerm !== searchQuery) {
      const handler = setTimeout(() => {
        setPosts([])
        setSearchQuery(searchTerm)
        setCursor(null)
        setShouldFetch(true)
      }, 500)

      return () => clearTimeout(handler)
    }
  }, [searchTerm])

  const loadMorePosts = () => setShouldFetch(true)

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  // Try to find selectedPost
  const selectedPost = useMemo(() => {
    if (!selectedPostId || !posts) return null
    return posts?.find((p) => p.id === selectedPostId)
  }, [selectedPostId, posts])

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader active />
      </LoaderContainer>
    )
  }

  if (error) return 'Ocurrió un error al cargar este foro.'

  return (
    <>
      {selectedPost ? (
        <PostDetail
          selectedPost={selectedPost}
          user={currentUser}
          assignment={assignment}
          documentUuid={documentUuid}
        />
      ) : (
        <PostsList
          posts={posts}
          user={currentUser}
          assignment={assignment}
          documentUuid={documentUuid}
          submission={submission}
          searchValue={searchTerm}
          onSearchChange={handleSearchChange}
          hasMore={!!data?.cursor}
          loadMore={loadMorePosts}
          isLoading={isFetching}
        />
      )}
    </>
  )
}

const Thumb = styled.img`
  border-radius: 5px;
  border: 1px solid transparent;
  :hover {
    cursor: pointer;
    border: 1px solid #ccc;
  }
`
const FileLink = styled.div`
  :hover {
    cursor: pointer;
    color: #00b5ad;
    text-decoration: underline;
  }
`
