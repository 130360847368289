import React from 'react'
import { selectCurrentUser } from '../../../../store/authSlice'
import { Button, Icon } from 'semantic-ui-react'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { useToggleForumPostLikeMutation } from 'app/store/apiHubSlice'
import { selectViewOnly } from 'app/store/uiSlice'

const PostDetailButtons = ({ post, setIsEditing }) => {
  const currentUser = useSelector(selectCurrentUser)
  const viewOnly = useSelector(selectViewOnly)
  const [toggleForumPostLike] = useToggleForumPostLikeMutation()
  const likes = post?.engagement?.likes || []
  const theme = useTheme()

  const isSameUser = currentUser.id === post.user.id

  const toggleLike = async (e) => {
    e.stopPropagation()

    try {
      await toggleForumPostLike({
        postId: post.id,
        userId: currentUser.id,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      {/* Like */}
      <CustomButton
        disabled={isSameUser || viewOnly}
        labelPosition="right"
        onClick={toggleLike}
      >
        <LikeButton
          size="large"
          name={
            likes.findIndex(
              (like) =>
                like.userId === currentUser.id ||
                like.userUuid === currentUser.id
            ) != -1
              ? 'heart'
              : 'heart outline'
          }
          liked={
            likes.findIndex(
              (like) =>
                like.userUuid === currentUser.id ||
                like.userId === currentUser.id
            ) != -1
              ? 1
              : undefined
          }
        />
        <Counter style={{ color: theme.text }}>
          {likes ? likes.length : '0'}
        </Counter>
      </CustomButton>

      {/* Edit */}
      <Button
        style={{
          display: isSameUser ? 'block' : 'none',
        }}
        onClick={() => {
          setIsEditing(true)
        }}
        size="tiny"
        primary
        disabled={viewOnly}
      >
        Editar
      </Button>
    </Container>
  )
}

export default PostDetailButtons
const LikeButton = styled(Icon)`
  color: ${(props) => (props.liked ? '#ed4956' : '')};
`
const Counter = styled.span`
  font-size: 1rem;
`
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2rem;
`
const CustomButton = styled(Button)`
  margin-right: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
`
