import React, { useCallback, useState } from 'react'
import ParentInformer from '../../utils/ParentInformer'
import styled, { useTheme } from 'styled-components'
import Flex from '../containers/Flex'
import { useCreateSubmissionMutation } from '../../store/apiHubSlice'
import { selectCurrentUser } from '../../store/authSlice'
import { selectEnrollmentId } from 'app/store/uiSlice'
import { Button, Loader } from 'semantic-ui-react'
import getFileUrl from '../../utils/getFileUrl'
import { useNavigate, useLocation } from 'react-router-dom'
import Quiz from './Exam/Quiz'
import { useSelector } from 'react-redux'
import { ErrorMessageWithReload } from '../ErrorMessageWithReload'

export default function Exam({ assignment: defaultAssignment, assigment, documentId }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [finished] = React.useState(false)
  const [createSubmission, { isLoading: isSavingExam }] =
    useCreateSubmissionMutation()
  const [quiz, setQuiz] = React.useState(null)
  const [submissionError, setSubmissionError] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const theme = useTheme()
  const enrollmentId = useSelector(selectEnrollmentId)
  const assignment = defaultAssignment ?? assigment;

  React.useEffect(() => {
    async function loadQuiz() {
      const updatedQuiz = []

      for (let i = 0; i < assignment.configuration.quiz.length; i++) {
        const element = { ...assignment.configuration.quiz[i] }

        if (element.isSelfHosted) {
          const url = await getFileUrl(
            element.imageUrl,
            process.env.REACT_APP_ASSIGNMENT_UPLOADS_BUCKET_NAME
          )
          element.imageUrl = url
        }

        updatedQuiz.push(element)
      }

      setQuiz(updatedQuiz)
    }

    loadQuiz()
  }, [assignment])

  const handleSubmit = useCallback(
    async (answers) => {
      if (currentUser.isStudent) {
        // setFinished(true);
        const payload = {
          assignmentId: assignment.id,
          userId: currentUser.id,
          documentId: documentId,
          details: {
            answers: answers,
          },
          enrollmentId,
        }

        try {
          // Save submission
          await createSubmission(payload).unwrap()

          // Inform parent
          ParentInformer.sendSubmission(assignment)

          // Remove answers from local storage
          window.localStorage.removeItem(assignment.id)

          // Reload
          window.location.reload()
        } catch (err) {
          if (err.data && err.data.message === 'Submission already found') {
            setSubmissionError('submission-already-found')
          } else {
            setSubmissionError(err.data.message ?? 'unknown-error')
          }
        }
      }
    },
    [currentUser, assignment.uuid]
  )

  const editExam = () => {
    navigate(`/exam/${assignment.id}/edit?user_id=${currentUser.id}`, {
      state: { prev: location.search },
    })
  }

  let content
  if (quiz === null || isSavingExam) {
    content = <Loader inverted={theme.isDark} active />
  } else if (finished) {
    content = (
      <Flex>
        <StyledH3>Actividad terminada</StyledH3>
      </Flex>
    )
  } else if (submissionError === 'submission-already-found') {
    content = (
      <Flex>
        <StyledH3>Ya realizaste esta entrega</StyledH3>
      </Flex>
    )
  } else if (submissionError) {
    content = (
      <ErrorMessageWithReload
        title={'Ocurrió un error'}
        description={'¡Pero no te preocupes! Guardamos tu avance'}
        action="Reintentar entrega"
        onClick={handleSubmit}
      />
    )
  } else {
    content = (
      <Flex align="flex-start" justify="flex-start" column full>
        <Quiz
          assigment={assignment}
          done={submissionError === 'submission-already-found'}
          quiz={quiz}
          onSubmit={handleSubmit}
        />
        {!currentUser.isStudent && (
          <Button
            inverted={theme.isDark}
            disabled={
              (assignment?.configuration?.isSynchronous &&
                assignment?.configuration?.isOpen) ??
              false
            }
            onClick={editExam}
          >
            Editar examen
          </Button>
        )}
      </Flex>
    )
  }

  return content
}

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.text};
`
