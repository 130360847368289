import { Spinner } from '../../../LoadingSpinner'
import React from 'react'

const LoadingScript = () => {
  return (
    <div className="h-screen bg-slate-700  w-full flex justify-center items-center">
      <Spinner />
    </div>
  )
}

export default LoadingScript
