import styled from 'styled-components'
import File from '../assigments/FileUpload/File'

export default function CompletedFileSubmissionAssignment({ submission }) {
  if (!submission.details || !submission.details.files) return <></>

  return (
    <Files>
      {submission.details.files.map((file, index) => (
        <File
          key={index}
          url={file.file_url}
          file_name={file.file_name}
          file_type={file.file_type}
        />
      ))}
    </Files>
  )
}
const Files = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`
