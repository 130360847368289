import { useCallback, useEffect, useState } from 'react'
import { RunPythonCode } from '../services/runcode'

const useRunCode = (onFinish = () => {}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [executionResult, setExecutionResult] = useState(null)
  const [error, setError] = useState(null)

  const resetOutputs = useCallback(() => {
    setExecutionResult(null)
    setError(null)
  }, [])

  const execute = useCallback(
    async (code) => {
      resetOutputs()
      setIsLoading(true)
      try {
        const result = await RunPythonCode(code)
        setExecutionResult(result)
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [RunPythonCode, resetOutputs, onFinish]
  )

  useEffect(() => {
    onFinish?.({ executionResult, error })
  }, [onFinish, executionResult, error])

  return {
    isLoading,
    executionResult,
    error,
    resetOutputs,
    execute,
  }
}

export default useRunCode
