import useLoadScript from './hooks/useLoadScript'
import useAssignmentAdapter from './hooks/useAssignmentAdapter'

import CodeRunnerProvider from './context/CodeRunnerStore'

import Main from './Main'
import LoadScriptFailed from './components/LoadScriptFailed'
import LoadingScript from './components/LoadingScript'

import { PYODIDE_SCRIPT_ID, PYODIDE_SCRIPT_SRC } from './constants'

const Bootstrap = ({ assignment, documentId, submission }) => {
  const adaptedAssignment = useAssignmentAdapter({ assignment })

  const { isLoaded, isLoading } = useLoadScript(
    PYODIDE_SCRIPT_SRC,
    PYODIDE_SCRIPT_ID
  )

  if (isLoading) {
    return <LoadingScript />
  }

  if (!isLoaded) {
    return <LoadScriptFailed />
  }

  return (
    <CodeRunnerProvider assignment={adaptedAssignment} documentId={documentId}>
      <Main submission={submission} />
    </CodeRunnerProvider>
  )
}

export default Bootstrap
