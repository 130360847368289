import { useEffect, useLayoutEffect, useRef, useState, memo } from 'react'
import {
  CONSOLE_RESULTS_TYPES,
  EDITOR_BG_COLOR,
  MAX_TERMINAL_HEIGHT_PX,
} from '../constants'
import { useCodeRunnerStore } from '../hooks/useCodeRunnerStore'
import ConsoleResult from './ConsoleResult'
import ConsoleError from './ConsoleError'
import { AiOutlineClose } from 'react-icons/ai'

const TERMINAL_Y_MARGIN = 50

const Terminal = () => {
  const resizableRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [initialPos, setInitialPos] = useState(null)
  const [initialSize, setInitialSize] = useState(null)

  const { consoleResult } = useCodeRunnerStore()

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (consoleResult.content !== null) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [consoleResult.content, setOpen])

  const onResizeStart = (e) => {
    const resizable = resizableRef.current
    setInitialPos(e.clientY)
    setInitialSize(resizable.offsetHeight)
  }

  const onResize = (e) => {
    const newHeight = parseInt(initialSize) + parseInt(initialPos - e.clientY)

    if (newHeight <= TERMINAL_Y_MARGIN || newHeight >= MAX_TERMINAL_HEIGHT_PX) {
      // don't mutate height
      return
    }

    resizableRef.current.style.height = `${newHeight}px`
  }

  useLayoutEffect(() => {
    resizableRef.current.style.height = `280px`
  }, [])

  return (
    <div
      className={`w-full  flex flex-col absolute ${
        open ? 'flex' : 'hidden'
      } bottom-0  z-40 overflow-x-hidden `}
    >
      <div
        className="h-[4px] bg-[#2C2D3C] hover:h-[8px] hover:bg-[#30313f] cursor-ns-resize w-full z-50 transition-all ease-out duration-200"
        onDragStart={onResizeStart}
        onDrag={onResize}
        draggable={true}
      />
      <div
        ref={resizableRef}
        id="Resizable"
        className={`bg-[${EDITOR_BG_COLOR}] h-full flex flex-col    w-full  px-3`}
      >
        <header className="py-2 flex justify-between items-center pt-3 ">
          <ul className="m-0 p-0">
            <li className="list-none">
              <p className="text-white text-lg underline underline-offset-4">
                Terminal
              </p>
            </li>
          </ul>
          <button
            type="button"
            className="bg-transparent rounded-[8px] hover:cursor-pointer px-2 hover:bg-zinc-700 p-1 border-none text-white font-medium transition-all ease-in-out duration-150"
            onClick={onClose}
          >
            <AiOutlineClose className="text-slate-400" />
          </button>
        </header>
        <main className="px-3 mt-3 overflow-y-auto h-full text-white">
          <>
            {consoleResult.type === CONSOLE_RESULTS_TYPES.RESULT ? (
              <ConsoleResult />
            ) : (
              <ConsoleError />
            )}
          </>
        </main>
      </div>
    </div>
  )
}

export default memo(Terminal)
