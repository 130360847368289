import React from 'react'
import {
  Image,
  Input,
  Form,
  Button,
  List,
  Icon,
  Segment,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAssigment,
  selectAssigment,
  setReady,
} from '../../../store/assigmentCreationSlice'
import {
  selectConfiguration,
  setPollConfiguration,
} from '../../../store/pollCreationSlice'
import { PopoverPicker } from '../../../components/PopoverPicker'
import { v4 as uuidv4 } from 'uuid'
import styled, { useTheme } from 'styled-components'
import { defaultColors } from 'app/utils'

export default function PollConfig() {
  const dispatch = useDispatch()
  const assigment = useSelector(selectAssigment)
  const pollConfiguration = useSelector(selectConfiguration)
  const [options, setOptions] = React.useState([])
  const [newOption, setNewOption] = React.useState('')
  const theme = useTheme()

  const handleAddOption = () => {
    if (newOption.length > 0) {
      const optionsCopy = [...options]
      optionsCopy.push({ uuid: uuidv4(), value: newOption })
      setOptions(optionsCopy)
      setNewOption('')
      dispatch(setReady(true))

      let config = { ...assigment.configuration }
      config.poll = optionsCopy.map((option) => ({
        id: option.uuid,
        text: option.value,
        votes: 0,
      }))
      config.description = config.title
      dispatch(
        setAssigment({
          ...assigment,
          configuration: config,
        })
      )
    }
  }

  const handleInputChange = (e) => {
    setNewOption(e.target.value)
  }

  const handleRemoveOption = (i) => {
    const optionsCopy = [...options]
    optionsCopy.splice(i, 1)
    setOptions(optionsCopy)

    if (optionsCopy.length === 0) {
      dispatch(setReady(false))

      let config = { ...assigment.configuration }
      config.pollOptions = optionsCopy.map((option, index) => ({
        id: index,
        text: option,
        votes: 0,
      }))
      config.description = config.title
      dispatch(
        setAssigment({
          ...assigment,
          configuration: config,
        })
      )
    }
  }

  const handleLayoutChange = (value) => {
    dispatch(
      setPollConfiguration({
        ...pollConfiguration,
        layout: value,
      })
    )
  }

  const handleSubheadingChange = (e, data) => {
    dispatch(
      setPollConfiguration({
        ...pollConfiguration,
        subheading: data.value,
      })
    )
  }

  const handleColorChange = (option, newColor) => {
    let previousColors = { ...pollConfiguration.colors }
    previousColors[option.uuid] = newColor
    dispatch(
      setPollConfiguration({
        ...pollConfiguration,
        colors: previousColors,
      })
    )
  }

  const getOptionColor = (option, index) => {
    if (Object.hasOwn(pollConfiguration.colors, option.uuid)) {
      return pollConfiguration.colors[option.uuid]
    }

    const color = defaultColors[index % defaultColors.length]
    return `rgba(${color.r}, ${color.g}, ${color.b}, 1)`
  }

  return (
    <FormRow>
      {/* Subheading */}
      <Form style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}>
        <Subheader>Subtítulo:</Subheader>
        <StyledInput
          onChange={handleSubheadingChange}
          style={{ width: '100%' }}
          focus
          placeholder="Subtítulo"
        />
      </Form>
      {/* Estilo de la encuesta */}
      <Form style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Form.Field>
          <Subheader>Elige diseño de la encuesta:</Subheader>
        </Form.Field>
        <DesignOptions>
          <DesignContainer
            style={{ marginRight: '3rem' }}
            onClick={() => handleLayoutChange('default')}
          >
            <StyledSegment
              tertiary={pollConfiguration.layout === 'default'}
              compact
            >
              <Image
                src="https://i.ibb.co/tLv99wy/Screen-Shot-2022-10-28-at-1-13-30.png"
                size="small"
                wrapped
              />
            </StyledSegment>
            <DesignText>Simple</DesignText>
          </DesignContainer>
          <DesignContainer onClick={() => handleLayoutChange('game')}>
            <StyledSegment
              tertiary={pollConfiguration.layout === 'game'}
              compact
            >
              <Image
                src="https://i.ibb.co/FY0BNRQ/Screen-Shot-2022-10-26-at-9-50-29.png"
                size="small"
                wrapped
              />
            </StyledSegment>
            <DesignText>Bloques de colores</DesignText>
          </DesignContainer>
        </DesignOptions>
      </Form>
      {/* Opciones */}
      <Form style={{ width: '100%', display: 'flex' }}>
        <StyledInput
          style={{ flex: 'auto' }}
          value={newOption}
          placeholder="Agrega una opción"
          onInput={handleInputChange}
        />
        <Button
          inverted={theme.isDark}
          style={{ margin: '0' }}
          onClick={handleAddOption}
        >
          Agregar
        </Button>
      </Form>
      <List style={{ marginBottom: '2rem' }} divided verticalAlign="middle">
        {options.map((option, index) => (
          <List.Item
            style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            key={option.uuid}
          >
            <List.Content floated="right">
              <Icon
                color="red"
                onClick={() => handleRemoveOption(index)}
                style={{ margin: '0', cursor: 'pointer' }}
                name="trash alternate"
              />
            </List.Content>
            <List.Content style={{ display: 'flex', alignItems: 'center' }}>
              {pollConfiguration.layout === 'game' && (
                <PopoverPicker
                  color={getOptionColor(option, index)}
                  onChange={(color) => handleColorChange(option, color)}
                />
              )}
              <span style={{ marginLeft: '0.5rem' }}>{option.value}</span>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </FormRow>
  )
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
const DesignContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
const DesignText = styled.span`
  margin-left: 1rem;
`
const DesignOptions = styled.div`
  display: flex;
  flex-direction: flex-row;
`
const StyledInput = styled(Input)`
  input {
    background: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
const Subheader = styled.span`
  color: ${(props) => props.theme.textSecondary};
`
const StyledSegment = styled(Segment)`
  background: ${(props) => props.theme.sidebarBackground};
`
