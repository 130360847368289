export const convertFiles = (files) => {
  if (typeof files === 'string') {
    if (files.startsWith('"')) {
      files = JSON.parse(JSON.parse(files))
    } else {
      files = JSON.parse(files)
    }
    if (!Array.isArray(files) && files !== null && files !== undefined) {
      files = []
    }
  }
  return files?.map((file) => file)
}

export default convertFiles
