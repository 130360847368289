import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useTheme } from 'styled-components'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { convertFromHTML } from 'draft-convert'
import { Button } from 'semantic-ui-react'
import FileUploader from './FileUploader'
import uploadMultipleFiles from '../../../../utils/uploadMultipleFiles'

import moment from 'moment'

import {
  useUpdateForumPostMutation,
  useGetSubmissionByAssignmentIdAndUserIdQuery,
  useUpdateSubmissionMutation,
} from 'app/store/apiHubSlice'
import { useSearchParams } from 'react-router-dom'
import useCurrentUser from '../../Coding/hooks/useCurrentUser'
import { convertFiles } from 'app/utils/convertFiles'

const PostEditor = ({ post, setIsEditing, uploadUrl }) => {
  const [params] = useSearchParams()

  const [editorState, setEditorState] = useState(() => {
    try {
      return EditorState.createWithContent(convertFromHTML(post.content))
    } catch (e) {
      console.error(e)
      return EditorState.createEmpty()
    }
  })
  const user = useCurrentUser()

  const { data: existingSubmission, isLoading: gettingExistingSubmission } =
    useGetSubmissionByAssignmentIdAndUserIdQuery({
      userId: user.id,
      assignmentId: params.get('assigment_id') ?? '',
    })
  // Aquí falta enrollmentId: Number(params.get('enrollmentId') ?? 0),

  const [newPost, setNewPost] = React.useState({ title: '', content: '' })
  const [postFiles, setPostFiles] = useState([
    ...(convertFiles(post.files) || []),
  ])

  const theme = useTheme()

  const isSameUser = user.id === post?.user?.id

  const handleEditorChanged = (state) => {
    setEditorState(state)
    setNewPost({
      ...newPost,
      content: draftToHtml(convertToRaw(state.getCurrentContent())),
    })
  }
  const [updatePost, { isLoading: isSavingPost }] = useUpdateForumPostMutation()
  const [updateSubmissionMutation, { isLoading: isSavingSubmission }] =
    useUpdateSubmissionMutation()

  const getNewFiles = async () => {
    const newFiles = postFiles.filter((file) => !file.file_url)
    const oldFiles = postFiles.filter((file) => file.file_url)
    const uploadedFiles = await uploadMultipleFiles(newFiles, uploadUrl)
    return oldFiles.concat(uploadedFiles)
  }

  const update = async () => {
    // If the user is not the same as the post's author, return
    if (!isSameUser) {
      return
    }

    const newEditHistory = {
      editedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      content: newPost.content,
    }
    const editHistory = [...post.editHistory, newEditHistory]

    const newFiles = await getNewFiles()
    const updatedPost = {
      id: post.id,
      content: newPost.content || post.content,
      editHistory,
      files: newFiles,
    }

    await updatePost(updatedPost)

    if (existingSubmission?.data) {
      await updateSubmissionMutation({
        id: existingSubmission.data.id,
        details: {
          forumPost: updatedPost,
        },
      })
    }

    setNewPost({
      ...post,
      ...newPost,
    })

    setIsEditing(false)
  }

  const isSaving = isSavingPost || isSavingSubmission

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChanged}
        toolbar={{
          options: ['inline', 'blockType', 'list'],
          blockType: {
            inDropdown: false,
          },
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
        placeholder="Escribe aquí..."
        editorStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
        toolbarStyle={{
          backgroundColor: theme.sidebarBackground,
          color: theme.text,
          border: `1px solid ${theme.cardBorder}`,
          borderRadius: '4px',
        }}
      />
      <FileUploader myFiles={postFiles} setMyFiles={setPostFiles} />
      {/* Cancel */}
      <Button
        disabled={isSaving}
        style={{ marginBottom: '1rem' }}
        onClick={() => {
          setIsEditing(false)
        }}
        size="tiny"
        basic
      >
        Cancelar
      </Button>
      {/* Save */}
      <Button
        // loading={isSaving}
        disabled={gettingExistingSubmission || isSaving || !isSameUser}
        style={{ marginBottom: '1rem' }}
        onClick={() => {
          update()
        }}
        size="tiny"
        primary
      >
        Guardar
      </Button>
    </div>
  )
}
export default PostEditor
