import { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import Flex from './containers/Flex'
import {
  useGetSubmissionsQuery,
  useGetTypeformInfoQuery,
  useDownloadTypeformFileMutation,
} from '../store/apiHubSlice'
import { selectCurrentUser } from '../store/authSlice'
// import { selectCourseId } from '../store/uiSlice'
import {
  Table,
  Button,
  Loader,
  Label,
  Icon,
  Popup,
  Search,
  Grid,
  GridColumn,
} from 'semantic-ui-react'
import timeAgo from '../utils/timeAgo'
import { useDispatch, useSelector } from 'react-redux'
import CompletedQuizAssignment from './CompletedAssignments/CompletedQuizAssignment'
import CompletedTypeformAssignment from './CompletedAssignments/CompletedTypeformAssignment'
import {
  setSelectedExam,
  selectSelectedExam,
  selectSelectedSubmission,
  setSelectedSubmission,
  selectEnrollmentGroupId,
} from '../store/uiSlice'
import Avatar from './shared/Avatar'
import { LeafPoll } from 'react-leaf-polls-copy'
import File from './assigments/FileUpload/File'
import 'react-leaf-polls-copy/dist/index.css'
import LoaderContainer from './containers/LoaderContainer'
import moment from 'moment-timezone'
import { DragAndDrop } from './assigments/DragAndDrop'
import SubmittedCodingAssignment from './assigments/Coding/SubmittedCodingAssignment'
import Fuse from 'fuse.js'
import CompletedFileSubmissionAssignment from './CompletedAssignments/CompletedFileSubmissionAssignment'
import ModalRemoveSubmission from './ModalRemoveSubmission'
import { useDebounce } from '@uidotdev/usehooks'

//import Coding from './assigments/Coding'
const customTheme = {
  textColor: 'black',
  mainColor: '#00B87B',
  backgroundColor: 'rgb(255,255,255)',
  alignment: 'center',
}

function AnswerCell({ submission, responses }) {
  const [fetchingFile, setFetchingFile] = useState(null)
  const [downloadTypeformFile] = useDownloadTypeformFileMutation()

  const onDownloadFileClicked = async (fileUrl) => {
    setFetchingFile(fileUrl)

    try {
      const blob = await downloadTypeformFile(fileUrl)
      const url = window.URL.createObjectURL(new Blob([blob.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = fileUrl.split('/').pop()
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading file', error)
    } finally {
      setFetchingFile(false)
    }
  }

  try {
    // Find student response
    const foundStudentResponse = responses.find(
      (r) =>
        r.response_id === submission.details.responseId ||
        r.response_id === submission.details.response_id
    )
    const studentAnswers = foundStudentResponse
      ? foundStudentResponse.answers
      : submission.details.answers

    return studentAnswers.map((item, index) => {
      let _answer
      switch (item.type) {
        case 'choice':
          _answer = item.choice.label
          break
        case 'choices':
          _answer = item.choices.labels.join(', ')
          break
        case 'boolean':
          _answer = item.boolean ? 'Sí' : 'No'
          break
        case 'file_url':
          _answer = (
            <Button
              basic
              icon="download"
              loading={fetchingFile === item.file_url}
              onClick={() => onDownloadFileClicked(item.file_url)}
            />
          )
          break
        default:
          _answer = item[item['type']] || ''
          break
      }
      return <Table.Cell key={index}>{_answer}</Table.Cell>
    })
  } catch (error) {
    return <Table.Cell />
  }
}

export default function AssigmentSummary({ assignment }) {
  const ELEMENTS_PER_PAGE = 15
  let dispatch = useDispatch()
  const selectedExam = useSelector(selectSelectedExam)
  const enrollmentGroupId = useSelector(selectEnrollmentGroupId)
  const currentUser = useSelector(selectCurrentUser)
  const selectedSubmission = useSelector(selectSelectedSubmission)
  const [selectedItem, setSelectedItem] = useState(selectedSubmission)
  // const courseId = useSelector(selectCourseId)
  const [submissions, setSubmissions] = useState([])
  const [filteredSubmissions, setFilteredSubmissions] = useState([])
  const [typeformData, setTypeformData] = useState(null)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  const {
    isLoading,
    refetch,
    isFetching,
    data: submissionData,
  } = useGetSubmissionsQuery({
    assignmentId: assignment.id,
    includes: [
      'user',
      { name: 'enrollment', required: true, enrollmentGroupId },
    ],
    page: page,
    limit: ELEMENTS_PER_PAGE,
    query: debouncedSearchQuery,
  })
  const theme = useTheme()

  const { data: typeformInfo, isLoading: isLoadingTypeformFields } =
    useGetTypeformInfoQuery(
      {
        formId: assignment.configuration.form_id,
      },
      { skip: assignment.assignmentType.slug !== 'typeform' }
    )

  useEffect(() => {
    if (typeformInfo) {
      setTypeformData(typeformInfo.data)
    }
    if (submissionData) {
      setSubmissions(submissionData)
      setFilteredSubmissions(submissionData)
    }
  }, [submissionData, typeformInfo])

  const handleSearchChange = (e, data) => {
    setPage(1)
    const searchValue = data.value
    setSearchQuery(searchValue)
  }

  const [open, setOpen] = useState(false)
  const handleOpenModal = (item) => {
    dispatch(setSelectedSubmission(item))
    setOpen(true)
  }
  const handleCloseModal = () => setOpen(false)

  const handleViewSubmission = (item) => {
    dispatch(setSelectedSubmission(item))
    setSelectedItem(item)
  }

  const handleNextPage = () => {
    setPage(page + 1)
  }
  const handlePrevPage = () => {
    setPage(page - 1)
  }
  const searchBar = (
    <Search
      style={{ marginTop: '1rem' }}
      placeholder="Busca un estudiante"
      showNoResults={false}
      onSearchChange={handleSearchChange}
    />
  )
  let requiredPagination = true
  let content

  if (isLoading || isLoadingTypeformFields) {
    content = (
      <LoaderContainer>
        <Loader active />
      </LoaderContainer>
    )
  } else if (
    assignment.assignmentType.slug === 'typeform' ||
    assignment.assignmentType.slug === 'typeform_quiz'
  ) {
    if (submissions?.length === 0) {
      content = (
        <div style={{ width: '100%' }}>
          <Text>No submissions</Text>
        </div>
      )
    } else {
      content =
        selectedItem === null ? (
          <>
            {searchBar}
            <Table inverted={theme.isDark} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Estudiante</Table.HeaderCell>
                  <Table.HeaderCell>Fecha</Table.HeaderCell>
                  {typeformData?.fields?.map((item) => (
                    <Table.HeaderCell key={item.id}>
                      {item.title}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredSubmissions.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {item.user.givenName} {item.user.familyName1}{' '}
                    </Table.Cell>
                    <Table.Cell>
                      {' '}
                      {item.createdAt
                        ? timeAgo.format(Date.parse(item.createdAt))
                        : ''}{' '}
                    </Table.Cell>
                    <AnswerCell
                      submission={item}
                      responses={typeformData?.items}
                    />
                    <Table.Cell>
                      {item.grade !== null ? (
                        <Flex justify="space-between">
                          <Label
                            circular
                            style={{
                              marginLeft: '5px',
                              paddingLeft: '10px !important',
                              paddingRight: '10px !important',
                            }}
                            color={item.grade > 5 ? 'green' : 'red'}
                            size="large"
                          >
                            <span
                              style={{
                                margin: 0,
                                padding: 0,
                                marginLeft: '10px',
                                marginRight: '10px',
                                color: 'white',
                              }}
                            >
                              {item.grade}
                            </span>
                          </Label>
                          <Button
                            onClick={() => handleViewSubmission(item)}
                            circular
                            size="tiny"
                            icon="eye"
                          />
                          <Popup
                            header="Borrar"
                            trigger={
                              <Button
                                onClick={() => handleOpenModal(item)}
                                circular
                                size="tiny"
                                icon="trash alternate outline"
                              />
                            }
                          />
                        </Flex>
                      ) : (
                        <Flex justify="space-between">
                          <Button
                            onClick={() => handleViewSubmission(item)}
                            color="yellow"
                          >
                            Calificar
                          </Button>
                          <Popup
                            header="Borrar"
                            trigger={
                              <Button
                                onClick={() => handleOpenModal(item)}
                                circular
                                size="tiny"
                                icon="trash alternate outline"
                              />
                            }
                          />
                        </Flex>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        ) : (
          <CompletedTypeformAssignment />
        )
    }
  } else if (selectedExam !== null) {
    content = (
      <div style={{ width: '100%', padding: '1rem', paddingTop: '0' }}>
        <HeadingCenter>{assignment.title}</HeadingCenter>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <AvatarWrapper>
            <Avatar
              src={selectedExam.submission.user.avatarUrl}
              size={45}
              showBorder={false}
            />{' '}
          </AvatarWrapper>
          <Heading style={{ marginLeft: '0.5rem' }}>
            {selectedExam.user.givenName} {selectedExam.user.familyName1}{' '}
          </Heading>
        </div>
        <CompletedQuizAssignment
          assignment={assignment}
          submission={selectedExam.submission}
          student={selectedExam.student}
        />
      </div>
    )
  } else if (assignment.assignmentType.slug === 'exam') {
    content = (
      <>
        {searchBar}
        <Table inverted={theme.isDark} celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Popup header="Estudiante" trigger={<Icon name="student" />} />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Popup
                  header="Fecha de entrega"
                  trigger={<Icon name="calendar outline" />}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Popup
                  header="Calificación"
                  trigger={<Icon name="clipboard check" />}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredSubmissions.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  {item.user?.name ||
                    `${item.user?.givenName} ${item.user?.familyName1}`}
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  {item.createdAt
                    ? moment(item.createdAt).format('DD MMM YYYY, h:mm a')
                    : ''}{' '}
                </Table.Cell>
                <Table.Cell>
                  {item.grade !== null ? (
                    <Flex justify="space-between">
                      <Label
                        circular
                        style={{
                          marginLeft: '5px',
                          paddingLeft: '10px !important',
                          paddingRight: '10px !important',
                        }}
                        color={item.grade >= 6 ? 'green' : 'red'}
                        size="large"
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            marginLeft: '10px',
                            marginRight: '10px',
                            color: 'white',
                          }}
                        >
                          {item.grade}
                        </span>
                      </Label>
                      <Button
                        onClick={() =>
                          dispatch(
                            setSelectedExam({
                              submission: item,
                              quiz: assignment.configuration.quiz,
                              user: item.user,
                            })
                          )
                        }
                        circular
                        icon="eye"
                        style={{
                          display: 'flex',
                          height: '32px',
                          width: '32px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                      <Popup
                        header="Borrar"
                        trigger={
                          <Button
                            onClick={() => handleOpenModal(item)}
                            circular
                            size="tiny"
                            icon="trash alternate outline"
                          />
                        }
                      />
                    </Flex>
                  ) : (
                    <Flex justify="space-between">
                      <Label
                        circular
                        style={{ marginLeft: '5px' }}
                        color="yellow"
                        size="large"
                      >
                        Pendiente
                      </Label>
                      <Button
                        onClick={() =>
                          dispatch(
                            setSelectedExam({
                              submission: item,
                              quiz: assignment.configuration.quiz,
                              user: item.user,
                            })
                          )
                        }
                        circular
                        icon="eye"
                        style={{
                          display: 'flex',
                          height: '32px',
                          width: '32px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                      <Popup
                        header="Borrar"
                        trigger={
                          <Button
                            onClick={() => handleOpenModal(item)}
                            circular
                            size="tiny"
                            icon="trash alternate outline"
                          />
                        }
                      />
                    </Flex>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  } else if (assignment.assignmentType.slug === 'poll') {
    const poll = assignment.configuration.poll.map((op) => ({
      id: op.id,
      text: op.text,
      votes: op.votes,
    }))
    // Doesnt show pagination
    requiredPagination = false

    content = (
      <PollContainer>
        <LeafPoll
          type="multiple"
          question={assignment.title}
          results={poll}
          theme={customTheme}
          isVoted={true}
        />
      </PollContainer>
    )
  } else if (assignment.assignmentType.slug === 'file_upload') {
    content =
      selectedItem === null ? (
        <>
          {searchBar}
          <Table inverted={theme.isDark} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Student</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Files</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {filteredSubmissions.map((item) => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.user.givenName} {item.user.familyName1}{' '}
                  </Table.Cell>
                  <Table.Cell>
                    {' '}
                    {item.createdAt
                      ? timeAgo.format(Date.parse(item.createdAt))
                      : ''}{' '}
                  </Table.Cell>
                  <Table.Cell style={{ display: 'flex !important' }}>
                    {item.details.files !== null ? (
                      <Flex justify="space-between">
                        <Files>
                          {item.details.files.map((file, index) => (
                            <File
                              key={index}
                              url={file.file_url}
                              file_name={file.file_name}
                              file_type={file.file_type}
                            />
                          ))}
                        </Files>
                        <Button
                          onClick={() => handleViewSubmission(item)}
                          circular
                          icon="eye"
                          style={{
                            display: 'flex',
                            height: '32px',
                            width: '32px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                        <Popup
                          header="Borrar"
                          trigger={
                            <Button
                              onClick={() => handleOpenModal(item)}
                              circular
                              size="tiny"
                              icon="trash alternate outline"
                            />
                          }
                        />
                      </Flex>
                    ) : (
                      <Flex justify="space-between">
                        <Button
                          onClick={() => handleViewSubmission(item)}
                          color="yellow"
                        >
                          Calificar
                        </Button>
                        <Popup
                          header="Borrar"
                          trigger={
                            <Button
                              onClick={() => handleOpenModal(item)}
                              circular
                              size="tiny"
                              icon="trash alternate outline"
                            />
                          }
                        />
                      </Flex>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <CompletedFileSubmissionAssignment submission={selectedItem} />
      )
  } else if (assignment.assignmentType.slug === 'drag_n_drop') {
    content = (
      <>
        {searchBar}
        <Table inverted={theme.isDark} celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Student</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Answers</Table.HeaderCell>
              <Table.HeaderCell>Grade</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredSubmissions.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  {item.user.givenName} {item.user.familyName1}{' '}
                </Table.Cell>
                <Table.Cell>
                  {' '}
                  {item.createdAt
                    ? timeAgo.format(Date.parse(item.createdAt))
                    : ''}{' '}
                </Table.Cell>
                <Table.Cell style={{ display: 'flex !important' }}>
                  <DragAndDrop assignment={assignment} submission={item} />
                </Table.Cell>
                <Table.Cell>
                  {item.grade !== null ? (
                    <Flex justify="space-between">
                      <Label
                        circular
                        style={{
                          marginLeft: '5px',
                          paddingLeft: '10px !important',
                          paddingRight: '10px !important',
                        }}
                        color={item.grade > 5 ? 'green' : 'red'}
                        size="large"
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            marginLeft: '10px',
                            marginRight: '10px',
                            color: 'white',
                          }}
                        >
                          {item.grade}
                        </span>
                      </Label>
                      <Popup
                        header="Borrar"
                        trigger={
                          <Button
                            onClick={() => handleOpenModal(item)}
                            circular
                            size="tiny"
                            icon="trash alternate outline"
                          />
                        }
                      />
                    </Flex>
                  ) : (
                    <Flex justify="space-between">
                      <Label
                        circular
                        style={{ marginLeft: '5px' }}
                        color="yellow"
                        size="large"
                      >
                        Pendiente
                      </Label>
                      <Popup
                        header="Borrar"
                        trigger={
                          <Button
                            onClick={() => handleOpenModal(item)}
                            circular
                            size="tiny"
                            icon="trash alternate outline"
                          />
                        }
                      />
                    </Flex>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    )
  } else if (assignment.assignmentType.slug === 'coding_assignment') {
    content =
      selectedItem === null ? (
        <>
          {searchBar}
          <Table unstackable inverted={theme.isDark} celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Student</Table.HeaderCell>
                <Table.HeaderCell>Submission Date</Table.HeaderCell>
                <Table.HeaderCell>Grade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {filteredSubmissions.map((item) => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Label style={{ marginRight: '10px' }}>
                      {item.user.studentId}
                    </Label>
                    {`${item.user.givenName} ${item.user.familyName1}`}
                  </Table.Cell>
                  <Table.Cell>
                    {/* show item.createdAt in format 24/ago/2023 14:53 */}
                    {moment(item.createdAt).format('D MMM YYYY HH:mm a')}
                    {'    '}
                    <Label>
                      {item.createdAt
                        ? timeAgo.format(Date.parse(item.createdAt))
                        : ''}{' '}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {item.grade !== null ? (
                      <Flex justify="space-between">
                        <Label
                          circular
                          style={{
                            marginLeft: '5px',
                            paddingLeft: '10px !important',
                            paddingRight: '10px !important',
                          }}
                          color={item.grade > 5 ? 'green' : 'red'}
                          size="large"
                        >
                          <span
                            style={{
                              margin: 0,
                              padding: 0,
                              marginLeft: '10px',
                              marginRight: '10px',
                              color: 'white',
                            }}
                          >
                            {item.grade}
                          </span>
                        </Label>
                        <Button
                          onClick={() => handleViewSubmission(item)}
                          circular
                          size="tiny"
                          icon="eye"
                        />
                        <Popup
                          header="Borrar"
                          trigger={
                            <Button
                              onClick={() => handleOpenModal(item)}
                              circular
                              size="tiny"
                              icon="trash alternate outline"
                            />
                          }
                        />
                      </Flex>
                    ) : (
                      <Flex justify="space-between">
                        <Button
                          onClick={() => handleViewSubmission(item)}
                          color="yellow"
                        >
                          Calificar
                        </Button>
                        <Popup
                          header="Borrar"
                          trigger={
                            <Button
                              onClick={() => handleOpenModal(item)}
                              circular
                              size="tiny"
                              icon="trash alternate outline"
                            />
                          }
                        />
                      </Flex>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <SubmittedCodingAssignment />
      )
  } else {
    content = (
      <Text>
        Hola, {currentUser.name}. Aquí verás un resumen ejecutivo de las
        entregas de los estudiantes de esta actividad de tipo{' '}
        {assignment.assignmentType.displayText}.
      </Text>
    )
  }

  return (
    <Flex full column justify={'flex-start'} align="flex-start">
      <Flex full align="center">
        {selectedExam !== null && (
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => dispatch(setSelectedExam(null))}
            inverted={theme.isDark}
            basic
          >
            Regresar
          </Button>
        )}
      </Flex>
      {selectedItem === null && (
        <FloatLeft>
          <Flex full align="center">
            <Button
              icon="refresh"
              loading={isFetching}
              onClick={refetch}
            ></Button>
          </Flex>
        </FloatLeft>
      )}
      {content}
      {requiredPagination && (
        <div style={{ width: '100%' }}>
          <Button
            icon
            basic
            floated="left"
            onClick={handlePrevPage}
            style={{ marginLeft: '4px' }}
            disabled={page === 1}
            loading={isFetching}
          >
            <Icon name="angle left" />
          </Button>
          <Button
            icon
            basic
            floated="right"
            style={{ marginRight: '4px' }}
            onClick={handleNextPage}
            loading={isFetching}
            disabled={submissionData?.length < ELEMENTS_PER_PAGE}
          >
            <Icon name="angle right" />
          </Button>
        </div>
      )}
      <ModalRemoveSubmission open={open} handleClose={handleCloseModal} />
    </Flex>
  )
}
const FloatLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`
const HeadingCenter = styled.h1`
  font-size: 2em;
  margin: 15px;
  margin-top: 15px;
  text-align: center;
  color: ${(props) => props.theme.text};
`
const Heading = styled.div`
  font-size: 1.5em;
  margin: 15px;
  margin-top: 15px;
  color: ${(props) => props.theme.textSecondary};
`
const Text = styled.p`
  font-size: 1.3em;
  text-align: center;
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const PollContainer = styled.div`
  padding: 2rem;
  padding-top: 4rem;
  width: 100%;
`
const Files = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HoverRow = styled(Table.Row)`
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.secondaryBackground};
  }
`
