import { Tldraw } from '@tldraw/tldraw'
import * as React from 'react'
import { useCreateElementMutation } from '../store/apiHubSlice'
import { RoomProvider } from '../components/board/liveblocks.config'
import { useMultiplayerState } from '../components/board/useMultiplayerState'
import uploadFile from '../utils/uploadFile'
import getFileUrl from '../utils/getFileUrl'
import { selectCurrentUser } from '../store/authSlice'
import { useSelector } from 'react-redux'
import Loader from 'app/components/shared/Loader'

export default function Board({ boardId, element, elementNotFound }) {
  const currentUser = useSelector(selectCurrentUser)
  const [createElement] = useCreateElementMutation()

  // Create board
  React.useEffect(() => {
    const createBoard = async () => {
      await createElement({
        id: boardId,
        type: 'board',
        config: {
          boardId
        }
      }).unwrap()
      window.location.reload()
    }

    if (!currentUser.isStudent && elementNotFound) {
      createBoard()
    }
  }, [])

  if (!boardId) {
    return <div>Invalid parameters</div>
  }

  let content = <></>

  if (boardId) {
    if (elementNotFound) {
      if (currentUser.isStudent) {
        content = <h1>Aún no terminan de crear esta actividad</h1>
      } else {
        content = <Loader />
      }
    } else if (element) {
      const { boardId, boardUuid } = element.config

      content = (
        <RoomProvider id={boardId ?? boardUuid}>
          <Editor roomId={boardId ?? boardUuid} />
        </RoomProvider>
      )
    } else {
      content = (
        <div>
          <h1>Error</h1>
        </div>
      )
    }
  }
  return content
}

function Editor({ roomId }) {
  const { error, ...events } = useMultiplayerState(roomId)
  if (error) return <div>Error: {error.message}</div>
  return (
    <div className="tldraw" style={{ minHeight: '600px' }}>
      <Tldraw
        showPages={false}
        {...events}
        autofocus={false}
        disableAssets={false}
        onAssetCreate={async (_, file, _id) => {
          const f = await uploadFile(
            file,
            `${roomId}/${_id}`,
            process.env.REACT_APP_BOARD_BUCKET_NAME
          )
          const tempUrl = await getFileUrl(
            f.file_url,
            process.env.REACT_APP_BOARD_BUCKET_NAME
          )
          return tempUrl
        }}
      />
    </div>
  )
}
