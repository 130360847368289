import { useDeleteForumPostMutation } from 'app/store/apiHubSlice'
import {
  selectSelectedPost,
  selectSelectedSubmission,
  setSelectedPost,
} from 'app/store/uiSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Header, Icon, Button } from 'semantic-ui-react'

const DeletedPost = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const selectedSubmission = useSelector(selectSelectedSubmission)
  const selectedPost = useSelector(selectSelectedPost)
  const [deletePost, { isLoading: isDeleting }] = useDeleteForumPostMutation()

  const handleDelete = async () => {
    await deletePost({
      id: selectedPost,
      submissionId: selectedSubmission.id,
    }).unwrap()
    dispatch(setSelectedPost(null))
    handleClose()
  }

  return (
    <Modal basic onClose={handleClose} open={open} size="small">
      <Header icon>
        <Icon name="warning sign" color="yellow" />
        Eliminar entrega
      </Header>
      <Modal.Content>
        <p className="text-center text-lg text-white">
          Estás a punto de eliminar la entrega de "
          {selectedSubmission?.user?.name}". ¿Deseas continuar?
        </p>
      </Modal.Content>
      <Modal.Actions className="flex justify-center">
        <Button basic inverted onClick={handleClose}>
          <Icon name="remove" /> Cancelar
        </Button>
        <Button
          loading={isDeleting}
          color="red"
          inverted
          onClick={handleDelete}
        >
          <Icon name="checkmark" /> Eliminar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DeletedPost
