import React from 'react'
import styled from 'styled-components'
import { Input } from 'semantic-ui-react'
import {
  setAssigment,
  selectAssigment,
  setReady,
  selectReady,
} from '../../../store/assigmentCreationSlice'
import { useDispatch, useSelector } from 'react-redux'
export default function FileUploadConfig() {
  let dispatch = useDispatch()
  const assigment = useSelector(selectAssigment)
  const ready = useSelector(selectReady)
  React.useEffect(() => {
    if (Object.keys(assigment.configuration).length === 0) {
      dispatch(
        setAssigment({
          ...assigment,
          configuration: {
            instructions: '',
            isSynchronous: assigment.configuration.isSynchronous,
          },
        })
      )
      dispatch(setReady(true))
    }
    if (!ready) {
      dispatch(setReady(true))
    }
  }, [assigment.configuration, ready])

  const handleChange = (e) => {
    dispatch(
      setAssigment({
        ...assigment,
        configuration: {
          instructions: e.target.value,
        },
      })
    )
  }

  let content = (
    <FormRow>
      <div>
        <span>Instrucciones</span>
      </div>
      <div>
        <StyledInput
          placeholder="Sube tu documento..."
          style={{ width: '100%' }}
          value={assigment.configuration.instructions || ''}
          onChange={handleChange}
        />
      </div>
    </FormRow>
  )

  return content
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 10px;
`
const StyledInput = styled(Input)`
  input {
    background: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
