import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Icon, Button } from 'semantic-ui-react'
import { useToggleForumPostLikeMutation } from 'app/store/apiHubSlice'

import { selectCurrentUser } from '../../../../store/authSlice'
import { useSelector } from 'react-redux'
import { selectViewOnly } from 'app/store/uiSlice'

export default function PostActions({ post, onAddComment, color = '#000' }) {
  const [toggleForumPostLike] = useToggleForumPostLikeMutation()
  const [likes, setLikes] = useState([])
  const currentUser = useSelector(selectCurrentUser)
  const viewOnly = useSelector(selectViewOnly)

  useEffect(() => {
    setLikes(
      post.engagement && post.engagement.likes ? post.engagement.likes : []
    )
  }, [post])

  const toggleLike = async (e) => {
    e.stopPropagation()

    try {
      const { data: updatedPost } = await toggleForumPostLike({
        postId: post.id,
        userId: currentUser.id,
      }).unwrap()

      setLikes(updatedPost.engagement.likes)
    } catch (error) {
      console.log(error)
    }
  }

  const onComment = (e) => {
    e.stopPropagation()
    if (onAddComment) {
      onAddComment(post)
    }
  }

  return (
    <ActionsContainer>
      <Button
        disabled={currentUser.id === post.user?.id || viewOnly}
        as="div"
        labelPosition="right"
        onClick={toggleLike}
        style={{ color }}
      >
        <LikeButton
          name={
            likes.findIndex((like) => like.userId === currentUser.id) != -1
              ? 'heart'
              : 'heart outline'
          }
          liked={
            likes.findIndex((like) => like.userId === currentUser.id) != -1
              ? 1
              : undefined
          }
        />
        <Counter style={{ color }}>{likes ? likes.length : '0'}</Counter>
      </Button>
      <Button
        as="div"
        labelPosition="right"
        onClick={onComment}
        style={{ marginLeft: '1rem', color }}
        disabled={viewOnly}
      >
        <Icon name="comment outline" />
        <Counter style={{ color }}>
          {post.engagement && post.engagement.comments
            ? post.engagement.comments.length
            : '0'}
        </Counter>
      </Button>
    </ActionsContainer>
  )
}

const ActionsContainer = styled.div``
const Counter = styled.span`
  font-size: 0.8rem;
`
const LikeButton = styled(Icon)`
  color: ${(props) => (props.liked ? '#ed4956' : '')};
`
