import styled from 'styled-components'

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  input {
    background-color: ${(props) => props.theme.sidebarBackground} !important;
    color: ${(props) => props.theme.text} !important;
  }
`
