import React from 'react'
import styled from 'styled-components'
import Flex from '../../containers/Flex'
import uploadFile from '../../../utils/uploadFile'
import {
  useCreateSubmissionMutation,
  useUpdateSubmissionMutation,
} from '../../../store/apiHubSlice'
import { selectCurrentUser } from '../../../store/authSlice'
import { selectEnrollmentId, selectViewOnly } from 'app/store/uiSlice'
import { useSelector } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { useDropzone } from 'react-dropzone'
import File from './File'
import SubmissionDetails from 'app/components/SubmissionDetails'
import SubmissionFeedback from 'app/elements/SubmissionFeedback'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export default function FileUpload({ assigment, documentUuid, submission }) {
  const submissionFiles = submission ? submission.details.files : null
  const instructions =
    typeof assigment.configuration === 'string'
      ? JSON.parse(assigment.configuration.replaceAll('=>', ':')).configuration
          .instructions
      : assigment.configuration.instructions
  const [uploading, setUploading] = React.useState(false)
  const [createSubmission] = useCreateSubmissionMutation()
  const [updateSubmission] = useUpdateSubmissionMutation()
  const currentUser = useSelector(selectCurrentUser)
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone()
  const [errorMessage, setErrorMessage] = React.useState(null)
  const enrollmentId = useSelector(selectEnrollmentId)
  const viewOnly = useSelector(selectViewOnly)

  const onSubmit = () => {
    if (acceptedFiles.length > 0 && currentUser.isStudent) {
      ;(async () => {
        setErrorMessage(null)
        setUploading(true)
        const filesUploadResponses = []

        // Try to upload files
        try {
          for (let i = 0; i < acceptedFiles.length; i++) {
            const fileUploadResponse = await uploadFile(
              acceptedFiles[i],
              `assigments/${assigment.id}/${currentUser.id}`
            )

            if (!fileUploadResponse.file_url) {
              throw new Error('Ocurrió un error al subir tu archivo.')
            }

            filesUploadResponses.push(fileUploadResponse)
          }
        } catch (err) {
          setErrorMessage('Ocurrió un error al subir tu archivo.')
          setUploading(false)
          return
        }

        // Try to create submission
        try {
          if (!submissionFiles) {
            const submission = {
              assignmentId: assigment.id,
              userId: currentUser.id,
              documentId: documentUuid,
              details: {
                files: filesUploadResponses,
              },
              enrollmentId,
            }
            await createSubmission(submission).unwrap()
          } else {
            await updateSubmission({
              id: submission.id,
              details: {
                files: filesUploadResponses,
              },
            }).unwrap()
          }

          window.location.reload()
        } catch (err) {
          setErrorMessage('Ocurrió un error al publicar tus archivos.')
          console.log(err)
        }

        setUploading(false)
      })()
    }
  }

  const acceptedFileItems = acceptedFiles.map((file, index) => {
    return (
      <File
        key={index}
        url={file.path}
        file_name={file.path}
        size={file.size}
      />
    )
  })

  const message = JSON.stringify({
    assigment_frame_height: '210',
  })

  window.parent.postMessage(message, '*')

  return (
    <Flex column full>
      {/* Submission details */}
      {submission && (
        <>
          <SubmissionDetails submission={submission} />
          <br />
        </>
      )}
      {/* Content */}
      <div>
        <Title>{assigment.title}</Title>
        <Description>{instructions}</Description>
        {acceptedFileItems.length > 0 && <Files>{acceptedFileItems}</Files>}
        {submissionFiles &&
          submissionFiles.length > 0 &&
          acceptedFiles.length === 0 && (
            <Files>
              {submissionFiles.map((file, index) => (
                <File
                  key={index}
                  url={file.file_url}
                  file_name={file.file_name}
                />
              ))}
            </Files>
          )}
        <div>
          <Container
            {...getRootProps({
              isFocused,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <DropzoneText>Click or drag files to this area</DropzoneText>
          </Container>
          <SendButtonContainer>
            <Button
              content={
                submissionFiles && submissionFiles.length > 0
                  ? 'Update'
                  : 'Send'
              }
              primary
              disabled={uploading || viewOnly}
              loading={uploading}
              onClick={onSubmit}
            />
            {errorMessage && (
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '0.5rem',
                }}
              >
                <small style={{ color: 'red' }}>{errorMessage}</small>
              </div>
            )}
          </SendButtonContainer>
        </div>
      </div>
      {/* submission feeedback */}
      {submission && assigment.configuration?.requiresFeedback && (
        <SubmissionFeedback />
      )}
    </Flex>
  )
}

const Title = styled.p`
  font-size: 2em;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 800;
  color: ${(props) => props.theme.text};
  width: 100%;
`
const Description = styled.p`
  font-size: 1em;
  margin-bottom: 3rem;
  text-align: center;
  color: ${(props) => props.theme.textSecondary};
  width: 100%;
`
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${(props) => props.theme.sidebarBackground};
  outline: none;
  transition: border 0.24s ease-in-out;
`
const DropzoneText = styled.p`
  color: ${(props) =>
    props.theme.isDark ? props.theme.textTertiary : '#bdbdbd'};
`
const SendButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const Files = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`
