import { useEffect, useState } from 'react'

const useLoadScript = (scriptSrc = '', scriptId = '') => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const existingScript = document.getElementById(scriptId)

    if (!existingScript) {
      const script = document.createElement('script')
      script.src = scriptSrc
      script.id = scriptId

      document.body.appendChild(script)

      script.onload = () => {
        setIsLoaded(true)
        setIsLoading(false)
      }

      script.onerror = () => {
        setIsLoading(false)
        setIsLoaded(false)
      }
    }

    if (existingScript) {
      setIsLoading(false)
      setIsLoaded(true)
    }
  }, [scriptId, scriptSrc])

  return {
    isLoaded,
    isLoading,
  }
}

export default useLoadScript
