/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  Image,
  Button,
  Icon,
  Segment,
  Divider,
  Progress,
  TextArea,
  Label,
} from 'semantic-ui-react'
import { selectSelectedFile, selectViewOnly } from '../../../store/uiSlice'
import FilePreview from '../Forum/components/FilePreview'
import { setSelectedFile } from '../../../store/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { isActivityClosed } from 'app/elements/Assignment'
import { selectCurrentUser } from 'app/store/authSlice'
import { shuffleArray } from 'app/utils/suffle'
import { useLocalStorage } from 'usehooks-ts'

export default function Quiz({ assigment, quiz, onSubmit, done }) {
  const coverUrl = assigment?.configuration?.image
  let dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [answers, setAnswers] = useLocalStorage(assigment?.id, {})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null)
  const [currentAnswer, setCurrentAnswer] = useState(null)
  const selectedImage = useSelector(selectSelectedFile)
  const viewOnly = useSelector(selectViewOnly)
  const ref = useRef(null)
  const theme = useTheme()
  let content

  const sortedQuizz = useMemo(() => {
    if (assigment?.configuration?.questionsOrder === 'random') {
      return shuffleArray(quiz)
    }
    return quiz
  }, [assigment, quiz])

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex === 0) {
      return
    }

    const prevQuestionId = sortedQuizz[currentQuestionIndex - 1]?.id

    setCurrentAnswer(answers[prevQuestionId])
    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  const onAnswerSelect = (newAnswer) => {
    const answersData = {
      ...answers,
      [sortedQuizz[currentQuestionIndex].id]: newAnswer,
    }
    setCurrentAnswer(newAnswer)
    setAnswers(answersData)
  }

  const handleNextQuestion = async () => {
    if (currentQuestionIndex === sortedQuizz.length - 1) {
      await onSubmit(answers)
    } else {
      const nextQuestionId = sortedQuizz[currentQuestionIndex + 1]?.id
      setCurrentQuestionIndex(currentQuestionIndex + 1)

      setCurrentAnswer(answers[nextQuestionId] ?? '')
    }
  }

  const onShowImage = (imageUrl) => {
    const file = {
      is_valid_url: true,
      file_url: imageUrl,
      file_type: 'image',
    }

    dispatch(setSelectedFile(file))
  }

  const startQuiz = () => {
    const initialQuestion = sortedQuizz[0]
    setCurrentQuestionIndex(0)

    if (answers && answers[initialQuestion.id]) {
      setCurrentAnswer(answers[initialQuestion.id])
    }
  }

  const handleAssignmentUpdate = useCallback(
    async (event) => {
      if (!currentUser?.isStudent) return

      const { updatedAssignment } = event.detail
      if (!updatedAssignment || !updatedAssignment?.configuration) return

      const isClosed = isActivityClosed({
        configuration: updatedAssignment?.configuration,
      })

      if (
        isClosed &&
        !done &&
        updatedAssignment?.id === assigment?.id &&
        currentQuestionIndex !== null // Check if exam has started
      ) {
        await onSubmit(answers)
      }
    },
    [done, onSubmit, answers, assigment?.id, currentQuestionIndex, currentUser]
  )

  // Listen to assignment changes
  useEffect(() => {
    const eventName = `assignment:${assigment.id}:sockets_update`

    document.addEventListener(eventName, handleAssignmentUpdate)

    return () => {
      document.removeEventListener(eventName, handleAssignmentUpdate)
    }
  }, [assigment.id, handleAssignmentUpdate])

  if (currentQuestionIndex !== null) {
    const currentQuestion = sortedQuizz[currentQuestionIndex]
    const options = currentQuestion.options
    const progress = ((currentQuestionIndex + 1) / sortedQuizz.length) * 100
    let questionInput

    if (options) {
      questionInput = options.map((option) => (
        <Option
          inverted={option.value === currentAnswer || theme.isDark}
          color={option.value === currentAnswer ? 'olive' : null}
          key={option.value}
          onClick={() => onAnswerSelect(option.value)}
        >
          <Icon
            color={option.value === currentAnswer ? 'black' : 'grey'}
            size="large"
            name={
              option.value === currentAnswer ? 'check circle' : 'circle outline'
            }
          />
          <span dangerouslySetInnerHTML={{ __html: option.text.es }}></span>
        </Option>
      ))
    } else {
      questionInput = (
        <CustomTextArea
          value={currentAnswer ?? ''}
          rows={2}
          placeholder="Escribe aquí tu respuesta"
          onChange={(e) => onAnswerSelect(e.target.value)}
        />
      )
    }

    content = (
      <>
        <div>
          <TopContainer>
            <BolderH3>{assigment.title}</BolderH3>
            <ProgressContainer>
              <BolderH3 style={{ textAlign: 'center', marginBottom: '0.2rem' }}>
                {currentQuestionIndex + 1} de {sortedQuizz.length}
              </BolderH3>
              <Progress percent={progress} color="olive" size="tiny" />
            </ProgressContainer>
          </TopContainer>
          <Divider style={{ marginTop: 0 }}></Divider>
        </div>
        <OptionsContainer>
          <QuestionTitle>
            {
              <span
                dangerouslySetInnerHTML={{
                  __html: currentQuestion.questions.es,
                }}
              ></span>
            }
            {!!currentQuestion.imageUrl &&
              currentQuestion.imageUrl.length !== 0 && (
                <div>
                  <Icon.Group
                    style={{ cursor: 'pointer' }}
                    size="big"
                    onClick={() => onShowImage(currentQuestion.imageUrl)}
                  >
                    <ImageWithFallback src={currentQuestion.imageUrl} />
                    <ExpandIcon corner name="search plus" />
                  </Icon.Group>
                </div>
              )}
          </QuestionTitle>
          {questionInput}
          <ActionsContainer>
            {currentQuestionIndex !== 0 && (
              <Button
                inverted={theme.isDark}
                style={{ borderRadius: 0 }}
                basic
                size="big"
                onClick={handlePreviousQuestion}
              >
                Anterior
              </Button>
            )}
            <MainButton
              disabled={currentAnswer == null || currentAnswer == ''}
              size="big"
              onClick={handleNextQuestion}
            >
              {currentQuestionIndex === sortedQuizz.length - 1
                ? 'Terminar'
                : 'Siguiente'}
            </MainButton>
          </ActionsContainer>
        </OptionsContainer>
      </>
    )
  } else {
    content = (
      <>
        {!!coverUrl && coverUrl.length !== 0 && (
          <ThumbnailImage src={coverUrl} rounded fluid />
        )}
        <div>
          <InfoIcon name="question circle" size="big" color="olive" />
          <BolderH3>
            {sortedQuizz.length}{' '}
            {sortedQuizz.length === 1 ? 'Pregunta' : 'Preguntas'}
          </BolderH3>
        </div>
        <TitleContainer>
          <ColoredH3>Realiza el siguiente quiz</ColoredH3>
          <Title>{assigment.title}</Title>
          <StartButton
            size="big"
            primary
            disabled={viewOnly}
            onClick={startQuiz}
          >
            Comenzar
          </StartButton>
        </TitleContainer>
      </>
    )
  }

  return (
    <div style={{ width: '100%' }} ref={ref}>
      {selectedImage && (
        <FilePreview backText="Ver pregunta" assigment={assigment} />
      )}
      {!selectedImage && content}
    </div>
  )
}

const ImageWithFallback = ({ src }) => {
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  const handleReloadImage = (e) => {
    e.stopPropagation()
    setImageError(false)
  }

  return (
    <div>
      {imageError ? (
        <Label
          style={{ cursor: 'pointer' }}
          content="Recargar imagen"
          icon="warning"
          onClick={handleReloadImage}
        />
      ) : (
        <Image
          style={{ marginTop: '2rem', height: '50px' }}
          src={src}
          onError={handleImageError}
        />
      )}
    </div>
  )
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
`
const Title = styled.p`
  font-size: 2em;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 800;
  color: ${(props) => props.theme.text};
  width: 100%;
`
const StartButton = styled(Button)`
  margin-top: 2rem !important;
  margin-right: 0 !important;
  background-color: #0b1a3e !important;
  color: white !important;
  border-radius: 0 !important;
`
const ThumbnailImage = styled(Image)`
  max-height: 12rem;
  object-fit: cover;
`
const InfoIcon = styled(Icon)`
  margin: 1rem !important;
  margin-right: 0.2rem !important;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  margin: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`
const ProgressContainer = styled.div`
  width: 8rem;
  display: flex;
  flex-direction: column;
`
export const OptionsContainer = styled.div`
  max-width: 90%;
  margin: auto;
`
const QuestionTitle = styled.h3`
  padding-top: 3rem;
  font-size: 1.3rem;
  margin-bottom: 3rem;
  color: red !important;

  p > span {
    color: ${(props) => props.theme.text} !important;
  }
`
const ColoredH3 = styled.h3`
  color: ${(props) => props.theme.textTertiary};
`
export const Option = styled(Segment)`
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  color: black !important;
  border-radius: 0 !important;
  display: flex;
  align-items: center;

  p > span {
    color: ${(props) => props.theme.text} !important;
  }
`
const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5rem;
`
const MainButton = styled(Button)`
  background-color: #0b1a3e !important;
  color: white !important;
  border-radius: 0 !important;
`
const CustomTextArea = styled(TextArea)`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background: ${(props) => props.theme.sidebarBackground};
  color: ${(props) => props.theme.text};
`
const BolderH3 = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  display: inline;
  margin-bottom: '0';
  color: ${(props) => props.theme.textSecondary};
`
const ExpandIcon = styled(Icon)`
  right: -12px !important;
  bottom: -12px !important;
`
