import * as React from 'react'
import styled from 'styled-components'
import placeholder from './placeholder.png'

export default function Avatar({ size = 24, src, icon, showBorder }) {
  return (
    <AvatarWrapper>
      <CircleImg
        src={src ? src : placeholder}
        $showBorder={showBorder}
        size={size}
      />
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </AvatarWrapper>
  )
}

const AvatarWrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: -2px;
  right: -2px;
  background: ${(props) => props.theme.primary};
  border: 2px solid ${(props) => props.theme.background};
  border-radius: 100%;
  width: 20px;
  height: 20px;
`

const CircleImg = styled.img`
  display: block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: 2px solid
    ${(props) =>
      props.$showBorder === false ? 'transparent' : props.theme.background};
  flex-shrink: 0;
`
