import uploadFile from './uploadFile'

export default async function uploadMultipleFiles(files, route) {
  const uploadedFiles = await Promise.all(
    files.map(async (file) => {
      const x = await uploadFile(file, route)

      if (!x.file_url) {
        throw new Error('Ocurrió un error al subir el archivo.')
      }

      return x
    })
  )
  return uploadedFiles
}
