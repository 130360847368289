import React from 'react'
import styled from 'styled-components'
import ExamDetail from '../../components/assigments/Exam/ExamDetail'

export default function CompletedQuizAssignment({
  submission,
  assignment,
  hideResults = false,
}) {
  if (!submission.details.answers) return <></>

  return (
    <Root>
      <ExamDetail
        assigment={assignment}
        submission={submission}
        hideResults={hideResults}
      />
    </Root>
  )
}
const Root = styled.div`
  width: 100%;
  height: 100%;
`
