import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import {
  useGetElementByIdQuery,
  useGetEnrollmentGroupByIdQuery,
  useGetEnrollmentGroupByDocumentIdQuery,
  useToggleElementOpenMutation,
} from '../store/apiHubSlice'

import LoaderContainer from '../components/containers/LoaderContainer'
import { Loader, Checkbox, Button } from 'semantic-ui-react'
import Poll from './Poll'
import ElementBoard from './ElementBoard'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/authSlice'
import Discussion from './Discussion'
import Countdown from './Countdown'
import styled from 'styled-components'
import LockedAssignment from 'app/components/LockedAssignment'
import { SocketContext } from 'app/context/socket'
import Race from './Race'

function isActivityClosed(element) {
  if (
    !element ||
    !element.config ||
    !Object.hasOwn(element.config, 'isSynchronous')
  ) {
    return false
  }

  return element.config?.isSynchronous && !element.config?.isOpen
}

// prettier-ignore
export default function Element() {
  const { type, elementId, enrollmentGroupId, documentId } = queryString.parse(window.location.search);
  const elementQuery = useGetElementByIdQuery(elementId)
  const enrollmentGroupByDocumentIdQuery =
    useGetEnrollmentGroupByDocumentIdQuery(documentId, { skip: !documentId })
  const enrollmentGroupQuery = useGetEnrollmentGroupByIdQuery(
    enrollmentGroupId,
    { skip: !enrollmentGroupId }
  )
  const currentUser = useSelector(selectCurrentUser);
  const [elementData, setElementData] = useState(null);
  const [elementConfig, setElementConfig] = useState({
    isSynchronous: false,
    isOpen: true
  });
  const [toggleOpen, { isLoading: isTogglingOpen }] = useToggleElementOpenMutation();
  const { socket } = useContext(SocketContext)

  // handle synchronous change
  const handleIsSynchronous = (e, data) => {
    setElementConfig({ 
      ...elementConfig,
      isSynchronous: data.checked,
      isOpen: !data.checked
    })
  }

  // Opens the activity
  const toggleOpenElement = async () => {
    // Update on server
    await toggleOpen({
      elementId: elementData.id,
      isOpen: !elementData.config.isOpen
    }).unwrap();
  }

  useEffect(() => {
    // Listen to element events
    socket.on('elements.toggle_open', (data) => {
      setElementData((prevElementData) => {
        if (!prevElementData) return prevElementData

        return {
          ...prevElementData,
          config: {
            ...prevElementData.config,
            isOpen: data.config.isOpen
          }
        }
      })
    })

    socket.on('elements.create', setElementData)
    socket.on('elements.update', setElementData)
    socket.on('elements.poll_vote', setElementData)
    socket.on('elements.discussion_comment', setElementData)
    socket.on('elements.toggle_discussion_comment_upvote', setElementData)

    // Unbind all events
    return () => {
      socket.off('elements.toggle_open')
      socket.off('elements.create')
      socket.off('elements.update')
      socket.off('elements.poll_vote')
      socket.off('elements.discussion_comment')
      socket.off('elements.toggle_discussion_comment_upvote')
    }
  }, [])

  
  // Listen to element query changes
  useEffect(() => {
    if (elementQuery.isLoading || elementQuery.isFetching || !elementQuery.data) return;
    setElementData(elementQuery.data);
  }, [elementQuery]);

  // isSynchronous checkbox
  const IsSynchronousCheckbox = ({ style = {} }) => {
    return <Checkbox
      name="isSynchronous"
      label="Actividad síncrona"
      checked={elementConfig?.isSynchronous}
      onChange={handleIsSynchronous}
      style={style}
    />
  }

  // Show loader
  if (
    elementQuery.isLoading ||
    enrollmentGroupQuery.isLoading ||
    enrollmentGroupByDocumentIdQuery.isLoading
  ) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  // Check if element is not found
  const elementNotFound = elementQuery.isError && elementQuery.error.status === 500 && !elementData;

  // Check if activity is closed
  const isClosed = isActivityClosed(elementData)
  const isSynchronous =
    elementNotFound || !elementData ? false : elementData.config?.isSynchronous

  // Button for marking activity as open
  const OpenActivityButton = ({ style = {} }) => (
    <Button
      color={isClosed ? 'green' : 'red'}
      style={style}
      onClick={toggleOpenElement}
      loading={isTogglingOpen}
    >
      { isClosed ? 'Open assignment' : 'Close assignment'}
    </Button>
  )

  if (!enrollmentGroupId && !documentId) {
    return (
      <div>
        <p>No se pudo conseguir el grupo</p>
      </div>
    )
  } else if (elementNotFound && currentUser.isStudent) {
    return (
      <div>
        <h1 className='text-2xl font-bold text-center'>Aún no terminan de crear esta actividad</h1>
      </div>
    )
  } else if (isClosed && currentUser.isStudent) {
    return (
      <LockedAssignment />
    )
  } else if (type === 'poll') {
    return (
      <>
        {/* is synchronous checkbox */}
        {elementNotFound && !currentUser.isStudent && (
          <IsSynchronousCheckbox
            style={{ marginLeft: '2rem', marginBottom: '-1rem' }}
          />
        )}
        {/* Poll */}
        <Poll
          id={elementId}
          elementNotFound={elementNotFound}
          element={elementData}
          elementConfig={elementConfig}
          openActivityComponent={isClosed ? OpenActivityButton : <></>}
        />
        {/* Open activity button */}
        {!currentUser.isStudent && isSynchronous ? (
          <OpenActivityContainer>
            <OpenActivityButton />
          </OpenActivityContainer>
        ) : (
          <></>
        )}
      </>
    )
  } else if (type === 'board') {
    return (
      <ElementBoard
        boardId={elementId}
        element={elementData}
        elementNotFound={elementNotFound}
      />
    )
  } else if (type === 'discussion') {
    return (
      <>
        {/* is synchronous checkbox */}
        {elementNotFound && !currentUser.isStudent && (
          <IsSynchronousCheckbox style={{ marginLeft: '2rem' }} />
        )}
        {/* Discussion */}
        <Discussion
          discussionId={elementId}
          element={elementData}
          elementNotFound={elementNotFound}
          elementConfig={elementConfig}
        />
        {!currentUser.isStudent && isSynchronous ? (
          <OpenActivityContainer>
            <OpenActivityButton />
          </OpenActivityContainer>
        ) : (
          <></>
        )}
      </>
    )
  } else if (type === 'countdown') {
    return (
      <Countdown
        elementId={elementId}
        element={elementData}
        elementNotFound={elementNotFound}
      />
    )
  } else if (type === 'race') {
    return (
      <>
        {/* is synchronous checkbox */}
        {
          (elementNotFound && !currentUser.isStudent) && (
            <IsSynchronousCheckbox style={{ marginLeft: '1rem' }} />
          )
        }
        {/* Race */}
        <Race
          raceId={elementId}
          elementNotFound={elementNotFound}
          element={elementData}
          enrollmentGroupQuery={enrollmentGroupQuery}
          elementConfig={elementConfig}
          openActivityComponent={isClosed ? OpenActivityButton : <></>}
        />
        {/* Open activity button */}
        {
          (!currentUser.isStudent && isSynchronous) ? (
            <OpenActivityContainer>
              <OpenActivityButton />
            </OpenActivityContainer>
          ) : <></>
        }
      </>
    );
  }

  return (
    <div>
      <h1>Tipo no reconocido</h1>
    </div>
  )
}

const OpenActivityContainer = styled.div`
  margin: auto;
`
