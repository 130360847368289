const colors = {
  brand: {
    red: '#FF5C80',
    pink: '#FF4DFA',
    purple: '#9E5CF7',
    blue: '#3633FF',
    marine: '#2BC2FF',
    green: '#42DED1',
    yellow: '#F5BE31',
    orange: '#FF9800',
    teal: '#009688',
  },
}

export const base = {
  ...colors,
}

export const light = {
  ...base,
}

export default light
