import React, { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import timeAgo from '../../../../utils/timeAgo'
import { useDispatch } from 'react-redux'
import { setSelectedPost } from '../../../../store/uiSlice'
import Avatar from '../../../shared/Avatar'
import { Icon } from 'semantic-ui-react'
import PostActions from './PostActions'
import ParentInformer from '../../../../utils/ParentInformer'
import Fuse from 'fuse.js'

export default function ForumPostsFeed({
  user,
  posts,
  assignment,
  searchValue,
  submission,
}) {
  const [, setWindowWidth] = useState(window.innerWidth)
  let dispatch = useDispatch()
  const extractContent = (post) => {
    return new DOMParser().parseFromString(post.content, 'text/html')
      .documentElement.textContent
  }
  const userPost = submission && { ...submission, user }

  const theme = useTheme()
  const [filteredSubmissions, setFilteredSubmissions] = useState(posts || [])

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!searchValue || searchValue === '') {
      setFilteredSubmissions(posts)
      return
    }

    const queryOptions = {
      threshold: 0.2,
      keys: ['user.givenName', 'user.familyName1', 'user.familyName2'],
    }
    const fuse = new Fuse(posts, queryOptions)
    const results = fuse.search(searchValue)
    setFilteredSubmissions(results.map((r) => r.item))
  }, [searchValue, posts])

  if (posts.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoAnswersText>No submissions</NoAnswersText>
      </div>
    )
  }

  const handlePostClick = (post) => {
    dispatch(setSelectedPost(post.id))
    ParentInformer.sendEngagement({
      eventName: 'forum_post:open',
      elementType: 'forum',
      element: {
        id: assignment.id,
        title: assignment.title,
        author: {
          email: post.user.email,
          uuid: post.user.id,
        },
      },
    })
  }

  return (
    <>
      <PostsGrid>
        {filteredSubmissions.map((post) => {
          return (
            <Post
              key={post.id}
              onClick={() => handlePostClick(post)}
              highlight={post.id === userPost?.id}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <AvatarWrapper>
                  <Avatar
                    src={post.user?.avatarUrl}
                    size={30}
                    showBorder={false}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '5px',
                    }}
                  >
                    <UserName>{`${post.user?.givenName} ${post.user?.familyName1}`}</UserName>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <Time>{timeAgo.format(Date.parse(post.createdAt))}</Time>
                      {post.files && post.files.length > 0 && (
                        <div
                          style={{
                            marginTop: '-10px',
                          }}
                        >
                          <Icon name="paperclip" size="small" />
                          <span
                            style={{
                              color: 'inherit',
                              fontSize: '0.8rem',
                            }}
                          >
                            {post.files.length}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </AvatarWrapper>
                <PostContent>
                  <>
                    {extractContent(post).substring(0, 200)}
                    {'...'}
                  </>
                </PostContent>
                <div style={{ flex: '1' }} />
                <PostActions
                  user={user}
                  post={post}
                  onAddComment={() => dispatch(setSelectedPost(post))}
                  color={theme.text}
                />
              </div>
            </Post>
          )
        })}
      </PostsGrid>
    </>
  )
}
const PostsGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);
  @media only screen and (min-width: 600px) {
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
  margin: auto;
  padding: 0px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  overflow: hidden;
  width: 100%;
`
const Post = styled.div`
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(23, 23, 23, 0.1);
  cursor: pointer;
  margin-bottom: 2.5px;
  width: 100%;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: ${(props) => props.theme.sidebarBackground};
  &:hover {
    background-color: ${(props) =>
      props.theme.isDark ? props.theme.white05 : '#f5f5f5'};
  }
  ${(props) =>
    props.highlight
      ? `
    box-shadow: 0 0 0 1px #0E6EB8;
  `
      : ``}
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const UserName = styled.div`
  font-size: 0.9em;
  font-weight: 800;
  color: ${(props) => props.theme.text};
`
const PostContent = styled.div`
  font-size: 0.8em;
  padding-top: 5px;
  font-weight: 400;
  line-height: 1.2;
  color: ${(props) => props.theme.text};
  margin-bottom: 10px;
`
const Time = styled.p`
  font-size: 0.7em;
  color: ${(props) => props.theme.textTertiary};
`
const NoAnswersText = styled.h4`
  color: ${(props) => props.theme.textTertiary};
`
