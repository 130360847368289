export default function calculateExamGrade({ submission, assigment }) {
  const studentAnswers = submission.details.answers
  const assigmentQuestions = assigment.configuration.quiz
  const questionsCount = assigmentQuestions.length
  const feedback = submission.feedback
    ? submission.feedback.instructor_feedback
    : submission.details.feedback
  let rightAnswersCount = 0
  const grading = []

  for (const iterator of assigmentQuestions) {
    const questionId = iterator.id
    let chosenOptions = studentAnswers[questionId]
    const questionName = iterator.questions.es
    const answerOptions = iterator.options

    // check if question has options
    if (answerOptions) {
      const answerCorrectOptions = answerOptions
        .filter((option) => !!option.isCorrect)
        .map((option) => option.value)
      const readableCorrectOptions = answerOptions
        .filter((option) => !!option.isCorrect)
        .map((option) => option.text.es)
        .join(', ')

      // check if student selected multiple answers
      if (chosenOptions !== undefined && typeof chosenOptions !== 'object') {
        chosenOptions = [chosenOptions]
      } else if (
        chosenOptions == undefined &&
        typeof chosenOptions !== 'object'
      ) {
        chosenOptions = []
      }

      let isAnswerCorrect = false

      // check if the student selected the correct option
      for (const chosenOption of chosenOptions) {
        if (answerCorrectOptions.includes(chosenOption)) {
          isAnswerCorrect = true
          break
        }
      }

      if (isAnswerCorrect) {
        rightAnswersCount += 1
      }

      grading.push({
        question: questionName,
        answer: chosenOptions
          .map(
            (option) =>
              iterator.options.find((op) => op.value === option).text.es
          )
          .join(', '),
        isCorrect: isAnswerCorrect,
        feedback: readableCorrectOptions,
      })
    } else {
      let isAnswerCorrect = false

      // check if has feedback
      if (feedback[questionId]) {
        isAnswerCorrect = feedback[questionId].isCorrect
      }

      if (isAnswerCorrect) {
        rightAnswersCount += 1
      }

      grading.push({
        question: questionName,
        answer: studentAnswers[questionId],
        isCorrect: isAnswerCorrect,
        feedback: feedback[questionId]?.feedback,
      })
    }
  }

  return {
    rightAnswersCount,
    questionsCount,
    grade: rightAnswersCount / questionsCount,
    grading,
  }
}
