import React, { useRef, useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Label, Button, Icon, TextArea } from 'semantic-ui-react'
import {
  useUserInfoMutation,
  useUpdateSubmissionMutation,
} from '../store/apiHubSlice'
import {
  selectSelectedAssignment,
  selectSelectedSubmission,
  selectViewOnly,
  setSelectedSubmission,
} from '../store/uiSlice'
import { gradeColors } from '../components/shared/GradeColors'
import DateTime from '../components/shared/DateTime'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentUser } from '../store/authSlice'
import { v4 as uuidv4 } from 'uuid'
import momentjs from 'moment'
import Avatar from '../components/shared/Avatar'
import { IoMdSend } from 'react-icons/io'
import moment from 'moment-timezone'

export default function SubmissionFeedback() {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [valueFeedback, setValueFeedback] = useState('')
  const [feedbackMessages, setFeedbackMessages] = useState([])
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [getUser] = useUserInfoMutation()
  const [updateSubmission, { isLoading: isSavingFeedback }] =
    useUpdateSubmissionMutation()
  const bottomRef = useRef(null)
  const theme = useTheme()
  const assignment = useSelector(selectSelectedAssignment)
  const submission = useSelector(selectSelectedSubmission)
  const viewOnly = useSelector(selectViewOnly)

  function formatDate(datetime) {
    try {
      const date = new Date(datetime)
      let month = momentjs(date).format('MMMM')
      const day = momentjs(date).date()
      const year = momentjs(date).year()
      month = month.charAt(0).toUpperCase() + month.slice(1)

      return `${month} ${day}, ${year}`
    } catch (e) {
      return datetime
    }
  }
  // Send a feedback message
  const submitMessages = async () => {
    try {
      setIsSendingMessage(true)

      let updatedSubmission = { ...submission }
      let previousMessages = Object.hasOwn(
        submission.feedback || {},
        'messages'
      )
        ? [...submission.feedback.messages]
        : []

      previousMessages.push({
        id: uuidv4(),
        text: valueFeedback,
        sender: currentUser.id,
        datetime: getCurrentDate(),
      })

      const isThereFeedBackMessage = previousMessages.some((item) =>
        Boolean(item?.answerId)
      )
      // console.log(lastMessageIsFeedbackMessage)

      updatedSubmission.feedback = {
        ...updatedSubmission.feedback,
        messages: previousMessages,
        seen: currentUser?.isStudent && isThereFeedBackMessage,
      }

      const submissionData = await updateSubmission({
        id: updatedSubmission.id,
        feedback: updatedSubmission.feedback,
        details: updatedSubmission.details,
        userId: currentUser.id,
        reviewedById: currentUser.id,
      }).unwrap()
      setValueFeedback('')
      setIsSendingMessage(false)
      dispatch(
        setSelectedSubmission({
          ...submission,
          ...submissionData,
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  // Handle feedback change
  function handleFeedback({ target }) {
    setValueFeedback(target.value)
  }

  // Get user for each message
  useEffect(() => {
    if (submission) {
      const feedback = submission.feedback || {}
      const previousMessages = feedback.messages || []

      // Get user's profile picture
      // eslint-disable-next-line no-inner-declarations
      async function getProfilePictures(previousMessages) {
        let newMessages = []

        for (let i = 0; i < previousMessages.length; i++) {
          const message = previousMessages[i]
          const user = await getUser(message.sender).unwrap()
          newMessages.push({
            ...message,
            user,
          })
        }

        setFeedbackMessages(newMessages)
      }

      getProfilePictures(previousMessages)
    }
  }, [submission])

  // Submits a grade
  const submitGrade = async (grade) => {
    const feedback = {
      ...submission.feedback,
      status: grade > 5 ? 'accepted' : 'rejected',
    }
    const details = {
      ...submission.details,
    }

    try {
      const submissionData = await updateSubmission({
        id: submission.id,
        feedback,
        details,
        userId: currentUser.id,
        reviewedById: currentUser.id,
        grade: parseInt(grade),
      }).unwrap()
      dispatch(
        setSelectedSubmission({
          ...submission,
          ...submissionData,
        })
      )
    } catch (e) {
      console.log(e)
    }
  }

  if (!submission) {
    return <></>
  }

  // Parse createdAt
  const parsedCreatedAt = moment(submission.createdAt)
    .subtract(6, 'hours')
    .toISOString()

  const requiresFeedback = assignment?.configuration?.requiresFeedback ?? false
  return (
    <Container column full align="flex-start" justify="flex-start">
      {!currentUser.isStudent && (
        <Flex column gap={20} align="flex-start" justify="flex-start" full>
          <Flex full column justify="space-between">
            <Flex
              style={{ marginBottom: '1rem' }}
              justify="space-between"
              align="flex-start"
            >
              <Flex gap={5} justify="flex-start">
                <StyledLabel>
                  <Icon name="calendar" />
                  <DateTime value={parsedCreatedAt} format="LL" />
                </StyledLabel>
                <StyledLabel>
                  <Icon name="clock" />
                  <DateTime value={parsedCreatedAt} format="h:mm a" />
                </StyledLabel>
              </Flex>
              <Flex column gap={5} justify="flex-end" align="flex-end">
                <Button.Group compact>
                  {gradeColors.map((btn) => {
                    return (
                      <Button
                        key={`btn_grade_${btn.value}`}
                        onClick={() => submitGrade(btn.value)}
                        color={
                          !isSavingFeedback &&
                          parseInt(submission.grade) === parseInt(btn.value)
                            ? btn.color
                            : null
                        }
                        size="tiny"
                        inverted={theme.isDark}
                        disabled={currentUser.isStudent}
                      >
                        {btn.value}
                      </Button>
                    )
                  })}
                </Button.Group>
                {submission.grade !== null ? (
                  submission.grade > 5 ? (
                    <Label
                      color={
                        gradeColors.find(
                          (c) =>
                            parseInt(c.value) === parseInt(submission.grade)
                        ).color
                      }
                    >
                      Aprobado
                    </Label>
                  ) : (
                    <Label color="red">No aprobado</Label>
                  )
                ) : requiresFeedback ? (
                  <Label>Calificación pendiente</Label>
                ) : (
                  <></>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex column full>
        <ChatContainer>
          {feedbackMessages && feedbackMessages.length > 0 ? (
            feedbackMessages.map((item, index) => {
              return (
                <React.Fragment key={item.datetime}>
                  {index === 0 && (
                    <Dates style={{ marginBottom: '1.2rem' }}>
                      <Line />
                      <p>{formatDate(item.datetime)}</p>
                      <Line />
                    </Dates>
                  )}
                  <FeedbackMessage message={item} user={item.user} />
                </React.Fragment>
              )
            })
          ) : (
            <TextLabel>No hay mensajes de retroalimentación</TextLabel>
          )}

          <div ref={bottomRef} />
        </ChatContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '0px',
              margin: '0px',
            }}
          >
            <MessageInput
              value={valueFeedback}
              onChange={handleFeedback}
              type="textarea"
              style={{ resize: 'none', margin: '0px' }}
              rows={3}
              disabled={viewOnly}
            />
          </div>
          <SendMessageButton
            onClick={submitMessages}
            primary
            size="tiny"
            disabled={valueFeedback ? false : true}
            style={{ marginTop: '0.2rem' }}
            loading={isSendingMessage}
            disabled={viewOnly}
          >
            <IoMdSend size={16} style={{ marginRight: '0.5rem' }} />
            Send
          </SendMessageButton>
        </div>
      </Flex>
    </Container>
  )
}

const getCurrentDate = () => {
  return momentjs(new Date()).format('YYYY-MM-DD h:mm:ss A')
}

function FeedbackMessage({ message, user }) {
  let hourString = ''
  try {
    hourString = momentjs(new Date(message.datetime)).format('h:mm A')
  } catch (error) {
    hourString = message.datetime
  }

  return (
    <BoxMessage>
      <Message>
        <Avatar src={user.avatarUrl || ''} size={30} showBorder={false} />
        <BoxInformation>
          <User>
            <h1>{user.name} </h1> <p>@</p> <p>{hourString}</p>
          </User>
          <NewMessage>
            <p>{message.text}</p>
          </NewMessage>
        </BoxInformation>
      </Message>
    </BoxMessage>
  )
}

const StyledLabel = styled(Label)`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.white10 : 'rgb(232, 232, 232)'} !important;
  color: ${({ theme }) => theme.textSecondary} !important;
`
const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: ${(props) => (props.align ? props.align : 'flex-start')};
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  width: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};
`
const Container = styled(Flex)`
  margin-top: 1rem;
  gap: 1rem;
`

const BoxMessage = styled.main`
  width: 100%;
`

const BoxInformation = styled.div`
  flex: 1;
  height: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px 0 2px 0;
  justify-content: center;
  align-items: center;
`
const User = styled.div`
  width: 100%;
  padding-left: 4px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  color: ${({ theme }) => theme.text};
  h1 {
    font-size: 0.8rem;
    margin: 0;
    padding-bottom: 3px;
  }
  p {
    font-size: 0.6rem;
    margin: 0;
    color: ${({ theme }) => theme.textTertiary};
  }
  p:first-child {
    color: black;
  }
`

const NewMessage = styled.div`
  width: 100%;
  height: 75%;
  padding-left: 4px;
  p {
    margin: 0;
    font-size: 0.9rem;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
`

const Message = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 10px;
`

const ChatContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  overflow-y: auto;
`

const Dates = styled.div`
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 1px;
  p {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.textSecondary} !important;
  }
`

const Line = styled.div`
  width: 30%;
  height: 1px;
  background-color: ${({ theme }) => theme.textSecondary} !important;
`

const TextLabel = styled.p`
  margin: 0;
  font-size: 0.7rem;
  font-weight: bold;
`

const MessageInput = styled(TextArea)`
  width: 100%;
  border-color: ${({ theme }) => (theme.isDark ? theme.cardBorder : '#DAE1E9')};
  border-radius: 4px;
  outline: none;
  padding: 8px 12px 8px 12px;
  background-color: ${({ theme }) =>
    theme.isDark ? theme.almostBlack : '#fefffe'};
  color: ${({ theme }) => theme.text};
  &:focus {
    border-color: #9aa7b2;
  }
`

const SendMessageButton = styled(Button)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
`
