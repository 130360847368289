import { useState, useEffect, useLayoutEffect } from 'react'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import timeInterval from '../../../../utils/timeInterval'
import ReactAudioPlayer from 'react-audio-player'

const DEFAULT_LAPS = []

export default function NewAudio({ onPost, onCancel }) {
  const [cameraPermissions, setCameraPermissions] = useState(null)
  const [micPermissions, setMicPermissions] = useState(null)
  const [useMic, setUseMic] = useState(false)
  const [elapsed, setElapsed] = useState(0)
  const [isTicking, setIsTicking] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [intervalId, setIntervalId] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)
  const [laps, setLaps] = useState(DEFAULT_LAPS)
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder()

  useEffect(() => {
    navigator.permissions.query({ name: 'microphone' }).then((res) => {
      if (res && res.state) {
        setMicPermissions(res.state)
      } else {
        setMicPermissions('unknown')
      }
    })
  }, [])

  useEffect(() => {
    if (micPermissions === 'granted') {
      requestCameraAndMicPermissions()
    }
  }, [micPermissions])

  useLayoutEffect(() => {
    if (!isTicking) return
    setIntervalId(
      setInterval(() => {
        update()
      }, 10)
    )
  }, [isTicking])

  useEffect(() => {
    if (recordingBlob) {
      const url = URL.createObjectURL(recordingBlob)
      setFileUrl(url)
    }
  }, [recordingBlob])

  const requestCameraAndMicPermissions = () => {
    requestMediaPermissions({ audio: true, video: false })
      .then(() => {
        // can successfully access camera and microphone streams
        setUseMic(true)
      })
      .catch((err) => {
        const { type } = err
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          setCameraPermissions('system_denied')
          setMicPermissions('system_denied')
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          setCameraPermissions('denied')
          setMicPermissions('denied')
        } else {
          // not all error types are handled by this library
          setCameraPermissions('unknown')
          setMicPermissions('unknown')
        }
      })
  }

  const handleStartRecording = () => {
    startRecording()
    start()
  }

  const handleStopRecording = () => {
    stopRecording()
  }

  const start = (elapsedFormatted) => {
    if (isTicking) {
      mark(elapsedFormatted)
      return
    }

    if (!startTime) setStartTime(Date.now())
    setIsTicking(true)
  }
  const update = () => {
    const now = Date.now()
    setElapsed(now - startTime + elapsed)
  }
  const mark = (elapsedFormatted) => {
    if (!isTicking) return
    setLaps([elapsedFormatted, ...laps])
  }

  const onCancelButtonClicked = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const onConfirmButtonClicked = () => {
    const file = new File([recordingBlob], new Date().toISOString(), {
      type: recordingBlob.type,
    })
    onPost(file)
  }

  let content

  if (cameraPermissions === 'prompt' || micPermissions === 'prompt') {
    content = (
      <PermissionsContainer>
        <h3>
          Para grabar audio, tu navegador deberá solicitar acceso al micrófono.
        </h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          El micrófono no está activo
        </PermissionsMessageContainer>
        <Button primary onClick={requestCameraAndMicPermissions}>
          Solicitar permisos
        </Button>
      </PermissionsContainer>
    )
  } else if (cameraPermissions === 'denied' || micPermissions === 'denid') {
    content = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos del micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>El micrófono no está activo</h5>
          <br />
          <span>
            Haz clic en el ícono 🔒 en la barra de direcciones de su navegador,
            <br />
            configura los permisos correctos y luego actualiza esta página.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (
    cameraPermissions === 'system_denied' ||
    micPermissions === 'system_denied'
  ) {
    content = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos del micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>
            La cámara y el micrófono no están activos
          </h5>
          <br />
          <span>
            Ve a las preferencias del sistema y concede permisos a tu navegador.
          </span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  } else if (cameraPermissions === 'unknown' || micPermissions === 'unknown') {
    content = (
      <PermissionsContainer>
        <h3>Por favor, activa los permisos del micrófono.</h3>
        <PermissionsMessageContainer>
          <div style={{ marginBottom: '1.5rem' }}>
            <NoMarginIcon color="grey" name="microphone slash" size="big" />
          </div>
          <h5 style={{ marginBottom: '0' }}>El micrófono no está activo</h5>
          <br />
          <span>Ocurrió un error al intentar acceder al micrófono.</span>
        </PermissionsMessageContainer>
      </PermissionsContainer>
    )
  }

  const { elapsedFormatted } = timeInterval(elapsed)

  if (useMic) {
    if (isRecording) {
      content = (
        <MicContainer>
          <div style={{ marginBottom: '1rem', color: 'red' }}>
            <div className="stopwatch-time elapsed">{elapsedFormatted}</div>
          </div>
          <StopButton onClick={handleStopRecording} style={{ display: 'flex' }}>
            <div
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: 'rgba(227, 73, 28, 0.9)',
                borderRadius: '6px',
              }}
            />
          </StopButton>
        </MicContainer>
      )
    } else if (fileUrl) {
      content = (
        <MicContainer>
          <ReactAudioPlayer src={fileUrl} controls />
          <AudioActionsContainer>
            {/* Text */}
            <p style={{ marginTop: '2rem' }}>¿Quieres usar este audio?</p>
            {/* Options */}
            <div style={{ display: 'flex', gap: '10px' }}>
              <ConfirmButton onClick={onConfirmButtonClicked}>Sí</ConfirmButton>
              <DiscardButton onClick={onCancelButtonClicked}>No</DiscardButton>
            </div>
          </AudioActionsContainer>
        </MicContainer>
      )
    } else {
      content = (
        <MicContainer>
          <span style={{ marginBottom: '1rem' }}>
            Presiona el botón para comenzar a grabar
          </span>
          <ActionsContainer>
            <RecordButton onClick={handleStartRecording}>
              <NoMarginIcon
                name="microphone"
                size="big"
                style={{ color: 'white', marginBottom: '0.4rem' }}
              />
            </RecordButton>
            <CancelButton onClick={onCancelButtonClicked}>
              <NoMarginIcon name="x" size="big" />
            </CancelButton>
          </ActionsContainer>
        </MicContainer>
      )
    }
  }

  return content
}

const PermissionsContainer = styled.div`
  min-height: 300px;
`
const PermissionsMessageContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 10rem;
  border-radius: 16px;
  background: rgb(240, 240, 240);
  margin: 32px 0px;
  padding-left: 4rem;
  padding-right: 4rem;
`
const NoMarginIcon = styled(Icon)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`
const MicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 2rem;
`
const RecordButton = styled(Button)`
  background-color: rgba(255, 60, 76, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`
const StopButton = styled(Button)`
  background-color: rgba(204, 204, 204, 0.4) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
`
const CancelButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  cursor: pointer;
`
const AudioActionsContainer = styled.div`
  display: flex;
  width: 200px !important;
  left: calc(50% - 100px);
  bottom: 25px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ConfirmButton = styled(Button)`
  background-color: rgba(54, 207, 113, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  color: white !important;
`
const DiscardButton = styled(Button)`
  background-color: rgba(149, 149, 149, 0.8) !important;
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
  transition: all 0.2s ease-in-out !important;
  :hover {
    transform: scale(1.1);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  color: white !important;
`
const ActionsContainer = styled.div`
  display: flex;
  widht: 140px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
