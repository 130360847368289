import { useDeleteSubmissionMutation } from 'app/store/apiHubSlice'
import {
  selectSelectedPost,
  selectSelectedSubmission,
  setSelectedSubmission,
} from 'app/store/uiSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Header, Icon, Button } from 'semantic-ui-react'

const ModalRemoveSubmission = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const selectedSubmission = useSelector(selectSelectedSubmission)
  const [deleteSubmission, { isLoading: isDeleting }] =
    useDeleteSubmissionMutation()

  const handleDelete = async () => {
    await deleteSubmission({
      id: selectedSubmission.id,
    }).unwrap()
    dispatch(setSelectedSubmission(null))
    handleClose()
  }

  return (
    <Modal basic onClose={handleClose} open={open} size="small">
      <Header icon>
        <Icon name="warning sign" color="yellow" />
        Eliminar entrega
      </Header>
      <Modal.Content>
        <p className="text-center text-lg text-white">
          Estás a punto de eliminar la entrega de "
          {selectedSubmission?.user?.name}". ¿Deseas continuar?
        </p>
      </Modal.Content>
      <Modal.Actions className="flex justify-center">
        <Button basic inverted onClick={handleClose}>
          <Icon name="remove" /> Cancelar
        </Button>
        <Button
          loading={isDeleting}
          color="red"
          inverted
          onClick={handleDelete}
        >
          <Icon name="checkmark" /> Eliminar
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalRemoveSubmission
