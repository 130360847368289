import { useEffect, useState } from 'react'
import { selectCurrentUser } from '../store/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '../components/shared/Avatar'
import styled from 'styled-components'
import { Button, Icon, Divider } from 'semantic-ui-react'
import {
  useCreateElementMutation,
  useAddDiscussionCommentMutation,
  useToggleDiscussionUpvoteMutation,
} from 'app/store/apiHubSlice'
import { v4 as uuidv4 } from 'uuid'
import {
  selectOpenComments,
  openComment,
  closeComment,
} from '../store/discussionSlice'
import ParentInformer from '../utils/ParentInformer'
import TextareaAutosize from 'react-textarea-autosize'

function CreateDiscussion({ discussionId, elementConfig }) {
  const currentUser = useSelector(selectCurrentUser)
  const [createElement, { isLoading: isCreatingElement }] =
    useCreateElementMutation()
  const [inputValue, setInputValue] = useState('')

  // Handle creation
  const createDiscussion = async () => {
    try {
      const commentId = uuidv4()
      await createElement({
        id: discussionId,
        type: 'discussion',
        config: {
          ...elementConfig,
          createdBy: currentUser.id,
        },
        data: {
          comments: [
            {
              id: commentId,
              uuid: commentId,
              title: inputValue,
              userId: currentUser.id,
              isFirstComment: true,
              comments: [],
              upvotes: [currentUser.id],
              downvotes: [],
            },
          ],
        },
      }).unwrap();
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <DiscussionCreatorWrapper
      style={{ margin: '1rem 2rem', marginTop: '1.8rem' }}
    >
      {/* User image */}
      <Avatar src={currentUser.avatarUrl} size={35} showBorder={false} />
      {/* Input */}
      <div style={{ flex: 1 }}>
        <CreateDiscussionInput
          placeholder="Comienza la discusión..."
          defaultValue={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
        />
      </div>
      {/* Post button */}
      <Button
        disabled={isCreatingElement || inputValue.length < 3}
        primary
        onClick={createDiscussion}
        loading={isCreatingElement}
      >
        Crear discusión
      </Button>
    </DiscussionCreatorWrapper>
  )
}

function AddDiscussionEntry({
  discussionId,
  comment,
  show = false,
  preWrittenComment = null,
}) {
  const [addDiscussionComment, { isLoading: isAddingComment }] =
    useAddDiscussionCommentMutation()
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState(preWrittenComment || '')
  const currentUser = useSelector(selectCurrentUser)

  // Handle creation
  const createDiscussionEntry = async () => {
    try {
      const newId = uuidv4()
      const commentText = inputValue

      await addDiscussionComment({
        commentId: newId,
        elementId: discussionId,
        userId: currentUser.id,
        parentCommentId: comment.uuid,
        comment: commentText,
      }).unwrap()

      ParentInformer.sendEngagement({
        eventName: 'discussion_comment:create',
        elementType: 'discussion',
        element: {
          id: discussionId,
        },
      })

      await handleCommentInputChange({ target: { value: '' }, open: false })
    } catch (err) {
      console.log(err)
    }
  }

  // Handle comment change
  const handleCommentInputChange = async ({ target, open = true }) => {
    setInputValue(target.value)
    dispatch(
      openComment({
        [comment.uuid]: {
          open,
          input: target.value,
        },
      })
    )
  }

  return (
    <>
      {show && (
        <DiscussionCreatorWrapper style={{ marginBottom: '1rem' }}>
          {/* Input */}
          <div style={{ flex: 1, marginLeft: '5px' }}>
            <CreateDiscussionInput
              placeholder="Escribe tu respuesta aquí"
              defaultValue={inputValue}
              value={inputValue}
              onChange={handleCommentInputChange}
            />
          </div>
          {/* Post button */}
          <Button
            disabled={inputValue.length < 3 || isAddingComment}
            primary
            onClick={createDiscussionEntry}
            loading={isAddingComment}
          >
            Responder
          </Button>
        </DiscussionCreatorWrapper>
      )}
    </>
  )
}

function ToggleUpvote({ discussionId, comment }) {
  const [toggleDiscussionUpvote] = useToggleDiscussionUpvoteMutation()
  const currentUser = useSelector(selectCurrentUser)
  const isUpvoted = comment.upvotes.indexOf(currentUser.id) !== -1

  const toggleUpvote = async () => {
    await toggleDiscussionUpvote({
      discussionId,
      commentId: comment.uuid,
    }).unwrap()
  }

  return (
    <UpvoteContainer
      upvoted={isUpvoted}
      onClick={toggleUpvote}
    >
      <Icon name="arrow up" />
      {comment.upvotes.length}
    </UpvoteContainer>
  )
}

function Comment({ comment, discussionId, level = 1 }) {
  const currentUser = useSelector(selectCurrentUser)
  const openComments = useSelector(selectOpenComments)
  const didUserComment =
    comment.comments.findIndex((c) => c.userId === currentUser.id || c.userHubUuid === currentUser.id) !== -1
  const [sortedComments, setSortedComments] = useState([])
  const dispatch = useDispatch()

  // Used for sorting comments by upvotes count
  const sortByUpvotes = (c1, c2) => {
    if (c1.upvotes.length < c2.upvotes.length) {
      return 1
    } else if (c1.upvotes.length > c2.upvotes.length) {
      return -1
    }

    return 0
  }

  // Handle toggle show comment
  const toggleShowCommentSection = (comment) => {
    if (openComments[comment.uuid]) {
      dispatch(closeComment(comment.uuid))
    } else {
      dispatch(
        openComment({
          [comment.uuid]: {
            open: true,
            input: '',
          },
        })
      )
    }
  }

  // Sort comments
  useEffect(() => {
    setSortedComments([...comment.comments].sort(sortByUpvotes))
  }, [comment])

  // Check if user can comment
  const canUserComment = !didUserComment

  return (
    <DiscussionEntryContainer>
      {/* Discussion title */}
      {comment.isFirstComment && (
        <div style={{ width: '100%' }}>
          {/* Discussion title */}
          <DiscussionTitle
            style={{ marginBottom: '0.3rem' }}
            dangerouslySetInnerHTML={{
              __html: comment.title.replace(/\n/g, '<br>'),
            }}
          />
          {/* Instructor data */}
          {comment.user && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={comment.user.avatarUrl} size={30} showBorder={false} />
              <UserTitle style={{ marginLeft: '0.5rem', fontSize: '0.75rem' }}>
                {comment.user.name}
              </UserTitle>
            </div>
          )}
          {/* Input and other comments */}
          <div style={{ width: '100%' }}>
            <Divider />
            {/* Input */}
            {canUserComment && (
              <AddDiscussionEntry
                discussionId={discussionId}
                comment={comment}
                show={true}
              />
            )}
            {/* Other comments */}
            {sortedComments.map((comment, i) => (
              <Comment key={i} comment={comment} discussionId={discussionId} />
            ))}
          </div>
        </div>
      )}
      {/* Other comments */}
      {!comment.isFirstComment && (
        <>
          <div style={{ marginTop: '0.2rem', position: 'relative' }}>
            {comment.user && (
              <Avatar src={comment.user.avatarUrl} size={35} showBorder={false} />
            )}
            <Line />
          </div>
          <div style={{ marginLeft: '0.5rem', width: '100%' }}>
            {comment.user && <UserTitle>{comment.user.name}</UserTitle>}
            <p
              style={{ marginBottom: '0.2rem' }}
              dangerouslySetInnerHTML={{
                __html: comment.title.replace(/\n/g, '<br>'),
              }}
            />
            {/* Upvote */}
            {
              <ActionsContainer>
                <ToggleUpvote discussionId={discussionId} comment={comment} />
                {canUserComment && level < 4 && (
                  <ToggleEntryInputButton
                    onClick={() => toggleShowCommentSection(comment)}
                  >
                    Responder
                  </ToggleEntryInputButton>
                )}
              </ActionsContainer>
            }
            {/* Comment input */}
            {canUserComment &&
              openComments[comment.uuid] &&
              openComments[comment.uuid].open && (
                <AddDiscussionEntry
                  discussionId={discussionId}
                  comment={comment}
                  show={true}
                  preWrittenComment={
                    openComments[comment.uuid]
                      ? openComments[comment.uuid].input
                      : ''
                  }
                />
              )}
            {/* Comments */}
            {sortedComments.map((comment, i) => (
              <Comment
                key={i}
                comment={comment}
                discussionId={discussionId}
                level={level + 1}
              />
            ))}
          </div>
        </>
      )}
    </DiscussionEntryContainer>
  )
}

export default function Discussion({
  discussionId,
  element,
  elementNotFound,
  elementConfig = {},
}) {
  const currentUser = useSelector(selectCurrentUser)

  if (elementNotFound && !currentUser.isStudent) {
    return (
      <CreateDiscussion
        discussionId={discussionId}
        elementConfig={elementConfig}
      />
    )
  } else if (elementNotFound) {
    return <h1>Aún no terminan de crear esta actividad</h1>
  }

  return (
    <>
      {element?.data?.comments?.map((comment, i) => (
        <Comment
          key={i}
          comment={comment}
          discussionId={discussionId}
          level={1}
        />
      ))}
    </>
  )
}

const DiscussionTitle = styled.h2`
  color: ${(props) => props.theme.text};
`

const DiscussionCreatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 1rem;
`
const CreateDiscussionInput = styled(TextareaAutosize)`
  width: 100%;
  margin: 0;
  border: none !important;
  outline: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.smoke} !important;
  textarea::placeholder {
    color: ${(props) =>
      props.theme.isDark ? props.theme.textTertiary : '#8d92ab'} !important;
  }
  padding: 0.7rem;
  overflow: hidden;
`
const DiscussionEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const UserTitle = styled.div`
  font-weight: semibold;
  color: grey;
  margin: 0;
  color: ${(props) => props.theme.textTertiary};
`

const UpvoteContainer = styled.div`
  cursor: pointer;
  color: ${({ theme, upvoted }) => (upvoted ? '#FF5700' : theme.text)};
  font-size: 0.8rem;
`

const Line = styled.div`
  position: absolute;
  left: 49%;
  top: 40px;
  bottom: 10px;
  width: 1px;
  background: ${(props) =>
    props.theme.isDark ? props.theme.backdrop : '#ccc'};
  z-index: 1;
`

const ToggleEntryInputButton = styled.p`
  font-weight: 500;
  cursor: pointer;
`

const ActionsContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
`
