import React, { useState, useRef, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { Label, Button, Icon, TextArea, Form, Table } from 'semantic-ui-react'
import { useUpdateSubmissionMutation } from '../../../store/apiHubSlice'
import calculateExamGrade from '../../../utils/calculateExamGrade'
import { selectCurrentUser } from '../../../store/authSlice'
import { useSelector } from 'react-redux'
import { Option } from './Quiz'

export default function ExamDetail({
  assigment,
  submission,
  hideResults = false,
}) {
  const [questionsGrading, setQuestionsGrading] = useState(
    submission.feedback?.instructor_feedback || {}
  )
  const [updateSubmission, { isLoading: isLoadingExamFeedback }] =
    useUpdateSubmissionMutation()
  const [examGradeDetails, setExamGradeDetails] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const examDetailsRef = useRef(null)
  const theme = useTheme()

  // Store submission with feedback
  let submissionWithFeedback = {
    ...submission,
    feedback: {
      ...submission.feedback,
      instructor_feedback: questionsGrading,
    },
  }

  function toggleIsCorrect(question, isCorrect) {
    if (currentUser.isStudent) {
      return
    }

    setQuestionsGrading({
      ...questionsGrading,
      [question.id]: {
        ...questionsGrading[question.id],
        isCorrect,
      },
    })
  }

  function onFeedbackChange(question, event) {
    if (currentUser.isStudent) {
      return
    }

    setQuestionsGrading({
      ...questionsGrading,
      [question.id]: {
        ...questionsGrading[question.id],
        feedback: event.target.value,
      },
    })
  }

  async function onSaveFeedback() {
    if (currentUser.isStudent) {
      return
    }

    if (Object.keys(questionsGrading).length === 0) {
      return alert('Por favor agrega retroalimentación')
    }

    // check that all grades have a isCorrect field
    for (const key in questionsGrading) {
      if (!Object.hasOwnProperty.call(questionsGrading[key], 'isCorrect')) {
        return alert(
          'Por favor indica si las respuestas son correctas/incorrectas'
        )
      }
    }

    const { grade } = calculateExamGrade({
      submission: submissionWithFeedback,
      assigment,
    })

    const currentMessages = submission?.feedback?.messages ?? []

    let newMessages = [...currentMessages]

    for (const [key, values] of Object.entries(questionsGrading)) {
      const answerId = key

      const answerFeedback = {
        feedback: values.feedback,
        isCorrect: values.isCorrect,
      }

      const quizConfig = assigment?.configuration?.quiz ?? []

      const question = quizConfig.find((item) => item.id === answerId)

      const escapedQuestionText = question?.questions?.es?.replace(
        /<[^>]*>/g,
        ''
      )

      const newFeedbackMessage = {
        text: `En pregunta "${escapedQuestionText.trim()}"

Estatus: ${answerFeedback.isCorrect ? 'Correcto' : 'Incorrecto'}

Retroalimentación: 
${answerFeedback.feedback}`,
        answerId,
        sender: currentUser.hubUuid,
        uuid: crypto.randomUUID(),
        datetime: new Date(),
      }

      if (
        currentMessages.find(
          (item) => item?.answerId && item?.answerId === answerId
        )
      ) {
        newMessages = newMessages.map((item) => {
          if (item?.answerId === answerId) {
            return newFeedbackMessage
          }
          return item
        })
      } else {
        newMessages = [...newMessages, newFeedbackMessage]
      }
    }

    // update submission with data
    await updateSubmission({
      id: submission.id,
      feedback: {
        instructor_feedback: questionsGrading,
        messages: newMessages,
        seen: false,
      },
      userId: currentUser.id,
      reviewedById: currentUser.id,
      grade: Math.round(grade * 1000) / 100,
    }).unwrap()

    window.location.reload()
  }

  useEffect(() => {
    try {
      const examGradeResult = calculateExamGrade({
        submission: submissionWithFeedback,
        assigment,
      })
      setExamGradeDetails(examGradeResult)
    } catch (err) {
      console.log(err)
    }
  }, [questionsGrading])

  return (
    <div ref={examDetailsRef} style={{ padding: '0.5rem', width: '100%' }}>
      {currentUser.isStudent && (
        <Heading>{currentUser.name || currentUser.givenName}</Heading>
      )}
      {examGradeDetails && !hideResults && (
        <Meta>
          Resultados:
          <Label
            circular
            style={{ marginLeft: '5px' }}
            color={examGradeDetails.grade >= 0.6 ? 'green' : 'red'}
            size="large"
          >
            ({examGradeDetails.rightAnswersCount}/
            {examGradeDetails.questionsCount}){' '}
            {Math.round(examGradeDetails.grade * 1000) / 100}
          </Label>
        </Meta>
      )}
      <>
        <Table inverted={theme.isDark}>
          <Table.Body>
            {assigment.configuration.quiz.map((item) => {
              // Handle question with options
              if (Object.hasOwn(item, 'options')) {
                // Find student's answer for this question
                const studentAnswer = submission.details.answers[item.id]

                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {/* Question title */}
                      <StyledHeader>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.questions.es,
                          }}
                        />
                      </StyledHeader>
                      {/* Options */}
                      <div style={{ marginTop: '1rem' }}>
                        {item.options.map((option) => {
                          // Determine color
                          let color = null
                          let inverted = theme.isDark
                          let iconColor = 'white'
                          let textColor = 'white'
                          let iconName = 'circle outline'

                          if (
                            option.value === studentAnswer &&
                            option.isCorrect
                          ) {
                            color = hideResults ? null : 'green'
                            iconColor = hideResults ? 'grey' : '#056442'
                            inverted = hideResults ? false : true
                            iconName = hideResults ? 'circle' : 'check circle'
                          } else if (
                            option.value === studentAnswer &&
                            !option.isCorrect
                          ) {
                            color = hideResults ? null : 'red'
                            iconColor = hideResults ? 'grey' : '#640510'
                            inverted = hideResults ? false : true
                            iconName = 'times circle'
                          } else if (option.isCorrect) {
                            color = 'green'
                            inverted = true
                          } else {
                            iconColor = 'grey'
                            textColor = theme.textSecondary
                          }

                          if (hideResults) {
                            textColor = theme.textSecondary
                          }

                          // Return option
                          return (
                            <Option
                              inverted={inverted}
                              color={color}
                              key={option.value}
                              cursor={'default'}
                            >
                              <Icon
                                style={{ color: iconColor }}
                                size="large"
                                name={iconName}
                              />
                              <OptionSpan
                                color={textColor}
                                dangerouslySetInnerHTML={{
                                  __html: option.text.es,
                                }}
                              ></OptionSpan>
                            </Option>
                          )
                        })}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )
              }

              // Handle question with textarea
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {/* Question title */}
                    <StyledHeader>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.questions.es }}
                      />
                    </StyledHeader>
                    {/* Student answer */}
                    <StyledAnswer>
                      {submission.details.answers[item.id]}
                    </StyledAnswer>
                    {/* Grading options */}
                    <div style={{ marginBottom: '1rem' }}>
                      <Button
                        color={
                          Object.hasOwn(
                            questionsGrading[item.id] || {},
                            'isCorrect'
                          )
                            ? questionsGrading[item.id].isCorrect
                              ? 'green'
                              : 'grey'
                            : 'grey'
                        }
                        onClick={() => toggleIsCorrect(item, true)}
                        inverted={theme.isDark}
                        disabled={currentUser.isStudent}
                      >
                        <Icon name="check circle outline" />
                        Correcto
                      </Button>
                      <Button
                        color={
                          Object.hasOwn(
                            questionsGrading[item.id] || {},
                            'isCorrect'
                          )
                            ? questionsGrading[item.id].isCorrect
                              ? 'grey'
                              : 'red'
                            : 'grey'
                        }
                        onClick={() => toggleIsCorrect(item, false)}
                        inverted={theme.isDark}
                        disabled={currentUser.isStudent}
                      >
                        <Icon name="times circle outline" />
                        Incorrecto
                      </Button>
                    </div>
                    <Form>
                      <StyledTextArea
                        value={questionsGrading[item.id]?.feedback}
                        onChange={(e) => onFeedbackChange(item, e)}
                        placeholder={
                          currentUser.isStudent
                            ? 'Todavía no hay retroalimentación'
                            : 'Escribe tu retroalimentación'
                        }
                        disabled={currentUser.isStudent}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {!currentUser.isStudent &&
          !assigment.configuration?.instantFeedback && (
            <Button
              floated="right"
              loading={isLoadingExamFeedback}
              primary
              onClick={onSaveFeedback}
            >
              Guardar
            </Button>
          )}
      </>
    </div>
  )
}
const Heading = styled.p`
  font-weight: bold;
  font-size: 2em;
  color: ${(props) => props.theme.text};
`
const Meta = styled.div`
  font-size: 1.3em;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.textTertiary};
`
const StyledHeader = styled.h3`
  color: ${(props) => props.theme.textSecondary};
  margin-bottom: 0;
`
const StyledAnswer = styled.p`
  color: ${(props) => props.theme.textSecondary};
  font-weight: lighter;
  margin-top: 0;
  padding-bottom: 0.5rem;
  font-size: 1rem;
`
const StyledTextArea = styled(TextArea)`
  color: ${(props) => props.theme.text} !important;
  background: ${(props) => props.theme.sidebarBackground} !important;
`
const OptionSpan = styled.span`
  color: ${(props) => props.color} !important;
  p {
    color: ${(props) => props.color} !important;
  }
  margin: 0;
  padding: 0;
`
